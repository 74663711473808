import Grid from "@mui/material/Grid";
import NumberFormat from "react-number-format";
import React from "react";
import { useMediaQuery } from "@mui/material";
import { subYears } from "date-fns";
import { color } from "../../styles/color";
import Text from "../../styles/textStyles";
import theme from "../../styles/theme";
import NumberInput from "../../elements/input/NumberInput";
import { PercentageSlider } from "../../elements/slider/PercentageSlider";
import {
  profileQuestion1SelectorData,
  profileQuestion3SelectorData,
  profileQuestion4SelectorData,
  profileQuestion5SelectorData,
  profileQuestion6SelectorData,
  profileQuestion7SelectorData,
  profileQuestion8SelectorData,
} from "../../utils/componentData";
import Selector from "../../elements/selector/Selector";
import TextInput from "../../elements/input/TextInput";
import DateInput from "../../elements/input/DateInput";
import { IndexContainerForm } from "../user/user-index.style";
import AdviceAlert from "../../elements/alert/AdviceAlert";
import WCIcons from "../../elements/icons/WCIcons";

interface RiskFormComponent {
  formik: any;
  questionIndex: number;
  setQuestionIndex: (questionIndex: number) => void;
  monthly_income: number;
  hideBirthdayQuestion: boolean;
}

export function RiskProfileForm(props: RiskFormComponent) {
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const downXl = useMediaQuery(theme.breakpoints.down("xl"));
  const {
    formik,
    questionIndex,
    setQuestionIndex,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    monthly_income,
    hideBirthdayQuestion,
  } = props;
  switch (questionIndex) {
    case 0:
      return (
        <IndexContainerForm
          className="index-container__form"
          downmobile={downMobile}
        >
          <Grid item>
            <TextInput
              value={formik.values.name}
              handleChange={formik.setFieldValue}
              formik={formik}
              name="name"
              label="Tu nombre"
              // @ts-ignore
              error={formik.errors.name}
              placeholder="Luis"
            />
          </Grid>
          {!hideBirthdayQuestion && (
            <Grid item>
              <DateInput
                fullDate
                value={formik.values.birthday}
                handleChange={formik.setFieldValue}
                formik={formik}
                // @ts-ignore
                error={formik.errors.birthday}
                name="birthday"
                label="Fecha de nacimiento"
                maxDate={subYears(new Date(), 18)}
                minDate={subYears(new Date(), 90)}
              />
            </Grid>
          )}
          <Grid item>
            <TextInput
              value={formik.values.zip_code}
              handleChange={formik.setFieldValue}
              formik={formik}
              name="zip_code"
              label="Código postal de tu residencia"
              // @ts-ignore
              error={formik.errors.zip_code}
              placeholder="41001"
            />
          </Grid>
        </IndexContainerForm>
      );
    case 1:
      return (
        <IndexContainerForm
          className="index-container__form"
          downmobile={downMobile}
        >
          <Grid item>
            <NumberInput
              value={formik.values.monthly_income}
              handleChange={formik.setFieldValue}
              formik={formik}
              name="monthly_income"
              label="Por trabajo"
              // @ts-ignore
              error={formik.errors.monthly_income}
              decimalScale={0}
              endAdornment={
                <Text
                  size="m"
                  weight="regular"
                  fontColor={color.greyscale.black[30]}
                >
                  €/mes
                </Text>
              }
              placeholder="1200"
            />
          </Grid>
          <Grid item>
            <NumberInput
              value={formik.values.rent_income}
              handleChange={formik.setFieldValue}
              formik={formik}
              name="rent_income"
              label="En alquileres"
              // @ts-ignore
              error={formik.errors.rent_income}
              decimalScale={0}
              endAdornment={
                <Text
                  size="m"
                  weight="regular"
                  fontColor={color.greyscale.black[30]}
                >
                  €/mes
                </Text>
              }
              placeholder="600"
            />
          </Grid>
          <Grid item>
            <NumberInput
              value={formik.values.other_income}
              handleChange={formik.setFieldValue}
              formik={formik}
              name="other_income"
              label="Otros"
              // @ts-ignore
              error={formik.errors.other_income}
              decimalScale={0}
              endAdornment={
                <Text
                  size="m"
                  weight="regular"
                  fontColor={color.greyscale.black[30]}
                >
                  €/mes
                </Text>
              }
              placeholder="300"
            />
          </Grid>
        </IndexContainerForm>
      );
    case 2:
      return (
        <Grid
          container
          justifyContent="center"
          columnSpacing={{ xs: theme.spacing(8), xl: theme.spacing(8) }}
          rowSpacing={{
            xs: downMobile ? theme.spacing(4) : theme.spacing(8),
            xl: theme.spacing(8),
          }}
        >
          {profileQuestion1SelectorData.map((data) => (
            <Grid key={data.title} item>
              <Selector
                title={data.title}
                selected={formik.values.stability === data.value}
                onClick={() => {
                  formik.setFieldValue(data.name, data.value, false);
                  setQuestionIndex(questionIndex + 1);
                }}
                size={downMobile ? "medium" : "big"}
                // eslint-disable-next-line no-nested-ternary
                width={downMobile ? 296 : downXl ? 176 : 240}
                // eslint-disable-next-line no-nested-ternary
                height={downMobile ? 96 : downXl ? 176 : 240}
              />
            </Grid>
          ))}
        </Grid>
      );
    case 3:
      return (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          // xs spacing isnt /2 of xl because of slider
          rowSpacing={{ xs: theme.spacing(22), xl: theme.spacing(28) }}
        >
          <Grid item>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              rowSpacing={{ xs: theme.spacing(1), xl: theme.spacing(2) }}
            >
              <Grid item>
                <Text
                  size={downMobile ? "xl" : "3xl"}
                  weight="semibold"
                  fontColor={color.greyscale.black[50]}
                >
                  <NumberFormat
                    value={formik.values.expenses * 0.01 * monthly_income}
                    thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                    decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                    decimalScale={0}
                    allowNegative={false}
                    displayType="text"
                    suffix=" €"
                  />
                </Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container>
            <PercentageSlider
              value={formik.values.expenses}
              handleChange={formik.setFieldValue}
              name="expenses"
              valueLabelFormat={(value: number) => `${value}%`}
            />
          </Grid>
          <Grid item>
            <Grid container sx={{ maxWidth: 450 }}>
              <Grid item>
                <AdviceAlert
                  text="Hablamos de gastos básicos: (1) vivienda, (2) suministros, (3) alimentación, (4) salud y (5) personas a tu cargo."
                  icon={
                    <WCIcons
                      name="help"
                      width="25"
                      height="24"
                      color={color.turquoise[100]}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    case 4:
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          rowSpacing={10}
        >
          <Grid item>
            <NumberInput
              value={formik.values.wealth}
              handleChange={formik.setFieldValue}
              formik={formik}
              name="wealth"
              label="Cantidad total (aprox.)"
              error={formik.errors.wealth}
              decimalScale={0}
              focused
              endAdornment={
                <Text
                  size="m"
                  weight="regular"
                  fontColor={color.greyscale.black[30]}
                >
                  €
                </Text>
              }
              placeholder="50.000"
            />
          </Grid>
          <Grid item>
            <Grid container sx={{ maxWidth: 430 }}>
              <Grid item>
                <AdviceAlert
                  text="Incluye: (1) Cuentas corrientes, (2) Depósitos, (3) Fondos de inversión, (4) Acciones y (5) Seguros unit linked."
                  icon={
                    <WCIcons
                      name="help"
                      width="25"
                      height="24"
                      color={color.turquoise[100]}
                    />
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    case 5:
      return (
        <Grid
          container
          justifyContent="center"
          columnSpacing={{ xs: theme.spacing(8), xl: theme.spacing(8) }}
          rowSpacing={{ xs: theme.spacing(8), xl: theme.spacing(8) }}
        >
          {profileQuestion3SelectorData.map((data) => (
            <Grid key={data.title} item>
              <Selector
                alignTitle={downMobile ? "center" : undefined}
                title={data.title}
                selected={formik.values.studies === data.value}
                onClick={() => {
                  formik.setFieldValue(data.name, data.value, false);
                  setQuestionIndex(questionIndex + 1);
                }}
                size={downMobile ? "medium" : "big"}
                // eslint-disable-next-line no-nested-ternary
                width={downMobile ? 296 : downXl ? 176 : 240}
                // eslint-disable-next-line no-nested-ternary
                height={downMobile ? 96 : downXl ? 176 : 240}
              />
            </Grid>
          ))}
        </Grid>
      );
    case 6:
      return (
        <Grid
          container
          justifyContent="center"
          columnSpacing={{ xs: theme.spacing(6), xl: theme.spacing(6) }}
          rowSpacing={{ xs: theme.spacing(8), xl: theme.spacing(8) }}
        >
          {profileQuestion4SelectorData.map((data) => (
            <Grid key={data.title} item>
              <Selector
                alignTitle={downMobile ? "center" : undefined}
                title={data.title}
                selected={formik.values.years_investing === data.value}
                onClick={() => {
                  formik.setFieldValue(data.name, data.value, false);
                  setQuestionIndex(questionIndex + 1);
                }}
                size={downMobile ? "medium" : "big"}
                // eslint-disable-next-line no-nested-ternary
                width={downMobile ? 300 : downXl ? 180 : 244}
                // eslint-disable-next-line no-nested-ternary
                height={downMobile ? 96 : downXl ? 176 : 240}
              />
            </Grid>
          ))}
        </Grid>
      );
    case 7:
      return (
        <Grid
          container
          justifyContent="center"
          columnSpacing={{ xs: theme.spacing(8), xl: theme.spacing(8) }}
          rowSpacing={{ xs: theme.spacing(8), xl: theme.spacing(8) }}
        >
          {profileQuestion5SelectorData.map((data) => (
            <Grid key={data.title} item>
              <Selector
                title={data.title}
                selected={formik.values.experience === data.value}
                onClick={() => {
                  formik.setFieldValue(data.name, data.value, false);
                  setQuestionIndex(questionIndex + 1);
                }}
                size={downMobile ? "medium" : "big"}
                // eslint-disable-next-line no-nested-ternary
                width={downMobile ? 296 : downXl ? 176 : 240}
                // eslint-disable-next-line no-nested-ternary
                height={downMobile ? 96 : downXl ? 176 : 240}
              />
            </Grid>
          ))}
        </Grid>
      );
    case 8:
      return (
        <Grid
          container
          justifyContent="center"
          columnSpacing={{ xs: theme.spacing(8), xl: theme.spacing(8) }}
          rowSpacing={{ xs: theme.spacing(8), xl: theme.spacing(8) }}
        >
          {profileQuestion6SelectorData.map((data) => (
            <Grid key={data.title} item>
              <Selector
                alignTitle="center"
                title={data.title}
                subtitle={data.subtitle}
                selected={formik.values.goal === data.value}
                onClick={() => {
                  formik.setFieldValue(data.name, data.value, false);
                  setQuestionIndex(questionIndex + 1);
                }}
                size={downMobile ? "medium" : "big"}
                // eslint-disable-next-line no-nested-ternary
                width={downMobile ? 296 : downXl ? 210 : 280}
                // eslint-disable-next-line no-nested-ternary
                height={downMobile ? 126 : downXl ? 176 : 240}
              />
            </Grid>
          ))}
        </Grid>
      );
    case 9:
      return (
        <Grid
          container
          justifyContent="center"
          columnSpacing={{ xs: theme.spacing(8), xl: theme.spacing(8) }}
          rowSpacing={{ xs: theme.spacing(8), xl: theme.spacing(8) }}
        >
          {profileQuestion7SelectorData.map((data) => (
            <Grid key={data.title} item>
              <Selector
                title={data.title}
                selected={formik.values.risk === data.value}
                onClick={() => {
                  formik.setFieldValue(data.name, data.value, false);
                  setQuestionIndex(questionIndex + 1);
                }}
                size={downMobile ? "medium" : "big"}
                // eslint-disable-next-line no-nested-ternary
                width={downMobile ? 296 : downXl ? 176 : 240}
                // eslint-disable-next-line no-nested-ternary
                height={downMobile ? 96 : downXl ? 176 : 240}
              />
            </Grid>
          ))}
        </Grid>
      );
    case 10:
      return (
        <Grid
          container
          justifyContent="center"
          columnSpacing={{ xs: theme.spacing(8), xl: theme.spacing(8) }}
          rowSpacing={{ xs: theme.spacing(8), xl: theme.spacing(8) }}
        >
          {profileQuestion8SelectorData.map((data) => (
            <Grid key={data.title} item>
              <Selector
                title={data.title}
                selected={formik.values.attitude === data.value}
                onClick={() => {
                  formik
                    .setFieldValue(data.name, data.value, false)
                    .then(() => formik.handleSubmit());
                }}
                size={downMobile ? "medium" : "big"}
                // eslint-disable-next-line no-nested-ternary
                width={downMobile ? 296 : downXl ? 176 : 240}
                // eslint-disable-next-line no-nested-ternary
                height={downMobile ? 96 : downXl ? 176 : 240}
              />
            </Grid>
          ))}
        </Grid>
      );
    default:
      return null;
  }
}

export default RiskProfileForm;
