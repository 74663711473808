import { fetchApi } from "../../utils/functions";
import { FinanbestTestAnswers } from "./risk.type";

export const getAllRiskProfilesApi = async () => {
  const allRiskProfilesRes = fetchApi("config/risk-profile", "get", undefined);
  return allRiskProfilesRes;
};

export const finanbestTestRiskProfileApi = async (
  testAnswers: FinanbestTestAnswers
) => {
  const finanbestRiskProfileRes = fetchApi("finanbest/risk-profile", "post", {
    answers: { ...testAnswers },
  });
  return finanbestRiskProfileRes;
};

export const postLowerProfileApi = async (identifier: string) => {
  fetchApi("risk-profile", "post", {
    identifier,
    is_active: true,
  });
};
