import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as React from "react";
import { useMediaQuery } from "@mui/material";
import { color } from "../../styles/color";
import Text from "../../styles/textStyles";
import theme from "../../styles/theme";

interface IAdviceAlert {
  text: string;
  icon?: JSX.Element;
}

function AdviceAlert(props: IAdviceAlert) {
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const { text, icon } = props;
  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "16px",
        background: color.turquoise[20],
        [theme.breakpoints.up("xs")]: {
          padding: theme.spacing(3),
        },
        [theme.breakpoints.up("xl")]: {
          padding: theme.spacing(6),
        },
      }}
    >
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        columnSpacing={{
          xs: downMobile ? theme.spacing(2.5) : theme.spacing(2),
          xl: theme.spacing(4),
        }}
        sx={{
          [theme.breakpoints.up("xs")]: {
            width: "auto",
          },
        }}
      >
        <Grid
          item
          sx={{
            color: color.turquoise[100],
            height: 24,
          }}
        >
          {icon}
        </Grid>
        <Grid
          item
          sx={{
            maxWidth: "calc(100% - 35px)",
          }}
        >
          <Text size="s" weight="regular" fontColor={color.greyscale.black[50]}>
            {text}
          </Text>
        </Grid>
      </Grid>
    </Box>
  );
}

AdviceAlert.defaultProps = {
  icon: undefined,
};

export default AdviceAlert;
