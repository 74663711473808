import Box from "@mui/material/Box";
import * as React from "react";
import Grid from "@mui/material/Grid";
import NumberFormat from "react-number-format";
// @ts-ignore
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import theme from "../../styles/theme";
import Text from "../../styles/textStyles";
import { color } from "../../styles/color";
import Buttons from "../../elements/buttons/Buttons";
import CircleChart from "../../components/chart/CircleChart";
import { Capitalize } from "../../utils/functions";
import { NotInteractiveSlider } from "../../elements/slider/NotInteractiveDiscreteSlider";
import SolidDividers from "../../elements/dividers/SolidDividers";
// eslint-disable-next-line import/no-cycle
import Perfil from "../../pages/perfil";
import PageLoading from "../../elements/loading/PageLoading";
import { postLowerProfileApi } from "./risk.service";
import WCIcons from "../../elements/icons/WCIcons";
import riskProfileDescription from "./risk.data";
import { reloadApi } from "../app/app.slice";

interface RiskDialogComponent {
  profileNumber: number;
  profileNumberMax: number;
  riskProfileArray: Array<any>;
  isTestAvailable?: boolean;
  closeDialog?: () => void;
  financialWealth?: any;
  birthday?: any;
  userIncome?: any;
  objectives?: any;
  isSliderActive?: boolean;
}

export function RiskProfileDialogContent(props: RiskDialogComponent) {
  const dispatch = useDispatch();
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const downXl = useMediaQuery(theme.breakpoints.down("xl"));
  const {
    riskProfileArray,
    profileNumber,
    isTestAvailable,
    closeDialog,
    financialWealth,
    birthday,
    userIncome,
    objectives,
    isSliderActive,
    profileNumberMax,
  } = props;
  const [showTest, setShowTest] = React.useState(false);
  const [testIsPassed, setTestIsPassed] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const router = useNavigate();
  const validationSchema = yup.object({
    profile: yup.number().min(1).max(6),
  });
  const formik = useFormik({
    initialValues: {
      profile: profileNumber,
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      postLowerProfileApi(values.profile.toString())
        .then(() => {
          dispatch(reloadApi());
          setLoading(false);
          formik.setSubmitting(false);
          if (closeDialog) {
            closeDialog();
          }
        })
        .catch(() => {
          setLoading(false);
          formik.setSubmitting(false);
        });
    },
  });
  React.useEffect(() => {
    formik.setFieldValue("profile", profileNumber);
  }, [profileNumber]);

  return (
    <Box
      sx={{
        width: "100%",
        // adding padding is a hotfix because of grid inside dialog mess with vertical position and adds a unwanted scrollbar
        [theme.breakpoints.up("xs")]: {
          // eslint-disable-next-line no-nested-ternary
          padding: downMobile
            ? theme.spacing(0)
            : // eslint-disable-next-line no-nested-ternary
            isTestAvailable === true && showTest === false
            ? theme.spacing(3)
            : showTest === true
            ? theme.spacing(3)
            : theme.spacing(8.5),
        },
        [theme.breakpoints.up("xl")]: {
          padding:
            // eslint-disable-next-line no-nested-ternary
            isTestAvailable === true && showTest === false
              ? theme.spacing(6)
              : showTest === true
              ? theme.spacing(6)
              : theme.spacing(17),
        },
      }}
    >
      <PageLoading open={loading} />
      {isTestAvailable === true && showTest === false && !downMobile && (
        <Grid container justifyContent="flex-end">
          <Grid
            item
            sx={{ cursor: "pointer", height: downXl ? "30" : "40" }}
            onClick={closeDialog}
          >
            <WCIcons
              name="close-filled"
              height={downXl ? "30" : "40"}
              width={downXl ? "30" : "40"}
            />
          </Grid>
        </Grid>
      )}
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        rowSpacing={{ xs: theme.spacing(9), xl: theme.spacing(18) }}
      >
        {/* Need to wrap containers on item to apply maxwidth */}
        {showTest === true ? (
          <Grid
            item
            sx={{
              width: "100%",
            }}
          >
            <Perfil
              data={{
                financialWealth,
                monthly_income: userIncome,
                birthday,
                objectives,
              }}
              finish={() => dispatch(reloadApi())}
              hideShowTest={() => {
                setShowTest(false);
                setTestIsPassed(true);
              }}
              goBack={() => {
                setShowTest(false);
              }}
              closeDialog={closeDialog}
            />
          </Grid>
        ) : (
          <Grid item>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              rowSpacing={{ xs: theme.spacing(20), xl: theme.spacing(20) }}
            >
              <Grid item>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                  sx={{
                    maxWidth: 690,
                  }}
                  rowSpacing={{
                    xs: downMobile ? theme.spacing(4) : theme.spacing(6),
                    xl: theme.spacing(8),
                  }}
                >
                  <Grid item>
                    <Text
                      // eslint-disable-next-line no-nested-ternary
                      size={downMobile ? "l" : downXl ? "2xl" : "3xl"}
                      weight="semibold"
                      gradient={color.gradient.blue}
                    >
                      {`Perfil inversor: ${Capitalize(
                        riskProfileArray[formik.values.profile - 1].name
                      )}`}
                    </Text>
                  </Grid>
                  <Grid item>
                    <Grid
                      container
                      direction={downMobile ? "column" : "row"}
                      alignItems="center"
                      justifyContent={downMobile ? "center" : undefined}
                      rowSpacing={downMobile ? theme.spacing(8) : 0}
                      columnSpacing={{
                        xs: theme.spacing(4),
                        xl: theme.spacing(8),
                      }}
                    >
                      <Grid item sx={{ maxWidth: downXl ? 400 : 515 }}>
                        <Text
                          size={downMobile ? "xxs" : "m"}
                          weight="medium"
                          fontColor={color.greyscale.black[50]}
                        >
                          {riskProfileDescription[formik.values.profile - 1]}
                        </Text>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          direction={downMobile ? "row" : "column"}
                          alignItems={downMobile ? "center" : undefined}
                        >
                          <Grid item>
                            <CircleChart
                              width={120}
                              height={120}
                              data={[
                                {
                                  name: "fixed",
                                  value:
                                    100 -
                                    riskProfileArray[profileNumber - 1]
                                      .max_equity_exposure *
                                      100,
                                  color: color.blue[100],
                                },
                                {
                                  name: "equity",
                                  value:
                                    riskProfileArray[profileNumber - 1]
                                      .max_equity_exposure * 100,
                                  color: color.turquoise[100],
                                },
                              ]}
                            />
                          </Grid>
                          <Grid
                            item
                            sx={{
                              [theme.breakpoints.up("xs")]: {
                                marginTop: -2,
                              },
                              [theme.breakpoints.up("xl")]: {
                                marginTop: -4,
                              },
                            }}
                          >
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                            >
                              <Grid item>
                                <Text
                                  size="s"
                                  weight="semibold"
                                  display="inline"
                                  fontColor={color.blue[100]}
                                >
                                  <NumberFormat
                                    value={
                                      100 -
                                      riskProfileArray[profileNumber - 1]
                                        .max_equity_exposure *
                                        100
                                    }
                                    thousandSeparator={
                                      process.env.REACT_APP_THOUSAND_SEPARATOR
                                    }
                                    decimalSeparator={
                                      process.env.REACT_APP_DECIMAL_SEPARATOR
                                    }
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                    suffix="% Renta fija"
                                  />
                                </Text>
                              </Grid>
                              <Grid item>
                                <Text
                                  size="s"
                                  weight="semibold"
                                  display="inline"
                                  fontColor={color.turquoise[100]}
                                >
                                  <NumberFormat
                                    value={
                                      riskProfileArray[profileNumber - 1]
                                        .max_equity_exposure * 100
                                    }
                                    thousandSeparator={
                                      process.env.REACT_APP_THOUSAND_SEPARATOR
                                    }
                                    decimalSeparator={
                                      process.env.REACT_APP_DECIMAL_SEPARATOR
                                    }
                                    decimalScale={0}
                                    allowNegative={false}
                                    displayType="text"
                                    suffix="% Renta variable"
                                  />
                                </Text>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                sx={{
                  width: downXl ? "100%" : "initial",
                }}
              >
                <Grid
                  container
                  item
                  spacing={{ xs: theme.spacing(4), xl: theme.spacing(8) }}
                  justifyContent="center"
                  sx={{
                    maxWidth: 816,
                    [theme.breakpoints.up("xs")]: {
                      paddingTop: theme.spacing(7),
                    },
                    [theme.breakpoints.up("xl")]: {
                      paddingTop: theme.spacing(14),
                    },
                  }}
                >
                  <Grid item container>
                    <NotInteractiveSlider
                      // @ts-ignore
                      handleChange={
                        isSliderActive === true
                          ? formik.setFieldValue
                          : undefined
                      }
                      name="profile"
                      marks={riskProfileArray.map((profile) => ({
                        value: parseInt(profile.identifier, 10),
                        label: (!downMobile ||
                          (downMobile &&
                            formik.values.profile ===
                              parseInt(profile.identifier, 10))) && (
                          <Grid
                            direction="column"
                            container
                            alignItems="center"
                            sx={{ minWidth: 70 }}
                            rowSpacing={{
                              xs: theme.spacing(0.5),
                              xl: theme.spacing(1),
                            }}
                          >
                            <Grid item>
                              <CircleChart
                                width={20}
                                height={20}
                                data={[
                                  {
                                    name: "fixed",
                                    value:
                                      100 - profile.max_equity_exposure * 100,
                                    color:
                                      formik.values.profile ===
                                      parseInt(profile.identifier, 10)
                                        ? color.blue[100]
                                        : color.greyscale.black[30],
                                  },
                                  {
                                    name: "equity",
                                    value: profile.max_equity_exposure * 100,
                                    color:
                                      formik.values.profile ===
                                      parseInt(profile.identifier, 10)
                                        ? color.turquoise[100]
                                        : color.greyscale.black[10],
                                  },
                                ]}
                              />
                            </Grid>
                            <Grid item>
                              <Text
                                size="xs"
                                weight="bold"
                                fontColor={
                                  // eslint-disable-next-line no-nested-ternary
                                  formik.values.profile ===
                                  parseInt(profile.identifier, 10)
                                    ? color.greyscale.black[100]
                                    : profileNumber >
                                      parseInt(profile.identifier, 10)
                                    ? color.greyscale.black[30]
                                    : color.greyscale.black[10]
                                }
                              >
                                {Capitalize(profile.name)}
                              </Text>
                            </Grid>
                          </Grid>
                        ),
                      }))}
                      value={formik.values.profile}
                      min={1}
                      max={6}
                      profileNumberMax={profileNumberMax}
                      step={1}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {isTestAvailable === true
          ? showTest === false && (
              <Grid
                item
                sx={{
                  width: "inherit",
                  [theme.breakpoints.up("xs")]: {
                    paddingBottom: theme.spacing(1.75),
                  },
                  [theme.breakpoints.up("xl")]: {
                    paddingBottom: theme.spacing(3.5),
                  },
                }}
              >
                <Grid
                  container
                  direction="column"
                  rowSpacing={{
                    xs: theme.spacing(4.75),
                    xl: theme.spacing(9.5),
                  }}
                >
                  {!downMobile && (
                    <Grid
                      item
                      sx={{
                        [theme.breakpoints.up("xs")]: {
                          width: `calc(100% + ${theme.spacing(9)})`,
                          marginLeft: `-${theme.spacing(6)}`,
                        },
                        [theme.breakpoints.up("xl")]: {
                          width: `calc(100% + ${theme.spacing(18)})`,
                          marginLeft: `-${theme.spacing(12)}`,
                        },
                      }}
                    >
                      <SolidDividers orientation="horizontal" />
                    </Grid>
                  )}
                  <Grid
                    item
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    direction={downMobile ? "column" : "row"}
                    rowSpacing={downMobile ? 4 : 0}
                  >
                    <Grid
                      item
                      order={{ xs: downMobile ? 2 : 1 }}
                      sx={{
                        width: downMobile ? "100%" : "initial",
                      }}
                    >
                      <Buttons
                        size={downMobile ? "medium" : undefined}
                        onClick={() => (closeDialog ? closeDialog() : null)}
                        hoverBackground={color.greyscale.black[10]}
                        variant="text"
                        fontColor={color.greyscale.black[50]}
                      >
                        Atrás
                      </Buttons>
                    </Grid>
                    <Grid item order={{ xs: downMobile ? 1 : 2 }}>
                      <Grid
                        container
                        alignItems="center"
                        columnSpacing={{ xs: 5.5, xl: 5.5 }}
                        rowSpacing={downMobile ? 4 : 0}
                        sx={{
                          // width of material spacing causes errors on safari so i set it to auto
                          [theme.breakpoints.up("xs")]: {
                            width: "auto",
                          },
                        }}
                      >
                        <Grid
                          item
                          order={{ xs: downMobile ? 2 : 1 }}
                          sx={{
                            width: downMobile ? "100%" : "initial",
                          }}
                        >
                          <Buttons
                            size={downMobile ? "medium" : undefined}
                            variant="outlined"
                            fontColor={color.blue[100]}
                            borderColor={color.greyscale.black[10]}
                            selected={false}
                            onClick={() => setShowTest(true)}
                          >
                            Repetir test
                          </Buttons>
                        </Grid>
                        <Grid
                          item
                          order={{ xs: downMobile ? 1 : 2 }}
                          sx={{
                            width: downMobile ? "100%" : "initial",
                          }}
                        >
                          <Buttons
                            size={downMobile ? "medium" : undefined}
                            variant="contained"
                            onClick={() => {
                              if (formik.values.profile !== profileNumber) {
                                formik.handleSubmit();
                              } else {
                                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                closeDialog ? closeDialog() : null;
                              }
                            }}
                            disabled={
                              formik.values.profile === profileNumber &&
                              testIsPassed === false
                            }
                          >
                            Guardar cambios
                          </Buttons>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
          : showTest === false && (
              <Grid
                item
                sx={{
                  width: "inherit",
                }}
              >
                <Grid container justifyContent="center">
                  <Grid
                    item
                    sx={{
                      // eslint-disable-next-line no-nested-ternary
                      width: downMobile ? "100%" : downXl ? 300 : 350,
                    }}
                  >
                    <Buttons
                      size={downMobile ? "medium" : undefined}
                      variant="contained"
                      onClick={() => {
                        setLoading(true);
                        router("/propuesta/resultado");
                      }}
                    >
                      Continuar
                    </Buttons>
                  </Grid>
                </Grid>
              </Grid>
            )}
      </Grid>
    </Box>
  );
}

RiskProfileDialogContent.defaultProps = {
  isTestAvailable: undefined,
  closeDialog: undefined,
  financialWealth: undefined,
  birthday: undefined,
  userIncome: undefined,
  objectives: undefined,
  isSliderActive: undefined,
};
export default RiskProfileDialogContent;
