import * as React from "react";
import { useNavigate } from "react-router-dom";
import { color } from "../../styles/color";
import styles from "../../styles/Resultado.module.css";

import SolidDividers from "../../elements/dividers/SolidDividers";

function InicioPerfil() {
  const router = useNavigate();

  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/grid/row.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/grid/col.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-3xl.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-2xl.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-xl.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-l.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/icon/index"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-m.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-s.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/button/index"), []);

  return (
    <div className={styles.container}>
      <inv-grid-row style={{ justifyContent: "center" }}>
        <inv-grid-col class="col-8" style={{ display: "flex" }}>
          <inv-text-3xl
            style={
              {
                "--inv-text-3xl-gradient": color.gradient.blue,
                "--inv-text-3xl-font-weight": 600,
                textAlign: "center",
              } as React.CSSProperties
            }
          >
            Necesitamos conocerte
          </inv-text-3xl>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row style={{ justifyContent: "center", marginTop: "0.8em" }}>
        <inv-grid-col
          class="col-4"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <inv-text-m
            style={
              {
                textAlign: "center",
                "--inv-text-m-color": color.greyscale.black[50],
              } as React.CSSProperties
            }
          >
            Vamos a hacer un breve test
          </inv-text-m>
          <inv-text-m
            style={
              {
                textAlign: "center",
                "--inv-text-m-color": color.greyscale.black[50],
              } as React.CSSProperties
            }
          >
            para determinar tu perfil como invesor.
          </inv-text-m>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row style={{ justifyContent: "center", marginTop: "4em" }}>
        <inv-grid-col class="col-9" style={{ display: "flex" }}>
          <inv-text-xl
            style={
              {
                "--inv-text-xl-font-weight": 600,
                textAlign: "center",
              } as React.CSSProperties
            }
          >
            Queremos que consigas tus metas con el menor esfuerzo
          </inv-text-xl>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row
        style={{
          justifyContent: "center",
          marginTop: "5em",
          alignItems: "flex-end",
        }}
      >
        <inv-grid-col class="col-auto">
          <inv-grid-row style={{ justifyContent: "center" }}>
            <inv-grid-col class="col-auto">
              <img
                style={{ width: "3.5em" }}
                alt="perfil-manos-dinero"
                src="/assets/images/illustrations/perfil/hands.svg"
              />
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row style={{ justifyContent: "center" }}>
            <inv-grid-col class="col-auto">
              <img
                alt="perfil-ahorro"
                style={{ margin: "0 auto", width: "6.9em" }}
                src="/assets/images/illustrations/perfil/ahorro.svg"
              />
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row
            style={{ justifyContent: "center", marginTop: "0.75em" }}
          >
            <inv-grid-col class="col-auto">
              <inv-text-s style={{ "--inv-text-s-font-weight": 600 }}>
                Ahorro
              </inv-text-s>
            </inv-grid-col>
          </inv-grid-row>
        </inv-grid-col>
        <inv-grid-col class="col-auto">
          <inv-grid-row style={{ justifyContent: "center" }}>
            <inv-grid-col class="col-auto">
              <img
                style={{ width: "3em" }}
                alt="perfil-euro-flecha"
                src="/assets/images/illustrations/perfil/euro-up.svg"
              />
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row
            style={{ justifyContent: "center", marginTop: "0.75em" }}
          >
            <inv-grid-col class="col-auto">
              <img
                style={{ width: "6.9em" }}
                alt="perfil-rentabilidad"
                src="/assets/images/illustrations/perfil/rentabilidad.svg"
              />
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row
            style={{ justifyContent: "center", marginTop: "0.75em" }}
          >
            <inv-grid-col class="col-auto">
              <inv-text-s style={{ "--inv-text-s-font-weight": 600 }}>
                Rentabilidad
              </inv-text-s>
            </inv-grid-col>
          </inv-grid-row>
        </inv-grid-col>
        <inv-grid-col class="col-auto">
          <inv-grid-row style={{ justifyContent: "center" }}>
            <inv-grid-col class="col-auto">
              <img
                style={{ width: "3em" }}
                alt="perfil-euro"
                src="/assets/images/illustrations/perfil/euro.svg"
              />
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row
            style={{ justifyContent: "center", marginTop: "0.75em" }}
          >
            <inv-grid-col class="col-auto">
              <img
                style={{ width: "6.9em" }}
                alt="perfil-metas"
                src="/assets/images/illustrations/perfil/metas.svg"
              />
            </inv-grid-col>
          </inv-grid-row>
          <inv-grid-row
            style={{ justifyContent: "center", marginTop: "0.75em" }}
          >
            <inv-grid-col class="col-auto">
              <inv-text-s style={{ "--inv-text-s-font-weight": 600 }}>
                Tus metas
              </inv-text-s>
            </inv-grid-col>
          </inv-grid-row>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row style={{ justifyContent: "center", marginTop: "4em" }}>
        <inv-grid-col
          class="col-8"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <inv-text-xl
            style={
              {
                textAlign: "center",
                "--inv-text-xl-color": color.greyscale.black[50],
              } as React.CSSProperties
            }
          >
            Conocer tu perfil inversor nos ayuda
          </inv-text-xl>
          <inv-text-xl
            style={
              {
                textAlign: "center",
                "--inv-text-xl-color": color.greyscale.black[50],
              } as React.CSSProperties
            }
          >
            a entender la rentabilidad a la que puedes aspirar
          </inv-text-xl>
        </inv-grid-col>
      </inv-grid-row>

      <inv-grid-row style={{ marginTop: "4em" }}>
        <inv-grid-col class="col-12">
          <div style={{ maxWidth: "calc(100% - var(--inv-gutter-x))" }}>
            <SolidDividers orientation="horizontal" />
          </div>
        </inv-grid-col>
      </inv-grid-row>
      <inv-grid-row
        style={{ justifyContent: "space-between", padding: "1.2em 1.6em 0" }}
      >
        <inv-grid-col class="col-auto">
          <inv-button
            onClick={() => router("/meta/fin")}
            style={{
              "--inv-primary-gradient-color": "transparent",
              "--inv-primary-font-color": color.greyscale.black[50],
              "--inv-primary-hover-color": color.greyscale.black[10],
            }}
          >
            Atrás
          </inv-button>
        </inv-grid-col>
        <inv-grid-col class="col-auto">
          <inv-button onClick={() => router("/perfil")}>
            Comenzar Test
          </inv-button>
        </inv-grid-col>
      </inv-grid-row>
    </div>
  );
}

InicioPerfil.title = "Perfil de riesgo";
InicioPerfil.layout = "EmptyLayout";
InicioPerfil.mobileLayout = "MobileEmptyLayout";
export default InicioPerfil;
