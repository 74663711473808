import * as React from "react";
import NumberFormat from "react-number-format";

import Grid from "@mui/material/Grid";
import { color } from "../styles/color";
import { fetchApi } from "./functions";
import { AmountType, OutputType } from "../modules/goal/goal.type";
import Text from "../styles/textStyles";
import WCIcons from "../elements/icons/WCIcons";

export const goalColorByType = {
  RETIREMENT: color.goal[3.1],
  EDUCATION: color.goal[2.1],
  REAL_STATE: color.goal[4.1],
  SAFETY_NET: color.validation.error,
  HERITAGE: color.goal[1.1],
  CUSTOM: color.goal[5.1],
};

export const goalSecondaryColorByType = {
  RETIREMENT: color.goal[3.2],
  EDUCATION: color.goal[2.2],
  REAL_STATE: color.goal[4.2],
  SAFETY_NET: color.validation.error,
  HERITAGE: color.goal[1.2],
  CUSTOM: color.goal[5.2],
};

export const goalTertiaryColorByType = {
  RETIREMENT: color.goal[3.3],
  EDUCATION: color.goal[2.3],
  REAL_STATE: color.goal[4.3],
  SAFETY_NET: "rgba(226, 46, 89, 0.24)",
  HERITAGE: color.goal[1.3],
  CUSTOM: color.goal[5.3],
};

export const goalsSelectorData = [
  {
    title: "Complemento jubilación",
    name: "RETIREMENT",
    iconColor: color.goal[3.1],
    route: "/meta/jubilacion",
  },
  {
    title: "La educación de mis hijos",
    name: "EDUCATION",
    iconColor: color.goal[2.1],
    route: "/meta/educacion",
  },
  {
    title: "Entrada para una vivienda",
    name: "REAL_STATE",
    iconColor: color.goal[4.1],
    route: "/meta/vivienda",
  },
  {
    title: "Colchón para emergencias",
    name: "SAFETY_NET",
    iconColor: color.validation.error,
    route: "/meta/emergencia",
  },
  {
    title: "Dejar una herencia",
    name: "HERITAGE",
    iconColor: color.goal[1.1],
    route: "/meta/herencia",
  },
  {
    title: "Una meta personalizada",
    name: "CUSTOM",
    iconColor: color.goal[5.1],
    route: "/meta/personalizada",
  },
];

export const retirementSelectorData = [
  {
    title: "Soy asalariado",
    name: "is_freelance",
    subtitle: "Trabajo por cuenta ajena",
    value: false,
  },
  {
    title: "Soy autónomo",
    subtitle: "Trabajo por cuenta propia",
    name: "is_freelance",
    value: true,
  },
];

export const realStateSelectorData = [
  {
    title: "Vivienda habitual",
    name: "is_home",
    value: true,
  },
  {
    title: "Segunda vivienda",
    name: "is_home",
    value: false,
  },
];
export const safetyNetSelectorData = [
  {
    title: "Si",
    subtitle: "Ya dispongo de esa cantidad",
    name: "is_completed",
    value: true,
  },
  {
    title: "No",
    subtitle: "Necesito ahorrarlos",
    name: "is_completed",
    value: false,
  },
];
export const customSelectorData = [
  {
    title: "De una vez",
    name: "output_type",
    subtitle: "Recibelo de una vez",
    value: AmountType.UNIQUE,
  },
  {
    title: "Mes a mes",
    subtitle: "Recibelo mensualmente",
    name: "output_type",
    value: AmountType.MONTHLY,
  },
  {
    title: "Año a año",
    subtitle: "Recibelo anualmente",
    name: "output_type",
    value: AmountType.ANNUAL,
  },
];
export const retirementAgeData = [
  {
    title: "Jubilarme a los 63 años",
    name: "retirement_age",
    subtitle: "Tu pensión sería de 1.890 €",
    iconColor: color.goal[3.1],
    value: 63,
  },
  {
    title: "Jubilarme a los 64",
    name: "retirement_age",
    subtitle: "Tu pensión sería de 1.890 €",
    iconColor: color.goal[2.1],
    value: 64,
  },
  {
    title: "Jubilarme a los 65 (edad oficial)",
    name: "retirement_age",
    subtitle: "Tu pensión sería de 1.890 €",
    iconColor: color.goal[4.1],
    value: 65,
  },
  {
    title: "Jubilarme a los 66",
    name: "retirement_age",
    subtitle: "Tu pensión sería de 1.890 €",
    iconColor: color.validation.error,
    value: 66,
  },
  {
    title: "Jubilarme a los 67",
    name: "retirement_age",
    subtitle: "Tu pensión sería de 1.890 €",
    iconColor: color.goal[1.1],
    value: 67,
  },
];

export const educationFormQuestionTitle = (
  duration: number,
  amount: number | null,
  initData: string | null,
  goalName: string | null,
  downMobile?: boolean
) => [
  <Text
    size={downMobile ? "xs" : "l"}
    weight="semibold"
    align="center"
    fontColor={color.greyscale.black[100]}
  >
    ¿Para quién serán estos estudios?
  </Text>,
  <Text
    size={downMobile ? "xs" : "l"}
    weight="semibold"
    align="center"
    fontColor={color.greyscale.black[100]}
  >
    ¿Cuándo comienzan y cuánto duran sus estudios?
  </Text>,
  <Text
    size={downMobile ? "xs" : "l"}
    weight="semibold"
    align="center"
    fontColor={color.greyscale.black[100]}
  >
    {`Indica el coste ${duration < 2 ? "total" : "anual"} de la educación`}
  </Text>,
  <Grid item>
    <Text
      size={downMobile ? "xs" : "l"}
      weight="light"
      align="center"
      fontColor={color.greyscale.black[50]}
      display="inline"
    >
      Quieres
    </Text>
    <Text
      size={downMobile ? "xs" : "l"}
      weight="semibold"
      align="center"
      fontColor={goalColorByType.EDUCATION}
      display="inline"
    >
      <NumberFormat
        value={amount}
        thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
        decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
        decimalScale={0}
        allowNegative={false}
        displayType="text"
        suffix={duration < 2 ? " € " : " €/mes "}
        prefix=" "
      />
    </Text>
    <Text
      size={downMobile ? "xs" : "l"}
      weight="light"
      align="center"
      fontColor={color.greyscale.black[50]}
      display="inline"
    >
      {`a partir de ${initData}`}
    </Text>
    <Text
      size={downMobile ? "xs" : "l"}
      weight="light"
      align="center"
      fontColor={color.greyscale.black[50]}
    >
      {`para la ${goalName}`}
    </Text>
  </Grid>,
];

export const customFormQuestionTitle = (
  outputType: OutputType | null,
  amount: number,
  initDate: string,
  goalName: string,
  downMobile?: boolean
) => {
  let priceType = "";
  let outputTimeframe = "";
  if (outputType === OutputType.ANNUAL) {
    priceType = "anual";
    outputTimeframe = " €/año ";
  } else if (outputType === OutputType.MONTHLY) {
    priceType = "mensual";
    outputTimeframe = " €/mes ";
  } else {
    priceType = "total";
    outputTimeframe = " € ";
  }
  return [
    <>
      <Text size={downMobile ? "xs" : "l"} weight="semibold" align="center">
        ¡Genial! Vamos a comenzar a configurar tu meta,
      </Text>
      <Text size={downMobile ? "xs" : "l"} weight="semibold" align="center">
        ¿Cómo la vas a llamar?
      </Text>
    </>,
    <Text size={downMobile ? "xs" : "l"} weight="semibold" align="center">
      ¿Cómo quieres recibir el dinero para tu meta?
    </Text>,
    <Text size={downMobile ? "xs" : "l"} weight="semibold" align="center">
      {`¿Cúal es el precio ${priceType} de la meta?`}
    </Text>,
    <Grid item>
      <Text
        size={downMobile ? "xs" : "l"}
        weight="light"
        align="center"
        fontColor={color.greyscale.black[50]}
        display="inline"
      >
        Quieres
      </Text>
      <Text
        size={downMobile ? "xs" : "l"}
        weight="semibold"
        align="center"
        fontColor={goalColorByType.CUSTOM}
        display="inline"
      >
        <NumberFormat
          value={amount}
          thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
          decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
          decimalScale={0}
          allowNegative={false}
          displayType="text"
          suffix={outputTimeframe}
          prefix=" "
        />
      </Text>
      <Text
        size={downMobile ? "xs" : "l"}
        weight="light"
        align="center"
        fontColor={color.greyscale.black[50]}
        display="inline"
      >
        {`a partir de ${initDate}`}
      </Text>
      <Text
        size={downMobile ? "xs" : "l"}
        weight="light"
        align="center"
        fontColor={color.greyscale.black[50]}
      >
        {`para ${goalName}`}
      </Text>
    </Grid>,
  ];
};

export const heritageFormQuestionTitle = (
  amount: number,
  initDate: string,
  duration: number,
  downMobile?: boolean
) => [
  <Text size={downMobile ? "xs" : "l"} weight="semibold" align="center">
    ¿Cuál es tu fecha de nacimiento?
  </Text>,
  <Text size={downMobile ? "xs" : "l"} weight="semibold" align="center">
    ¿A cuánto asciende la herencia que quieres dejar?
  </Text>,
  <Grid item>
    <Text
      size={downMobile ? "xs" : "l"}
      weight="light"
      align="center"
      fontColor={color.greyscale.black[50]}
      display="inline"
    >
      Quieres
    </Text>
    <Text
      size={downMobile ? "xs" : "l"}
      weight="semibold"
      align="center"
      fontColor={goalColorByType.HERITAGE}
      display="inline"
    >
      <NumberFormat
        value={amount}
        thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
        decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
        decimalScale={0}
        allowNegative={false}
        displayType="text"
        suffix=" € "
        prefix=" "
      />
    </Text>
    <Text
      size={downMobile ? "xs" : "l"}
      weight="light"
      align="center"
      fontColor={color.greyscale.black[50]}
      display="inline"
    >
      {`en ${initDate}`}
    </Text>
    <Text
      size={downMobile ? "xs" : "l"}
      weight="light"
      align="center"
      fontColor={color.greyscale.black[50]}
    >
      para dejar la herencia deseada
    </Text>
  </Grid>,
];

export const heritageQuestionNameOrder = ["date_init", "amount"];

export const educationQuestionNameOrder = ["recipient", "date_init", "amount"];

export const realStateFormQuestionTitle = (downMobile: boolean) => [
  <Text
    size={downMobile ? "xs" : "l"}
    weight="semibold"
    align="center"
    fontColor={color.greyscale.black[100]}
  >
    ¿Qué tipo de vivienda quieres?
  </Text>,
  <Text
    size={downMobile ? "xs" : "l"}
    weight="semibold"
    align="center"
    fontColor={color.greyscale.black[100]}
  >
    ¿Cuánto necesitas para la entrada?
  </Text>,
  <Text
    size={downMobile ? "xs" : "l"}
    weight="semibold"
    align="center"
    fontColor={color.greyscale.black[100]}
  >
    ¿Cuándo necesitas el dinero de la entrada?
  </Text>,
];

export const realStateQuestionNameOrder = [
  "date_init",
  "real_state_value",
  "percentage",
];

export const safetyNetFormQuestionTitle = (
  downMobile: boolean,
  amount: number,
  initiDate: string,
  months: number
) => [
  <Text
    size={downMobile ? "xs" : "l"}
    weight="semibold"
    align="center"
    fontColor={color.greyscale.black[100]}
  >
    ¿Qué cantidad necesitas para afrontar un mes corriente?
  </Text>,
  <Text
    size={downMobile ? "xs" : "l"}
    weight="semibold"
    align="center"
    fontColor={color.greyscale.black[100]}
  >
    ¿Cuántos meses de gastos debe cubrir tu colchón?
  </Text>,
  <Text
    size={downMobile ? "xs" : "l"}
    weight="semibold"
    align="center"
    fontColor={color.greyscale.black[100]}
  >
    {`¿Tienes ya esos ${amount}€ ahorrados?`}
  </Text>,
  <Text
    size={downMobile ? "xs" : "l"}
    weight="semibold"
    align="center"
    fontColor={color.greyscale.black[100]}
  >
    {`Quiero este colchón de ${amount}€ dentro de:`}
  </Text>,
  <Grid item>
    <Text
      size={downMobile ? "xs" : "l"}
      weight="light"
      align="center"
      fontColor={color.greyscale.black[50]}
      display="inline"
    >
      Quieres
    </Text>
    <Text
      size={downMobile ? "xs" : "l"}
      weight="semibold"
      align="center"
      fontColor={goalColorByType.SAFETY_NET}
      display="inline"
    >
      <NumberFormat
        value={amount}
        thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
        decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
        decimalScale={0}
        allowNegative={false}
        displayType="text"
        suffix=" € "
        prefix=" "
      />
    </Text>
    <Text
      size={downMobile ? "xs" : "l"}
      weight="light"
      align="center"
      fontColor={color.greyscale.black[50]}
      display="inline"
    >
      {`a partir de ${initiDate}`}
    </Text>
    <Text
      size={downMobile ? "xs" : "l"}
      weight="light"
      align="center"
      fontColor={color.greyscale.black[50]}
    >
      {`para cubrir ${months} meses de gastos`}
    </Text>
  </Grid>,
];

export const safetyNetQuestionNameOrder = [
  "expenses",
  "months_income",
  "is_completed",
  "duration",
];

export const retirementFormQuestionTitle = (
  retirementAge: number | null,
  downMobile: boolean,
  amount: number | null
) => [
  <>
    <Grid item>
      <Text
        size={downMobile ? "xs" : "l"}
        weight="semibold"
        align="center"
        fontColor={color.greyscale.black[100]}
      >
        Primero estimaremos la Pensión que recibirás del estado
      </Text>
    </Grid>
    <Grid item>
      <Grid container sx={{ maxWidth: 500 }}>
        <Grid item>
          <Text
            size={downMobile ? "xxs" : "m"}
            weight="medium"
            align="center"
            fontColor={color.greyscale.black[50]}
          >
            Completa estos datos para que entiendas cuánto recibirás por parte
            de la Seguridad Social al jubilarte.
          </Text>
        </Grid>
      </Grid>
    </Grid>
  </>,
  <>
    <Grid item>
      <Text
        size={downMobile ? "xs" : "l"}
        weight="semibold"
        align="center"
        fontColor={color.greyscale.black[100]}
      >
        ¿A qué edad deseas jubilarte?
      </Text>
    </Grid>
    <Grid item>
      <Grid container sx={{ maxWidth: 400 }}>
        <Grid item>
          <Text
            size={downMobile ? "xxs" : "m"}
            weight="medium"
            align="center"
            fontColor={color.greyscale.black[50]}
          >
            La edad a la que te jubiles afecta
          </Text>
          <Text
            size={downMobile ? "xxs" : "m"}
            weight="medium"
            align="center"
            fontColor={color.greyscale.black[50]}
          >
            a la Pensión que recibas de la Seguridad Social.
          </Text>
        </Grid>
      </Grid>
    </Grid>
  </>,
  <>
    <Grid item>
      <Text
        size={downMobile ? "xs" : "l"}
        weight="semibold"
        align="center"
        fontColor={color.greyscale.black[100]}
      >
        ¿Qué ingresos quieres recibir cuando te jubiles?
      </Text>
    </Grid>
    <Grid item>
      <Grid container sx={{ maxWidth: 500 }}>
        <Grid item>
          <Text
            size={downMobile ? "xxs" : "m"}
            weight="medium"
            align="center"
            fontColor={color.greyscale.black[50]}
          >
            Pensión Pública + Complemento Privado = Ingresos jubilación
          </Text>
        </Grid>
      </Grid>
    </Grid>
  </>,
  <Grid item>
    <Text
      size={downMobile ? "xs" : "l"}
      weight="light"
      align="center"
      fontColor={color.greyscale.black[50]}
      display="inline"
    >
      Quieres recibir
    </Text>
    <Text
      size={downMobile ? "xs" : "l"}
      weight="semibold"
      align="center"
      fontColor={goalColorByType.RETIREMENT}
      display="inline"
    >
      <NumberFormat
        value={amount}
        thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
        decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
        decimalScale={0}
        allowNegative={false}
        displayType="text"
        suffix="€/mes "
        prefix=" "
      />
    </Text>
    <Text
      size={downMobile ? "xs" : "l"}
      weight="light"
      align="center"
      fontColor={color.greyscale.black[50]}
      display="inline"
    >
      {`a partir de tus ${retirementAge} años`}
    </Text>
    <Text
      size={downMobile ? "xs" : "l"}
      weight="light"
      align="center"
      fontColor={color.greyscale.black[50]}
    >
      para completar la pensión del estado
    </Text>
  </Grid>,
];

export const profileFormQuestionMainTitle = [
  "Empecemos por algo facil",
  "Ingresos",
  `Estabilidad financiera`,
  "Gastos fijos",
  "Patrimonio financiero",
  `Formación`,
  "Experiencia inversora",
  "Experiencia inversora",
  "Rentabilidad vs. Riesgo",
  "Tolerancia al riesgo",
  `Tolerancia al riesgo`,
];

export const retirementQuestionNameOrder = [
  [
    "is_freelance",
    "net_income",
    "years_contributed",
    "monthly_self_employed_fee",
  ],
  ["retirement_age"],
  ["amount"],
];

export const tokenSelectorData = [
  {
    title: "Log in",
    name: "login",
    icon: <WCIcons name="profile-single" width="64" height="64" />,
    iconColor: color.greyscale.black[100],
    onClick: () =>
      fetchApi("auth/login", "post", {
        email: "pabgaor+prueba@gmail.com",
        password: "Prueba11",
      })
        .then(() => true)
        .finally(() => true),
  },
  {
    title: "Log out",
    name: "logout",
    icon: <WCIcons name="log-out" width="64" height="64" />,
    iconColor: color.greyscale.black[100],
    onClick: () =>
      fetchApi("auth/logout", "get", undefined)
        .then(() => true)
        .finally(() => true),
  },
  {
    title: "Petición get",
    name: "get",
    icon: <WCIcons name="edit" width="64" height="64" />,
    iconColor: color.greyscale.black[100],
    onClick: () =>
      fetchApi("family-situation", "get", undefined)
        .then(() => true)
        .finally(() => true),
  },
  {
    title: "Borrar objetivos",
    name: "delete",
    icon: <WCIcons name="delete" width="64" height="64" />,
    iconColor: color.greyscale.black[100],
    onClick: async () => {
      try {
        const getExistingObjectivesRes = await fetchApi(
          "objectives",
          "get",
          undefined
        );
        // @ts-ignore
        const deleteExistingObjectivesRes = getExistingObjectivesRes.map(
          (objective: { id: number }) =>
            fetchApi(`objectives/${objective.id}`, "delete", undefined)
        );
        await Promise.all(deleteExistingObjectivesRes);
        return true;
      } catch (e) {
        return true;
      }
    },
  },
];

export const profileQuestionNameOrder = [
  "",
  "",
  "stability",
  "expenses",
  "wealth",
  "studies",
  "years_investing",
  "experience",
  "goal",
  "risk",
  "attitude",
];

export const profileFormQuestionTitle = (
  monthlyIncome: number,
  downMobile?: boolean
) => [
  <Text
    size={downMobile ? "xxs" : "m"}
    weight="medium"
    align="center"
    fontColor={color.greyscale.black[50]}
  >
    Introduce tus datos
  </Text>,
  <Text
    size={downMobile ? "xxs" : "m"}
    weight="medium"
    align="center"
    fontColor={color.greyscale.black[50]}
  >
    ¿Cuánto dinero entra en casa cada mes?
  </Text>,
  <Text
    size={downMobile ? "xxs" : "m"}
    weight="medium"
    align="center"
    fontColor={color.greyscale.black[50]}
  >
    ¿Cómo de estables son tus ingresos?
  </Text>,
  <>
    <Text
      size={downMobile ? "xxs" : "m"}
      weight="medium"
      align="center"
      fontColor={color.greyscale.black[50]}
    >
      <NumberFormat
        key="monthly_income"
        value={monthlyIncome}
        thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
        decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
        decimalScale={0}
        allowNegative={false}
        displayType="text"
        prefix="De esos "
        suffix="€/mes de ingresos,"
      />
    </Text>
    <Text
      size={downMobile ? "xxs" : "m"}
      weight="medium"
      align="center"
      fontColor={color.greyscale.black[50]}
    >
      ¿Qué cantidad destinas a gastos fijos?
    </Text>
  </>,
  <Text
    size={downMobile ? "xxs" : "m"}
    weight="medium"
    align="center"
    fontColor={color.greyscale.black[50]}
  >
    ¿Cuanto tienes ahorrado en la actualidad?
  </Text>,
  <Text
    size={downMobile ? "xxs" : "m"}
    weight="medium"
    align="center"
    fontColor={color.greyscale.black[50]}
  >
    ¿Cuál es tu nivel de estudios?
  </Text>,
  <Text
    size={downMobile ? "xxs" : "m"}
    weight="medium"
    align="center"
    fontColor={color.greyscale.black[50]}
  >
    ¿Cuántos años llevas invirtiendo?
  </Text>,
  <Text
    size={downMobile ? "xxs" : "m"}
    weight="medium"
    align="center"
    fontColor={color.greyscale.black[50]}
  >
    ¿Has desempeñado actividad profesional en mercados financieros?
  </Text>,
  <Text
    size={downMobile ? "xxs" : "m"}
    weight="medium"
    align="center"
    fontColor={color.greyscale.black[50]}
  >
    ¿Cuál es el objetivo de tu inversión?
  </Text>,
  <Text
    size={downMobile ? "xxs" : "m"}
    weight="medium"
    align="center"
    fontColor={color.greyscale.black[50]}
  >
    ¿Qué te transmite la palabra “riesgo”?
  </Text>,
  <>
    <Text
      size={downMobile ? "xxs" : "m"}
      weight="medium"
      align="center"
      fontColor={color.greyscale.black[50]}
    >
      Si el valor de una inversión cae,
    </Text>
    <Text
      size={downMobile ? "xxs" : "m"}
      weight="medium"
      align="center"
      fontColor={color.greyscale.black[50]}
    >
      ¿cuándo empiezas a preocuparte?
    </Text>
  </>,
];

export const profileQuestion1SelectorData = [
  {
    title: "Poco",
    name: "stability",
    value: 1,
  },
  {
    title: "Bastante",
    name: "stability",
    value: 2,
  },
  {
    title: "Mucho",
    name: "stability",
    value: 3,
  },
];

export const profileQuestion3SelectorData = [
  {
    title: "Enseñanza obligatoria",
    name: "studies",
    value: 1,
  },
  {
    title: "Formación profesional",
    name: "studies",
    value: 2,
  },
  {
    title: "Universitaria o superior",
    name: "studies",
    value: 3,
  },
];

export const profileQuestion4SelectorData = [
  {
    title: "Más de 5 años",
    name: "years_investing",
    value: 1,
  },
  {
    title: "Entre 1 y 5 años",
    name: "years_investing",
    value: 2,
  },
  {
    title: "Menos de 1 año",
    name: "years_investing",
    value: 3,
  },
  {
    title: "Nunca",
    name: "years_investing",
    value: 4,
  },
];

export const profileQuestion5SelectorData = [
  {
    title: "Sí",
    name: "experience",
    value: 1,
  },
  {
    title: "No, nunca",
    name: "experience",
    value: 2,
  },
];

export const profileQuestion6SelectorData = [
  {
    title: "Rentabilidad ALTA",
    subtitle: "Aunque exija tiempo y riesgo",
    name: "goal",
    value: 1,
  },
  {
    title: "EQUILIBRIO",
    subtitle: "Entre rentabilidad y estabilidad",
    name: "goal",
    value: 2,
  },
  {
    title: "Riesgo BAJO",
    subtitle: "Batir la inflación y no perder",
    name: "goal",
    value: 3,
  },
];

export const profileQuestion7SelectorData = [
  {
    title: "Pérdida",
    name: "risk",
    value: 1,
  },
  {
    title: "Incertidumbre",
    name: "risk",
    value: 2,
  },
  {
    title: "Oportunidad",
    name: "risk",
    value: 3,
  },
  {
    title: "Emoción",
    name: "risk",
    value: 4,
  },
];

export const profileQuestion8SelectorData = [
  {
    title: "Hasta -3%",
    name: "attitude",
    value: 1,
  },
  {
    title: "Hasta -7%",
    name: "attitude",
    value: 2,
  },
  {
    title: "Hasta -14%",
    name: "attitude",
    value: 3,
  },
  {
    title: "Hasta -20%",
    name: "attitude",
    value: 4,
  },
];

export const lastQuestionIndex = {
  education: 2,
  retirement: 2,
  heritage: 2,
  realState: 1,
  custom: 2,
  safetyNet: 1,
};

export const planMenuElements: Array<{
  icon: any;
  title: string;
  name: "plan" | "goals" | "benefits" | "help" | "details";
}> = [
  {
    icon: <WCIcons name="financial-plan" width="64" height="64" />,
    title: "Plan financiero",
    name: "plan",
  },
  {
    icon: <WCIcons name="goal" width="64" height="64" />,
    title: "Metas",
    name: "goals",
  },
  {
    icon: <WCIcons name="benefits" width="64" height="64" />,
    title: "Beneficios",
    name: "benefits",
  },
  {
    icon: <WCIcons name="help" width="64" height="64" />,
    title: "Ayuda",
    name: "help",
  },
];

export const planAportaciones = [
  {
    date: "10 septiembre 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 agosto 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 julio 2021",
    amount: 3021,
    isPaid: false,
  },
  {
    date: "10 junio 2021",
    amount: 3021,
    isPaid: true,
  },
];

export const planAportacionesMore = [
  {
    date: "10 septiembre 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 agosto 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 julio 2021",
    amount: 3021,
    isPaid: false,
  },
  {
    date: "10 junio 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 septiembre 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 agosto 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 julio 2021",
    amount: 3021,
    isPaid: false,
  },
  {
    date: "10 junio 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 septiembre 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 agosto 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 julio 2021",
    amount: 3021,
    isPaid: false,
  },
  {
    date: "10 junio 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 septiembre 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 agosto 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 julio 2021",
    amount: 3021,
    isPaid: false,
  },
  {
    date: "10 junio 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 septiembre 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 agosto 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 julio 2021",
    amount: 3021,
    isPaid: false,
  },
  {
    date: "10 junio 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 septiembre 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 agosto 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 julio 2021",
    amount: 3021,
    isPaid: false,
  },
  {
    date: "10 junio 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 septiembre 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 agosto 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 julio 2021",
    amount: 3021,
    isPaid: false,
  },
  {
    date: "10 junio 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 septiembre 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 agosto 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 julio 2021",
    amount: 3021,
    isPaid: false,
  },
  {
    date: "10 junio 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 septiembre 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 agosto 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 julio 2021",
    amount: 3021,
    isPaid: false,
  },
  {
    date: "10 junio 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 septiembre 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 agosto 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 julio 2021",
    amount: 3021,
    isPaid: false,
  },
  {
    date: "10 junio 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 agosto 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 julio 2021",
    amount: 3021,
    isPaid: false,
  },
  {
    date: "10 junio 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 septiembre 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 agosto 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 julio 2021",
    amount: 3021,
    isPaid: false,
  },
  {
    date: "10 junio 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 septiembre 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 agosto 2021",
    amount: 3021,
    isPaid: true,
  },
  {
    date: "10 julio 2021",
    amount: 3021,
    isPaid: false,
  },
  {
    date: "10 junio 2021",
    amount: 3021,
    isPaid: true,
  },
];

export const performanceObject = {
  "12-2001": {
    max_performance: 42.616,
    expected_performance: 4.002,
    min_performance: -25.304000000000002,
    real: 7.933394628986323,
  },
  "01-2002": {
    max_performance: 42.356831216306986,
    expected_performance: 4.002,
    min_performance: -25.10989080434148,
    real: 12.953415383949185,
  },
  "02-2002": {
    max_performance: 42.09923856501591,
    expected_performance: 4.002,
    min_performance: -24.917131449612665,
    real: 3.768119055279362,
  },
  "03-2002": {
    max_performance: 41.84321246089311,
    expected_performance: 4.002,
    min_performance: -24.725712548980912,
    real: 11.918572920548653,
  },
  "04-2002": {
    max_performance: 41.58874337699744,
    expected_performance: 4.002,
    min_performance: -24.535624780889872,
    real: 12.902014850822852,
  },
  "05-2002": {
    max_performance: 41.33582184432574,
    expected_performance: 4.002,
    min_performance: -24.34685888860557,
    real: 11.304697881579461,
  },
  "06-2002": {
    max_performance: 41.0844384514605,
    expected_performance: 4.002,
    min_performance: -24.15940567976563,
    real: 8.29887564892655,
  },
  "07-2002": {
    max_performance: 40.834583844219665,
    expected_performance: 4.002,
    min_performance: -23.973256025931622,
    real: 13.52131956040541,
  },
  "08-2002": {
    max_performance: 40.586248725308536,
    expected_performance: 4.002,
    min_performance: -23.788400862144535,
    real: 17.3145695667857,
  },
  "09-2002": {
    max_performance: 40.33942385397382,
    expected_performance: 4.002,
    min_performance: -23.604831186483352,
    real: 19.073688724488314,
  },
  "10-2002": {
    max_performance: 40.09410004565978,
    expected_performance: 4.002,
    min_performance: -23.422538059626678,
    real: 12.06724879503287,
  },
  "11-2002": {
    max_performance: 39.85026817166645,
    expected_performance: 4.002,
    min_performance: -23.2415126044174,
    real: 13.56193265861847,
  },
  "12-2002": {
    max_performance: 39.607919158809985,
    expected_performance: 4.002,
    min_performance: -23.061746005430432,
    real: 9.35933246380667,
  },
  "01-2003": {
    max_performance: 39.367043989085,
    expected_performance: 4.002,
    min_performance: -22.883229508543383,
    real: 9.971041415281135,
  },
  "02-2003": {
    max_performance: 39.12763369932903,
    expected_performance: 4.002,
    min_performance: -22.705954420510302,
    real: 17.494016611757445,
  },
  "03-2003": {
    max_performance: 38.889679380888985,
    expected_performance: 4.002,
    min_performance: -22.529912108538312,
    real: 13.75974638697648,
  },
  "04-2003": {
    max_performance: 38.653172179289676,
    expected_performance: 4.002,
    min_performance: -22.355093999867226,
    real: 11.965036630104375,
  },
  "05-2003": {
    max_performance: 38.4181032939043,
    expected_performance: 4.002,
    min_performance: -22.18149158135207,
    real: 5.961803569389246,
  },
  "06-2003": {
    max_performance: 38.184463977627,
    expected_performance: 4.002,
    min_performance: -22.009096399048545,
    real: 9.028795941437263,
  },
  "07-2003": {
    max_performance: 37.95224553654732,
    expected_performance: 4.002,
    min_performance: -21.837900057801306,
    real: 10.09173519608102,
  },
  "08-2003": {
    max_performance: 37.72143932962679,
    expected_performance: 4.002,
    min_performance: -21.667894220835162,
    real: 13.180621263241509,
  },
  "09-2003": {
    max_performance: 37.49203676837728,
    expected_performance: 4.002,
    min_performance: -21.49907060934911,
    real: 8.190779040930662,
  },
  "10-2003": {
    max_performance: 37.26402931654149,
    expected_performance: 4.002,
    min_performance: -21.33142100211315,
    real: 10.12175071378143,
  },
  "11-2003": {
    max_performance: 37.03740848977528,
    expected_performance: 4.002,
    min_performance: -21.16493723506796,
    real: 9.639660360080725,
  },
  "12-2003": {
    max_performance: 36.81216585533196,
    expected_performance: 4.002,
    min_performance: -20.999611200927326,
    real: 11.58445784141422,
  },
  "01-2004": {
    max_performance: 36.58829303174853,
    expected_performance: 4.002,
    min_performance: -20.835434848783322,
    real: 9.48482665998105,
  },
  "02-2004": {
    max_performance: 36.36578168853373,
    expected_performance: 4.002,
    min_performance: -20.672400183714284,
    real: 11.036754052569918,
  },
  "03-2004": {
    max_performance: 36.14462354585814,
    expected_performance: 4.002,
    min_performance: -20.510499266395446,
    real: 7.58276402201663,
  },
  "04-2004": {
    max_performance: 35.92481037424602,
    expected_performance: 4.002,
    min_performance: -20.34972421271235,
    real: 10.720642057683417,
  },
  "05-2004": {
    max_performance: 35.70633399426913,
    expected_performance: 4.002,
    min_performance: -20.19006719337689,
    real: 5.92742795689889,
  },
  "06-2004": {
    max_performance: 35.4891862762423,
    expected_performance: 4.002,
    min_performance: -20.03152043354605,
    real: 6.83714313775754,
  },
  "07-2004": {
    max_performance: 35.273359139921006,
    expected_performance: 4.002,
    min_performance: -19.874076212443303,
    real: 6.62486536973057,
  },
  "08-2004": {
    max_performance: 35.05884455420061,
    expected_performance: 4.002,
    min_performance: -19.717726862982627,
    real: 5.284020880177209,
  },
  "09-2004": {
    max_performance: 34.8456345368176,
    expected_performance: 4.002,
    min_performance: -19.562464771395124,
    real: 7.40133635557203,
  },
  "10-2004": {
    max_performance: 34.63372115405248,
    expected_performance: 4.002,
    min_performance: -19.408282376858278,
    real: 4.207696021103602,
  },
  "11-2004": {
    max_performance: 34.42309652043462,
    expected_performance: 4.002,
    min_performance: -19.255172171127736,
    real: 5.099842818263278,
  },
  "12-2004": {
    max_performance: 34.21375279844879,
    expected_performance: 4.002,
    min_performance: -19.103126698171707,
    real: 7.218455469456542,
  },
  "01-2005": {
    max_performance: 34.005682198243534,
    expected_performance: 4.002,
    min_performance: -18.95213855380785,
    real: 4.418395367531969,
  },
  "02-2005": {
    max_performance: 33.798876977341294,
    expected_performance: 4.002,
    min_performance: -18.802200385342722,
    real: 5.6090290895774775,
  },
  "03-2005": {
    max_performance: 33.59332944035032,
    expected_performance: 4.002,
    min_performance: -18.65330489121373,
    real: 7.9807838590033375,
  },
  "04-2005": {
    max_performance: 33.38903193867831,
    expected_performance: 4.002,
    min_performance: -18.50544482063354,
    real: 5.854428999893879,
  },
  "05-2005": {
    max_performance: 33.185976870247806,
    expected_performance: 4.002,
    min_performance: -18.35861297323702,
    real: 6.096759868462187,
  },
  "06-2005": {
    max_performance: 32.9841566792133,
    expected_performance: 4.002,
    min_performance: -18.212802198730568,
    real: 5.127470385483757,
  },
  "07-2005": {
    max_performance: 32.78356385568008,
    expected_performance: 4.002,
    min_performance: -18.068005396543942,
    real: 6.3186153214224605,
  },
  "08-2005": {
    max_performance: 32.58419093542481,
    expected_performance: 4.002,
    min_performance: -17.924215515484473,
    real: 6.973866298966924,
  },
  "09-2005": {
    max_performance: 32.38603049961772,
    expected_performance: 4.002,
    min_performance: -17.78142555339367,
    real: 6.70460566542701,
  },
  "10-2005": {
    max_performance: 32.1890751745466,
    expected_performance: 4.002,
    min_performance: -17.639628556806272,
    real: 6.433867600846832,
  },
  "11-2005": {
    max_performance: 31.9933176313424,
    expected_performance: 4.002,
    min_performance: -17.498817620611604,
    real: 7.6851516695595645,
  },
  "12-2005": {
    max_performance: 31.798750585706507,
    expected_performance: 4.002,
    min_performance: -17.358985887717335,
    real: 5.606894241175953,
  },
  "01-2006": {
    max_performance: 31.6053667976397,
    expected_performance: 4.002,
    min_performance: -17.220126548715548,
    real: 6.443115702897972,
  },
  "02-2006": {
    max_performance: 31.41315907117275,
    expected_performance: 4.002,
    min_performance: -17.082232841551143,
    real: 6.023495290205783,
  },
  "03-2006": {
    max_performance: 31.22212025409862,
    expected_performance: 4.002,
    min_performance: -16.945298051192555,
    real: 6.449830429009484,
  },
  "04-2006": {
    max_performance: 31.03224323770638,
    expected_performance: 4.002,
    min_performance: -16.809315509304735,
    real: 6.186528984695983,
  },
  "05-2006": {
    max_performance: 30.843520956516638,
    expected_performance: 4.002,
    min_performance: -16.67427859392442,
    real: 5.074505178005939,
  },
  "06-2006": {
    max_performance: 30.65594638801865,
    expected_performance: 4.002,
    min_performance: -16.540180729137685,
    real: 4.934572869052567,
  },
  "07-2006": {
    max_performance: 30.469512552408993,
    expected_performance: 4.002,
    min_performance: -16.407015384759696,
    real: 6.914078460769613,
  },
  "08-2006": {
    max_performance: 30.284212512331866,
    expected_performance: 4.002,
    min_performance: -16.2747760760167,
    real: 6.753420993412829,
  },
  "09-2006": {
    max_performance: 30.100039372620923,
    expected_performance: 4.002,
    min_performance: -16.14345636323026,
    real: 4.506667017786237,
  },
  "10-2006": {
    max_performance: 29.91698628004269,
    expected_performance: 4.002,
    min_performance: -16.01304985150365,
    real: 3.867174566569287,
  },
  "11-2006": {
    max_performance: 29.735046423041577,
    expected_performance: 4.002,
    min_performance: -15.883550190410423,
    real: 6.516562064198594,
  },
  "12-2006": {
    max_performance: 29.554213031486416,
    expected_performance: 4.002,
    min_performance: -15.754951073685197,
    real: 6.0764450586330065,
  },
  "01-2007": {
    max_performance: 29.374479376418492,
    expected_performance: 4.002,
    min_performance: -15.627246238916532,
    real: 6.302913315406133,
  },
  "02-2007": {
    max_performance: 29.195838769801217,
    expected_performance: 4.002,
    min_performance: -15.500429467241984,
    real: 4.414183768446067,
  },
  "03-2007": {
    max_performance: 29.018284564271216,
    expected_performance: 4.002,
    min_performance: -15.37449458304525,
    real: 4.145462612218798,
  },
  "04-2007": {
    max_performance: 28.841810152890982,
    expected_performance: 4.002,
    min_performance: -15.249435453655446,
    real: 5.128490361504445,
  },
  "05-2007": {
    max_performance: 28.666408968903056,
    expected_performance: 4.002,
    min_performance: -15.125245989048457,
    real: 4.527187849069092,
  },
  "06-2007": {
    max_performance: 28.492074485485627,
    expected_performance: 4.002,
    min_performance: -15.001920141550363,
    real: 4.572888574767675,
  },
  "07-2007": {
    max_performance: 28.318800215509704,
    expected_performance: 4.002,
    min_performance: -14.879451905542956,
    real: 5.749659010867547,
  },
  "08-2007": {
    max_performance: 28.146579711297694,
    expected_performance: 4.002,
    min_performance: -14.757835317171258,
    real: 4.689975982641914,
  },
  "09-2007": {
    max_performance: 27.975406564383494,
    expected_performance: 4.002,
    min_performance: -14.63706445405312,
    real: 4.756162685906307,
  },
  "10-2007": {
    max_performance: 27.80527440527403,
    expected_performance: 4.002,
    min_performance: -14.517133434990795,
    real: 5.941932181273568,
  },
  "11-2007": {
    max_performance: 27.63617690321224,
    expected_performance: 4.002,
    min_performance: -14.398036419684567,
    real: 3.9588298661495713,
  },
  "12-2007": {
    max_performance: 27.46810776594149,
    expected_performance: 4.002,
    min_performance: -14.279767608448333,
    real: 4.315438671453409,
  },
  "01-2008": {
    max_performance: 27.301060739471435,
    expected_performance: 4.002,
    min_performance: -14.162321241927158,
    real: 5.16671999092333,
  },
  "02-2008": {
    max_performance: 27.13502960784534,
    expected_performance: 4.002,
    min_performance: -14.04569160081685,
    real: 5.444963787429202,
  },
  "03-2008": {
    max_performance: 26.970008192908715,
    expected_performance: 4.002,
    min_performance: -13.929873005585398,
    real: 4.4534364341830335,
  },
  "04-2008": {
    max_performance: 26.805990354079476,
    expected_performance: 4.002,
    min_performance: -13.81485981619644,
    real: 4.634030697559965,
  },
  "05-2008": {
    max_performance: 26.642969988119432,
    expected_performance: 4.002,
    min_performance: -13.700646431834587,
    real: 4.901578938604834,
  },
  "06-2008": {
    max_performance: 26.480941028907157,
    expected_performance: 4.002,
    min_performance: -13.587227290632667,
    real: 3.8994606844261037,
  },
  "07-2008": {
    max_performance: 26.319897447212295,
    expected_performance: 4.002,
    min_performance: -13.4745968694009,
    real: 4.586285357161413,
  },
  "08-2008": {
    max_performance: 26.159833250471188,
    expected_performance: 4.002,
    min_performance: -13.362749683357936,
    real: 4.562415909620343,
  },
  "09-2008": {
    max_performance: 26.000742482563908,
    expected_performance: 4.002,
    min_performance: -13.251680285863738,
    real: 4.490519055204745,
  },
  "10-2008": {
    max_performance: 25.8426192235926,
    expected_performance: 4.002,
    min_performance: -13.14138326815437,
    real: 4.795304777887277,
  },
  "11-2008": {
    max_performance: 25.68545758966121,
    expected_performance: 4.002,
    min_performance: -13.03185325907859,
    real: 5.049467814627295,
  },
  "12-2008": {
    max_performance: 25.529251732656544,
    expected_performance: 4.002,
    min_performance: -12.923084924836298,
    real: 4.846391615685741,
  },
  "01-2009": {
    max_performance: 25.373995840030645,
    expected_performance: 4.002,
    min_performance: -12.8150729687188,
    real: 4.124847766057282,
  },
  "02-2009": {
    max_performance: 25.21968413458451,
    expected_performance: 4.002,
    min_performance: -12.707812130850852,
    real: 4.599126578442947,
  },
  "03-2009": {
    max_performance: 25.06631087425313,
    expected_performance: 4.002,
    min_performance: -12.601297187934552,
    real: 5.572222409683514,
  },
  "04-2009": {
    max_performance: 24.913870351891784,
    expected_performance: 4.002,
    min_performance: -12.495522952994945,
    real: 5.1354675318725285,
  },
  "05-2009": {
    max_performance: 24.76235689506371,
    expected_performance: 4.002,
    min_performance: -12.390484275127461,
    real: 4.616023181025186,
  },
  "06-2009": {
    max_performance: 24.61176486582901,
    expected_performance: 4.002,
    min_performance: -12.286176039247058,
    real: 4.964032900753024,
  },
  "07-2009": {
    max_performance: 24.462088660534864,
    expected_performance: 4.002,
    min_performance: -12.182593165839155,
    real: 4.047261023716242,
  },
  "08-2009": {
    max_performance: 24.31332270960701,
    expected_performance: 4.002,
    min_performance: -12.079730610712252,
    real: 4.987400119045393,
  },
  "09-2009": {
    max_performance: 24.165461477342493,
    expected_performance: 4.002,
    min_performance: -11.977583364752304,
    real: 4.239892938535728,
  },
  "10-2009": {
    max_performance: 24.01849946170369,
    expected_performance: 4.002,
    min_performance: -11.876146453678789,
    real: 4.499331668186524,
  },
  "11-2009": {
    max_performance: 23.872431194113563,
    expected_performance: 4.002,
    min_performance: -11.775414937802477,
    real: 4.362180091209167,
  },
  "12-2009": {
    max_performance: 23.72725123925216,
    expected_performance: 4.002,
    min_performance: -11.675383911784879,
    real: 4.420688726159816,
  },
  "01-2010": {
    max_performance: 23.582954194854388,
    expected_performance: 4.002,
    min_performance: -11.576048504399367,
    real: 4.0173944748264745,
  },
  "02-2010": {
    max_performance: 23.439534691508964,
    expected_performance: 4.002,
    min_performance: -11.477403878293966,
    real: 4.986262474435477,
  },
  "03-2010": {
    max_performance: 23.29698739245864,
    expected_performance: 4.002,
    min_performance: -11.379445229755788,
    real: 4.247308176803188,
  },
  "04-2010": {
    max_performance: 23.155306993401602,
    expected_performance: 4.002,
    min_performance: -11.282167788477098,
    real: 3.8067766057133356,
  },
  "05-2010": {
    max_performance: 23.014488222294077,
    expected_performance: 4.002,
    min_performance: -11.18556681732302,
    real: 5.199444857606594,
  },
  "06-2010": {
    max_performance: 22.8745258391542,
    expected_performance: 4.002,
    min_performance: -11.08963761210085,
    real: 4.397579941195251,
  },
  "07-2010": {
    max_performance: 22.73541463586698,
    expected_performance: 4.002,
    min_performance: -10.994375501330975,
    real: 4.276148441421768,
  },
  "08-2010": {
    max_performance: 22.597149435990552,
    expected_performance: 4.002,
    min_performance: -10.899775846019391,
    real: 4.0918482964467096,
  },
  "09-2010": {
    max_performance: 22.459725094563506,
    expected_performance: 4.002,
    min_performance: -10.80583403943178,
    real: 4.131374413843569,
  },
  "10-2010": {
    max_performance: 22.323136497913485,
    expected_performance: 4.002,
    min_performance: -10.712545506869201,
    real: 4.040238888522829,
  },
  "11-2010": {
    max_performance: 22.187378563466858,
    expected_performance: 4.002,
    min_performance: -10.619905705445287,
    real: 3.6085394882785504,
  },
  "12-2010": {
    max_performance: 22.052446239559636,
    expected_performance: 4.002,
    min_performance: -10.527910123865047,
    real: 3.991465442062969,
  },
  "01-2011": {
    max_performance: 21.91833450524947,
    expected_performance: 4.002,
    min_performance: -10.436554282205154,
    real: 4.24440614573256,
  },
  "02-2011": {
    max_performance: 21.785038370128806,
    expected_performance: 4.002,
    min_performance: -10.345833731695805,
    real: 3.9131761887473138,
  },
  "03-2011": {
    max_performance: 21.65255287413923,
    expected_performance: 4.002,
    min_performance: -10.255744054504063,
    real: 3.8092050320221116,
  },
  "04-2011": {
    max_performance: 21.52087308738685,
    expected_performance: 4.002,
    min_performance: -10.166280863518734,
    real: 4.403460670445991,
  },
  "05-2011": {
    max_performance: 21.389994109958895,
    expected_performance: 4.002,
    min_performance: -10.077439802136718,
    real: 3.8291458891948293,
  },
  "06-2011": {
    max_performance: 21.259911071741357,
    expected_performance: 4.002,
    min_performance: -9.989216544050858,
    real: 4.356467242879081,
  },
  "07-2011": {
    max_performance: 21.13061913223777,
    expected_performance: 4.002,
    min_performance: -9.901606793039267,
    real: 3.673781252944708,
  },
  "08-2011": {
    max_performance: 21.002113480389113,
    expected_performance: 4.002,
    min_performance: -9.814606282756102,
    real: 3.7475439841638147,
  },
  "09-2011": {
    max_performance: 20.874389334394767,
    expected_performance: 4.002,
    min_performance: -9.728210776523815,
    real: 4.784063289586826,
  },
  "10-2011": {
    max_performance: 20.747441941534586,
    expected_performance: 4.002,
    min_performance: -9.642416067126831,
    real: 4.736012115624133,
  },
  "11-2011": {
    max_performance: 20.621266577992042,
    expected_performance: 4.002,
    min_performance: -9.557217976606681,
    real: 4.096868545861088,
  },
  "12-2011": {
    max_performance: 20.495858548678463,
    expected_performance: 4.002,
    min_performance: -9.472612356058534,
    real: 3.70015939758106,
  },
  "01-2012": {
    max_performance: 20.3712131870583,
    expected_performance: 4.002,
    min_performance: -9.388595085429165,
    real: 4.236424241334191,
  },
  "02-2012": {
    max_performance: 20.247325854975486,
    expected_performance: 4.002,
    min_performance: -9.305162073316312,
    real: 3.5976689931684014,
  },
  "03-2012": {
    max_performance: 20.12419194248088,
    expected_performance: 4.002,
    min_performance: -9.222309256769451,
    real: 4.352690757440358,
  },
  "04-2012": {
    max_performance: 20.001806867660687,
    expected_performance: 4.002,
    min_performance: -9.140032601091924,
    real: 4.068808920741951,
  },
  "05-2012": {
    max_performance: 19.88016607646597,
    expected_performance: 4.002,
    min_performance: -9.058328099644479,
    real: 4.260724659858517,
  },
  "06-2012": {
    max_performance: 19.7592650425432,
    expected_performance: 4.002,
    min_performance: -8.977191773650139,
    real: 3.7181771953349374,
  },
  "07-2012": {
    max_performance: 19.639099267065824,
    expected_performance: 4.002,
    min_performance: -8.896619672000462,
    real: 3.7065620744389127,
  },
  "08-2012": {
    max_performance: 19.51966427856686,
    expected_performance: 4.002,
    min_performance: -8.816607871063125,
    real: 3.240713987792443,
  },
  "09-2012": {
    max_performance: 19.400955632772508,
    expected_performance: 4.002,
    min_performance: -8.737152474490864,
    real: 3.6652077264108796,
  },
  "10-2012": {
    max_performance: 19.282968912436775,
    expected_performance: 4.002,
    min_performance: -8.658249613031714,
    real: 3.620782264728839,
  },
  "11-2012": {
    max_performance: 19.165699727177095,
    expected_performance: 4.002,
    min_performance: -8.57989544434061,
    real: 4.1527341142483465,
  },
  "12-2012": {
    max_performance: 19.049143713310986,
    expected_performance: 4.002,
    min_performance: -8.502086152792259,
    real: 3.2395045914424436,
  },
  "01-2013": {
    max_performance: 18.933296533693657,
    expected_performance: 4.002,
    min_performance: -8.424817949295338,
    real: 3.404055801649153,
  },
  "02-2013": {
    max_performance: 18.818153877556608,
    expected_performance: 4.002,
    min_performance: -8.34808707110798,
    real: 4.060024896854109,
  },
  "03-2013": {
    max_performance: 18.703711460347243,
    expected_performance: 4.002,
    min_performance: -8.271889781654519,
    real: 3.2659534098731458,
  },
  "04-2013": {
    max_performance: 18.58996502356943,
    expected_performance: 4.002,
    min_performance: -8.196222370343555,
    real: 3.6869669936953953,
  },
  "05-2013": {
    max_performance: 18.47691033462504,
    expected_performance: 4.002,
    min_performance: -8.121081152387248,
    real: 4.3768215544597755,
  },
  "06-2013": {
    max_performance: 18.364543186656448,
    expected_performance: 4.002,
    min_performance: -8.046462468621872,
    real: 3.831913172924306,
  },
  "07-2013": {
    max_performance: 18.252859398389987,
    expected_performance: 4.002,
    min_performance: -7.972362685329633,
    real: 3.7030675225603993,
  },
  "08-2013": {
    max_performance: 18.141854813980366,
    expected_performance: 4.002,
    min_performance: -7.898778194061718,
    real: 3.509156978013861,
  },
  "09-2013": {
    max_performance: 18.031525302856032,
    expected_performance: 4.002,
    min_performance: -7.825705411462565,
    real: 3.2972551873964084,
  },
  "10-2013": {
    max_performance: 17.921866759565464,
    expected_performance: 4.002,
    min_performance: -7.753140779095379,
    real: 3.4930881400358826,
  },
  "11-2013": {
    max_performance: 17.81287510362439,
    expected_performance: 4.002,
    min_performance: -7.681080763268826,
    real: 3.4613109040855683,
  },
  "12-2013": {
    max_performance: 17.704546279363974,
    expected_performance: 4.002,
    min_performance: -7.609521854864969,
    real: 3.9237140528144643,
  },
  "01-2014": {
    max_performance: 17.596876255779886,
    expected_performance: 4.002,
    min_performance: -7.538460569168381,
    real: 3.728359347895016,
  },
  "02-2014": {
    max_performance: 17.489861026382314,
    expected_performance: 4.002,
    min_performance: -7.46789344569644,
    real: 3.6501987727478147,
  },
  "03-2014": {
    max_performance: 17.383496609046873,
    expected_performance: 4.002,
    min_performance: -7.397817048030822,
    real: 3.4804026012303257,
  },
  "04-2014": {
    max_performance: 17.277779045866414,
    expected_performance: 4.002,
    min_performance: -7.328227963650155,
    real: 3.631656453423826,
  },
  "05-2014": {
    max_performance: 17.172704403003785,
    expected_performance: 4.002,
    min_performance: -7.259122803763843,
    real: 4.0717247629731945,
  },
  "06-2014": {
    max_performance: 17.06826877054541,
    expected_performance: 4.002,
    min_performance: -7.190498203147026,
    real: 3.7330526939699737,
  },
  "07-2014": {
    max_performance: 16.96446826235582,
    expected_performance: 4.002,
    min_performance: -7.122350819976729,
    real: 3.4693487110557752,
  },
  "08-2014": {
    max_performance: 16.86129901593303,
    expected_performance: 4.002,
    min_performance: -7.0546773356690995,
    real: 3.5429621908836806,
  },
  "09-2014": {
    max_performance: 16.75875719226484,
    expected_performance: 4.002,
    min_performance: -6.987474454717814,
    real: 3.385586735156565,
  },
  "10-2014": {
    max_performance: 16.656838975685954,
    expected_performance: 4.002,
    min_performance: -6.920738904533602,
    real: 3.385325028316122,
  },
  "11-2014": {
    max_performance: 16.555540573736007,
    expected_performance: 4.002,
    min_performance: -6.854467435284862,
    real: 3.461885627478134,
  },
  "12-2014": {
    max_performance: 16.45485821701845,
    expected_performance: 4.002,
    min_performance: -6.788656819739426,
    real: 4.089000565696277,
  },
  "01-2015": {
    max_performance: 16.354788159060277,
    expected_performance: 4.002,
    min_performance: -6.723303853107382,
    real: 3.1338743934920865,
  },
  "02-2015": {
    max_performance: 16.255326676172615,
    expected_performance: 4.002,
    min_performance: -6.658405352885023,
    real: 3.789371577788092,
  },
  "03-2015": {
    max_performance: 16.15647006731218,
    expected_performance: 4.002,
    min_performance: -6.593958158699863,
    real: 3.086746500922688,
  },
  "04-2015": {
    max_performance: 16.058214653943544,
    expected_performance: 4.002,
    min_performance: -6.529959132156744,
    real: 3.3392023596123366,
  },
  "05-2015": {
    max_performance: 15.960556779902248,
    expected_performance: 4.002,
    min_performance: -6.4664051566849885,
    real: 3.859937268307201,
  },
  "06-2015": {
    max_performance: 15.863492811258768,
    expected_performance: 4.002,
    min_performance: -6.403293137386646,
    real: 4.030024078504479,
  },
  "07-2015": {
    max_performance: 15.767019136183285,
    expected_performance: 4.002,
    min_performance: -6.3406200008857745,
    real: 3.8449475055768234,
  },
  "08-2015": {
    max_performance: 15.67113216481129,
    expected_performance: 4.002,
    min_performance: -6.278382695178781,
    real: 3.694889232474474,
  },
  "09-2015": {
    max_performance: 15.57582832910999,
    expected_performance: 4.002,
    min_performance: -6.216578189485782,
    real: 3.6118687093848134,
  },
  "10-2015": {
    max_performance: 15.481104082745556,
    expected_performance: 4.002,
    min_performance: -6.155203474103038,
    real: 4.007873530218853,
  },
  "11-2015": {
    max_performance: 15.38695590095116,
    expected_performance: 4.002,
    min_performance: -6.094255560256366,
    real: 3.9989910367449477,
  },
  "12-2015": {
    max_performance: 15.29338028039579,
    expected_performance: 4.002,
    min_performance: -6.033731479955609,
    real: 3.6970938552739696,
  },
  "01-2016": {
    max_performance: 15.200373739053928,
    expected_performance: 4.002,
    min_performance: -5.973628285850101,
    real: 3.984534916544047,
  },
  "02-2016": {
    max_performance: 15.107932816075943,
    expected_performance: 4.002,
    min_performance: -5.913943051085129,
    real: 3.8395190584313177,
  },
  "03-2016": {
    max_performance: 15.016054071659335,
    expected_performance: 4.002,
    min_performance: -5.854672869159421,
    real: 3.7896774969380376,
  },
  "04-2016": {
    max_performance: 14.924734086920727,
    expected_performance: 4.002,
    min_performance: -5.795814853783591,
    real: 3.9669306686626715,
  },
  "05-2016": {
    max_performance: 14.833969463768652,
    expected_performance: 4.002,
    min_performance: -5.737366138739604,
    real: 3.8059248480560637,
  },
  "06-2016": {
    max_performance: 14.743756824777096,
    expected_performance: 4.002,
    min_performance: -5.679323877741172,
    real: 3.4740914789294752,
  },
  "07-2016": {
    max_performance: 14.654092813059828,
    expected_performance: 4.002,
    min_performance: -5.6216852442951755,
    real: 3.687894544370618,
  },
  "08-2016": {
    max_performance: 14.564974092145494,
    expected_performance: 4.002,
    min_performance: -5.564447431564002,
    real: 3.9555633705731674,
  },
  "09-2016": {
    max_performance: 14.476397345853455,
    expected_performance: 4.002,
    min_performance: -5.507607652228877,
    real: 3.9148865493378704,
  },
  "10-2016": {
    max_performance: 14.388359278170388,
    expected_performance: 4.002,
    min_performance: -5.4511631383541115,
    real: 4.149291880841965,
  },
  "11-2016": {
    max_performance: 14.300856613127646,
    expected_performance: 4.002,
    min_performance: -5.395111141252327,
    real: 4.507608059268975,
  },
  "12-2016": {
    max_performance: 14.213886094679351,
    expected_performance: 4.002,
    min_performance: -5.339448931350594,
    real: 4.123491068549723,
  },
  "01-2017": {
    max_performance: 14.12744448658124,
    expected_performance: 4.002,
    min_performance: -5.284173798057511,
    real: 4.035780349395357,
  },
  "02-2017": {
    max_performance: 14.041528572270238,
    expected_performance: 4.002,
    min_performance: -5.22928304963121,
    real: 4.026871388338571,
  },
  "03-2017": {
    max_performance: 13.956135154744754,
    expected_performance: 4.002,
    min_performance: -5.174774013048271,
    real: 3.6468094603406165,
  },
  "04-2017": {
    max_performance: 13.871261056445746,
    expected_performance: 4.002,
    min_performance: -5.120644033873557,
    real: 3.8016988837380916,
  },
  "05-2017": {
    max_performance: 13.786903119138461,
    expected_performance: 4.002,
    min_performance: -5.066890476130945,
    real: 4.377084572084722,
  },
  "06-2017": {
    max_performance: 13.703058203794916,
    expected_performance: 4.002,
    min_performance: -5.013510722174974,
    real: 4.2786341501723335,
  },
  "07-2017": {
    max_performance: 13.619723190477101,
    expected_performance: 4.002,
    min_performance: -4.960502172563357,
    real: 3.9711357088437094,
  },
  "08-2017": {
    max_performance: 13.536894978220868,
    expected_performance: 4.002,
    min_performance: -4.907862245930406,
    real: 4.045458745598718,
  },
  "09-2017": {
    max_performance: 13.45457048492057,
    expected_performance: 4.002,
    min_performance: -4.855588378861327,
    real: 3.9064473655596954,
  },
  "10-2017": {
    max_performance: 13.372746647214338,
    expected_performance: 4.002,
    min_performance: -4.803678025767384,
    real: 4.120521651525033,
  },
  "11-2017": {
    max_performance: 13.291420420370118,
    expected_performance: 4.002,
    min_performance: -4.7521286587619365,
    real: 4.242543828194578,
  },
  "12-2017": {
    max_performance: 13.210588778172356,
    expected_performance: 4.002,
    min_performance: -4.700937767537344,
    real: 3.9893411161157077,
  },
  "01-2018": {
    max_performance: 13.130248712809404,
    expected_performance: 4.002,
    min_performance: -4.650102859242713,
    real: 3.958719385521725,
  },
  "02-2018": {
    max_performance: 13.050397234761594,
    expected_performance: 4.002,
    min_performance: -4.5996214583625115,
    real: 4.1548236326101495,
  },
  "03-2018": {
    max_performance: 12.971031372689984,
    expected_performance: 4.002,
    min_performance: -4.549491106596012,
    real: 4.384693059888556,
  },
  "04-2018": {
    max_performance: 12.8921481733258,
    expected_performance: 4.002,
    min_performance: -4.49970936273758,
    real: 4.705361610169367,
  },
  "05-2018": {
    max_performance: 12.813744701360553,
    expected_performance: 4.002,
    min_performance: -4.4502738025577955,
    real: 4.429071450235056,
  },
  "06-2018": {
    max_performance: 12.735818039336795,
    expected_performance: 4.002,
    min_performance: -4.401182018685402,
    real: 4.135092432325438,
  },
  "07-2018": {
    max_performance: 12.658365287539576,
    expected_performance: 4.002,
    min_performance: -4.3524316204900675,
    real: 4.634171951341748,
  },
  "08-2018": {
    max_performance: 12.581383563888517,
    expected_performance: 4.002,
    min_performance: -4.304020233965975,
    real: 4.286813223893127,
  },
  "09-2018": {
    max_performance: 12.504870003830598,
    expected_performance: 4.002,
    min_performance: -4.255945501616214,
    real: 4.492773581451304,
  },
  "10-2018": {
    max_performance: 12.428821760233545,
    expected_performance: 4.002,
    min_performance: -4.208205082337971,
    real: 4.419111545661036,
  },
  "11-2018": {
    max_performance: 12.35323600327989,
    expected_performance: 4.002,
    min_performance: -4.16079665130853,
    real: 4.790719085820094,
  },
  "12-2018": {
    max_performance: 12.278109920361674,
    expected_performance: 4.002,
    min_performance: -4.113717899872064,
    real: 4.46440881233201,
  },
  "01-2019": {
    max_performance: 12.20344071597578,
    expected_performance: 4.002,
    min_performance: -4.066966535427197,
    real: 4.6128935720408215,
  },
  "02-2019": {
    max_performance: 12.12922561161992,
    expected_performance: 4.002,
    min_performance: -4.020540281315373,
    real: 4.211026403638574,
  },
  "03-2019": {
    max_performance: 12.055461845689246,
    expected_performance: 4.002,
    min_performance: -3.9744368767099876,
    real: 4.385150641501823,
  },
  "04-2019": {
    max_performance: 11.982146673373572,
    expected_performance: 4.002,
    min_performance: -3.928654076506286,
    real: 4.379954628638605,
  },
  "05-2019": {
    max_performance: 11.909277366555251,
    expected_performance: 4.002,
    min_performance: -3.8831896512120365,
    real: 4.911612881731253,
  },
  "06-2019": {
    max_performance: 11.836851213707662,
    expected_performance: 4.002,
    min_performance: -3.8380413868389627,
    real: 5.08165465274113,
  },
  "07-2019": {
    max_performance: 11.7648655197943,
    expected_performance: 4.002,
    min_performance: -3.7932070847949233,
    real: 4.635685597196426,
  },
  "08-2019": {
    max_performance: 11.693317606168506,
    expected_performance: 4.002,
    min_performance: -3.7486845617768547,
    real: 4.825729931489953,
  },
  "09-2019": {
    max_performance: 11.622204810473772,
    expected_performance: 4.002,
    min_performance: -3.70447164966444,
    real: 5.029980651703769,
  },
  "10-2019": {
    max_performance: 11.551524486544695,
    expected_performance: 4.002,
    min_performance: -3.660566195414536,
    real: 4.899281010510159,
  },
  "11-2019": {
    max_performance: 11.481274004308494,
    expected_performance: 4.002,
    min_performance: -3.6169660609563263,
    real: 4.864471451263785,
  },
  "12-2019": {
    max_performance: 11.411450749687159,
    expected_performance: 4.002,
    min_performance: -3.573669123087194,
    real: 4.845677461412867,
  },
  "01-2020": {
    max_performance: 11.342052124500162,
    expected_performance: 4.002,
    min_performance: -3.530673273369342,
    real: 4.955321149951031,
  },
  "02-2020": {
    max_performance: 11.273075546367783,
    expected_performance: 4.002,
    min_performance: -3.487976418027102,
    real: 4.799647595072742,
  },
  "03-2020": {
    max_performance: 11.204518448615023,
    expected_performance: 4.002,
    min_performance: -3.445576477844989,
    real: 4.958649511754887,
  },
  "04-2020": {
    max_performance: 11.136378280176093,
    expected_performance: 4.002,
    min_performance: -3.403471388066438,
    real: 5.181938184137036,
  },
  "05-2020": {
    max_performance: 11.068652505499482,
    expected_performance: 4.002,
    min_performance: -3.3616590982932633,
    real: 5.066747341443034,
  },
  "06-2020": {
    max_performance: 11.00133860445361,
    expected_performance: 4.002,
    min_performance: -3.320137572385804,
    real: 4.777360125564312,
  },
  "07-2020": {
    max_performance: 10.934434072233051,
    expected_performance: 4.002,
    min_performance: -3.2789047883637754,
    real: 5.309091190304516,
  },
  "08-2020": {
    max_performance: 10.867936419265337,
    expected_performance: 4.002,
    min_performance: -3.237958738307801,
    real: 5.118381668542327,
  },
  "09-2020": {
    max_performance: 10.801843171118305,
    expected_performance: 4.002,
    min_performance: -3.197297428261636,
    real: 4.985140767117913,
  },
  "10-2020": {
    max_performance: 10.736151868408022,
    expected_performance: 4.002,
    min_performance: -3.1569188781350594,
    real: 5.197533510790553,
  },
  "11-2020": {
    max_performance: 10.670860066707283,
    expected_performance: 4.002,
    min_performance: -3.1168211216074586,
    real: 5.269843227559723,
  },
  "12-2020": {
    max_performance: 10.605965336454632,
    expected_performance: 4.002,
    min_performance: -3.0770022060320694,
    real: 5.448170293882737,
  },
  "01-2021": {
    max_performance: 10.54146526286398,
    expected_performance: 4.002,
    min_performance: -3.0374601923408906,
    real: 5.354806309405058,
  },
  "02-2021": {
    max_performance: 10.47735744583473,
    expected_performance: 4.002,
    min_performance: -2.9981931549502554,
    real: 5.362955982608764,
  },
  "03-2021": {
    max_performance: 10.413639499862468,
    expected_performance: 4.002,
    min_performance: -2.9591991816670586,
    real: 5.305365520724781,
  },
  "04-2021": {
    max_performance: 10.350309053950209,
    expected_performance: 4.002,
    min_performance: -2.9204763735956463,
    real: 5.181065582331579,
  },
  "05-2021": {
    max_performance: 10.287363751520157,
    expected_performance: 4.002,
    min_performance: -2.882022845045333,
    real: 5.418808261554786,
  },
  "06-2021": {
    max_performance: 10.224801250326028,
    expected_performance: 4.002,
    min_performance: -2.8438367234385855,
    real: 5.800109042541202,
  },
  "07-2021": {
    max_performance: 10.162619222365876,
    expected_performance: 4.002,
    min_performance: -2.805916149219826,
    real: 5.724124662802654,
  },
  "08-2021": {
    max_performance: 10.100815353795484,
    expected_performance: 4.002,
    min_performance: -2.768259275764881,
    real: 5.4246052604567385,
  },
  "09-2021": {
    max_performance: 10.039387344842254,
    expected_performance: 4.002,
    min_performance: -2.730864269291056,
    real: 5.872774845902851,
  },
  "10-2021": {
    max_performance: 9.97833290971963,
    expected_performance: 4.002,
    min_performance: -2.69372930876783,
    real: 5.716976185125389,
  },
  "11-2021": {
    max_performance: 9.917649776542042,
    expected_performance: 4.002,
    min_performance: -2.6568525858281826,
    real: 5.516558439342578,
  },
  "12-2021": {
    max_performance: 9.857335687240377,
    expected_performance: 4.002,
    min_performance: -2.6202323046805325,
    real: null,
  },
  "01-2022": {
    max_performance: 9.797388397477949,
    expected_performance: 4.002,
    min_performance: -2.5838666820212812,
    real: null,
  },
  "02-2022": {
    max_performance: 9.737805676566968,
    expected_performance: 4.002,
    min_performance: -2.547753946947974,
    real: null,
  },
  "03-2022": {
    max_performance: 9.67858530738556,
    expected_performance: 4.002,
    min_performance: -2.5118923408730667,
    real: null,
  },
  "04-2022": {
    max_performance: 9.619725086295263,
    expected_performance: 4.002,
    min_performance: -2.476280117438279,
    real: null,
  },
  "05-2022": {
    max_performance: 9.561222823059005,
    expected_performance: 4.002,
    min_performance: -2.44091554242956,
    real: null,
  },
  "06-2022": {
    max_performance: 9.503076340759632,
    expected_performance: 4.002,
    min_performance: -2.405796893692632,
    real: null,
  },
  "07-2022": {
    max_performance: 9.445283475718885,
    expected_performance: 4.002,
    min_performance: -2.3709224610491275,
    real: null,
  },
  "08-2022": {
    max_performance: 9.38784207741689,
    expected_performance: 4.002,
    min_performance: -2.336290546213308,
    real: null,
  },
  "09-2022": {
    max_performance: 9.330750008412148,
    expected_performance: 4.002,
    min_performance: -2.3018994627093643,
    real: null,
  },
  "10-2022": {
    max_performance: 9.274005144261976,
    expected_performance: 4.002,
    min_performance: -2.2677475357892862,
    real: null,
  },
  "11-2022": {
    max_performance: 9.217605373443476,
    expected_performance: 4.002,
    min_performance: -2.2338331023513107,
    real: null,
  },
  "12-2022": {
    max_performance: 9.161548597274958,
    expected_performance: 4.002,
    min_performance: -2.2001545108589338,
    real: null,
  },
  "01-2023": {
    max_performance: 9.105832729837841,
    expected_performance: 4.002,
    min_performance: -2.1667101212604813,
    real: null,
  },
  "02-2023": {
    max_performance: 9.050455697899038,
    expected_performance: 4.002,
    min_performance: -2.133498304909246,
    real: null,
  },
  "03-2023": {
    max_performance: 8.995415440833803,
    expected_performance: 4.002,
    min_performance: -2.1005174444841788,
    real: null,
  },
  "04-2023": {
    max_performance: 8.940709910549069,
    expected_performance: 4.002,
    min_performance: -2.0677659339111263,
    real: null,
  },
  "05-2023": {
    max_performance: 8.886337071407219,
    expected_performance: 4.002,
    min_performance: -2.035242178284619,
    real: null,
  },
  "06-2023": {
    max_performance: 8.832294900150352,
    expected_performance: 4.002,
    min_performance: -2.0029445937902106,
    real: null,
  },
  "07-2023": {
    max_performance: 8.778581385824982,
    expected_performance: 4.002,
    min_performance: -1.9708716076273407,
    real: null,
  },
  "08-2023": {
    max_performance: 8.725194529707224,
    expected_performance: 4.002,
    min_performance: -1.9390216579327517,
    real: null,
  },
  "09-2023": {
    max_performance: 8.672132345228409,
    expected_performance: 4.002,
    min_performance: -1.9073931937044288,
    real: null,
  },
  "10-2023": {
    max_performance: 8.619392857901168,
    expected_performance: 4.002,
    min_performance: -1.87598467472607,
    real: null,
  },
  "11-2023": {
    max_performance: 8.566974105245958,
    expected_performance: 4.002,
    min_performance: -1.8447945714920793,
    real: null,
  },
  "12-2023": {
    max_performance: 8.51487413671803,
    expected_performance: 4.002,
    min_performance: -1.8138213651330894,
    real: null,
  },
  "01-2024": {
    max_performance: 8.46309101363486,
    expected_performance: 4.002,
    min_performance: -1.783063547341992,
    real: null,
  },
  "02-2024": {
    max_performance: 8.411622809104003,
    expected_performance: 4.002,
    min_performance: -1.752519620300491,
    real: null,
  },
  "03-2024": {
    max_performance: 8.36046760795139,
    expected_performance: 4.002,
    min_performance: -1.7221880966061636,
    real: null,
  },
  "04-2024": {
    max_performance: 8.309623506650055,
    expected_performance: 4.002,
    min_performance: -1.692067499200021,
    real: null,
  },
  "05-2024": {
    max_performance: 8.25908861324933,
    expected_performance: 4.002,
    min_performance: -1.6621563612945907,
    real: null,
  },
  "06-2024": {
    max_performance: 8.208861047304412,
    expected_performance: 4.002,
    min_performance: -1.6324532263024794,
    real: null,
  },
  "07-2024": {
    max_performance: 8.15893893980641,
    expected_performance: 4.002,
    min_performance: -1.6029566477654442,
    real: null,
  },
  "08-2024": {
    max_performance: 8.109320433112792,
    expected_performance: 4.002,
    min_performance: -1.5736651892839562,
    real: null,
  },
  "09-2024": {
    max_performance: 8.060003680878262,
    expected_performance: 4.002,
    min_performance: -1.5445774244472505,
    real: null,
  },
  "10-2024": {
    max_performance: 8.010986847986052,
    expected_performance: 4.002,
    min_performance: -1.5156919367638602,
    real: null,
  },
  "11-2024": {
    max_performance: 7.962268110479639,
    expected_performance: 4.002,
    min_performance: -1.4870073195926445,
    real: null,
  },
  "12-2024": {
    max_performance: 7.913845655494878,
    expected_performance: 4.002,
    min_performance: -1.4585221760742857,
    real: null,
  },
  "01-2025": {
    max_performance: 7.86571768119253,
    expected_performance: 4.002,
    min_performance: -1.4302351190632616,
    real: null,
  },
  "02-2025": {
    max_performance: 7.817882396691233,
    expected_performance: 4.002,
    min_performance: -1.402144771060303,
    real: null,
  },
  "03-2025": {
    max_performance: 7.770338022000847,
    expected_performance: 4.002,
    min_performance: -1.3742497641453117,
    real: null,
  },
  "04-2025": {
    max_performance: 7.723082787956227,
    expected_performance: 4.002,
    min_performance: -1.3465487399107414,
    real: null,
  },
  "05-2025": {
    max_performance: 7.676114936151386,
    expected_performance: 4.002,
    min_performance: -1.3190403493954554,
    real: null,
  },
  "06-2025": {
    max_performance: 7.629432718874069,
    expected_performance: 4.002,
    min_performance: -1.2917232530190268,
    real: null,
  },
  "07-2025": {
    max_performance: 7.583034399040715,
    expected_performance: 4.002,
    min_performance: -1.2645961205165137,
    real: null,
  },
  "08-2025": {
    max_performance: 7.536918250131817,
    expected_performance: 4.002,
    min_performance: -1.2376576308736729,
    real: null,
  },
  "09-2025": {
    max_performance: 7.491082556127679,
    expected_performance: 4.002,
    min_performance: -1.2109064722626304,
    real: null,
  },
  "10-2025": {
    max_performance: 7.445525611444565,
    expected_performance: 4.002,
    min_performance: -1.184341341978004,
    real: null,
  },
  "11-2025": {
    max_performance: 7.400245720871229,
    expected_performance: 4.002,
    min_performance: -1.157960946373462,
    real: null,
  },
  "12-2025": {
    max_performance: 7.355241199505836,
    expected_performance: 4.002,
    min_performance: -1.1317640007987246,
    real: null,
  },
  "01-2026": {
    max_performance: 7.310510372693262,
    expected_performance: 4.002,
    min_performance: -1.105749229537008,
    real: null,
  },
  "02-2026": {
    max_performance: 7.266051575962783,
    expected_performance: 4.002,
    min_performance: -1.0799153657429001,
    real: null,
  },
  "03-2026": {
    max_performance: 7.2218631549661385,
    expected_performance: 4.002,
    min_performance: -1.054261151380668,
    real: null,
  },
  "04-2026": {
    max_performance: 7.177943465415971,
    expected_performance: 4.002,
    min_performance: -1.0287853371629945,
    real: null,
  },
  "05-2026": {
    max_performance: 7.13429087302464,
    expected_performance: 4.002,
    min_performance: -1.003486682490145,
    real: null,
  },
  "06-2026": {
    max_performance: 7.090903753443403,
    expected_performance: 4.002,
    min_performance: -0.9783639553895465,
    real: null,
  },
  "07-2026": {
    max_performance: 7.047780492201988,
    expected_performance: 4.002,
    min_performance: -0.9534159324558025,
    real: null,
  },
  "08-2026": {
    max_performance: 7.004919484648503,
    expected_performance: 4.002,
    min_performance: -0.9286413987911115,
    real: null,
  },
  "09-2026": {
    max_performance: 6.9623191358897305,
    expected_performance: 4.002,
    min_performance: -0.9040391479461078,
    real: null,
  },
  "10-2026": {
    max_performance: 6.919977860731781,
    expected_performance: 4.002,
    min_performance: -0.8796079818611058,
    real: null,
  },
  "11-2026": {
    max_performance: 6.877894083621109,
    expected_performance: 4.002,
    min_performance: -0.8553467108077659,
    real: null,
  },
  "12-2026": {
    max_performance: 6.836066238585876,
    expected_performance: 4.002,
    min_performance: -0.831254153331149,
    real: null,
  },
  "01-2027": {
    max_performance: 6.794492769177692,
    expected_performance: 4.002,
    min_performance: -0.8073291361921919,
    real: null,
  },
  "02-2027": {
    max_performance: 6.753172128413691,
    expected_performance: 4.002,
    min_performance: -0.7835704943105681,
    real: null,
  },
  "03-2027": {
    max_performance: 6.712102778718963,
    expected_performance: 4.002,
    min_performance: -0.7599770707079498,
    real: null,
  },
  "04-2027": {
    max_performance: 6.67128319186935,
    expected_performance: 4.002,
    min_performance: -0.7365477164516743,
    real: null,
  },
  "05-2027": {
    max_performance: 6.630711848934574,
    expected_performance: 4.002,
    min_performance: -0.7132812905987884,
    real: null,
  },
  "06-2027": {
    max_performance: 6.590387240221713,
    expected_performance: 4.002,
    min_performance: -0.6901766601404877,
    real: null,
  },
  "07-2027": {
    max_performance: 6.550307865219033,
    expected_performance: 4.002,
    min_performance: -0.6672326999469456,
    real: null,
  },
  "08-2027": {
    max_performance: 6.510472232540144,
    expected_performance: 4.002,
    min_performance: -0.6444482927125192,
    real: null,
  },
  "09-2027": {
    max_performance: 6.470878859868504,
    expected_performance: 4.002,
    min_performance: -0.6218223289013416,
    real: null,
  },
  "10-2027": {
    max_performance: 6.431526273902272,
    expected_performance: 4.002,
    min_performance: -0.5993537066932899,
    real: null,
  },
  "11-2027": {
    max_performance: 6.392413010299474,
    expected_performance: 4.002,
    min_performance: -0.5770413319303329,
    real: null,
  },
  "12-2027": {
    max_performance: 6.353537613623514,
    expected_performance: 4.002,
    min_performance: -0.5548841180632396,
    real: null,
  },
  "01-2028": {
    max_performance: 6.314898637289025,
    expected_performance: 4.002,
    min_performance: -0.5328809860986792,
    real: null,
  },
  "02-2028": {
    max_performance: 6.2764946435080295,
    expected_performance: 4.002,
    min_performance: -0.5110308645466692,
    real: null,
  },
  "03-2028": {
    max_performance: 6.2383242032364485,
    expected_performance: 4.002,
    min_performance: -0.48933268936840024,
    real: null,
  },
  "04-2028": {
    max_performance: 6.200385896120918,
    expected_performance: 4.002,
    min_performance: -0.4677854039244189,
    real: null,
  },
  "05-2028": {
    max_performance: 6.162678310445936,
    expected_performance: 4.002,
    min_performance: -0.44638795892317207,
    real: null,
  },
  "06-2028": {
    max_performance: 6.125200043081342,
    expected_performance: 4.002,
    min_performance: -0.4251393123699123,
    real: null,
  },
  "07-2028": {
    max_performance: 6.08794969943009,
    expected_performance: 4.002,
    min_performance: -0.4040384295159529,
    real: null,
  },
  "08-2028": {
    max_performance: 6.050925893376366,
    expected_performance: 4.002,
    min_performance: -0.383084282808277,
    real: null,
  },
  "09-2028": {
    max_performance: 6.014127247234003,
    expected_performance: 4.002,
    min_performance: -0.3622758518395037,
    real: null,
  },
  "10-2028": {
    max_performance: 5.977552391695223,
    expected_performance: 4.002,
    min_performance: -0.34161212329819346,
    real: null,
  },
  "11-2028": {
    max_performance: 5.941199965779678,
    expected_performance: 4.002,
    min_performance: -0.3210920909195041,
    real: null,
  },
  "12-2028": {
    max_performance: 5.9050686167838045,
    expected_performance: 4.002,
    min_performance: -0.3007147554361871,
    real: null,
  },
  "01-2029": {
    max_performance: 5.8691570002305005,
    expected_performance: 4.002,
    min_performance: -0.28047912452992785,
    real: null,
  },
  "02-2029": {
    max_performance: 5.8334637798190805,
    expected_performance: 4.002,
    min_performance: -0.26038421278302204,
    real: null,
  },
  "03-2029": {
    max_performance: 5.797987627375562,
    expected_performance: 4.002,
    min_performance: -0.24042904163038736,
    real: null,
  },
  "04-2029": {
    max_performance: 5.7627272228032425,
    expected_performance: 4.002,
    min_performance: -0.22061263931191366,
    real: null,
  },
  "05-2029": {
    max_performance: 5.727681254033564,
    expected_performance: 4.002,
    min_performance: -0.20093404082513455,
    real: null,
  },
  "06-2029": {
    max_performance: 5.6928484169773155,
    expected_performance: 4.002,
    min_performance: -0.18139228787824146,
    real: null,
  },
  "07-2029": {
    max_performance: 5.6582274154760785,
    expected_performance: 4.002,
    min_performance: -0.16198642884341474,
    real: null,
  },
  "08-2029": {
    max_performance: 5.6238169612540165,
    expected_performance: 4.002,
    min_performance: -0.14271551871048072,
    real: null,
  },
  "09-2029": {
    max_performance: 5.5896157738699275,
    expected_performance: 4.002,
    min_performance: -0.12357861904089384,
    real: null,
  },
  "10-2029": {
    max_performance: 5.555622580669599,
    expected_performance: 4.002,
    min_performance: -0.10457479792203817,
    real: null,
  },
  "11-2029": {
    max_performance: 5.521836116738457,
    expected_performance: 4.002,
    min_performance: -0.08570312992184403,
    real: null,
  },
  "12-2029": {
    max_performance: 5.488255124854487,
    expected_performance: 3.5834,
    min_performance: -0.06696269604372285,
    real: null,
  },
  "01-2030": {
    max_performance: 5.454878355441464,
    expected_performance: 3.5834,
    min_performance: -0.048352583681816164,
    real: null,
  },
  "02-2030": {
    max_performance: 5.421704566522439,
    expected_performance: 3.5834,
    min_performance: -0.029871886576549933,
    real: null,
  },
  "03-2030": {
    max_performance: 5.388732523673545,
    expected_performance: 3.5834,
    min_performance: -0.011519704770508454,
    real: null,
  },
  "04-2030": {
    max_performance: 5.355960999978044,
    expected_performance: 3.5834,
    min_performance: 0.006704855435395896,
    real: null,
  },
  "05-2030": {
    max_performance: 5.323388775980684,
    expected_performance: 3.5834,
    min_performance: 0.024802681525441628,
    real: null,
  },
  "06-2030": {
    max_performance: 5.291014639642314,
    expected_performance: 3.5834,
    min_performance: 0.04277465481231468,
    real: null,
  },
  "07-2030": {
    max_performance: 5.2588373862947915,
    expected_performance: 3.5834,
    min_performance: 0.06062165048002832,
    real: null,
  },
  "08-2030": {
    max_performance: 5.226855818596149,
    expected_performance: 3.5834,
    min_performance: 0.07834453762654192,
    real: null,
  },
  "09-2030": {
    max_performance: 5.195068746486042,
    expected_performance: 3.5834,
    min_performance: 0.09594417930608046,
    real: null,
  },
  "10-2030": {
    max_performance: 5.163474987141469,
    expected_performance: 3.5834,
    min_performance: 0.11342143257116577,
    real: null,
  },
  "11-2030": {
    max_performance: 5.132073364932751,
    expected_performance: 3.5834,
    min_performance: 0.13077714851435385,
    real: null,
  },
  "12-2030": {
    max_performance: 5.100862711379791,
    expected_performance: 3.105,
    min_performance: 0.1480121723096759,
    real: null,
  },
  "01-2031": {
    max_performance: 5.069841865108593,
    expected_performance: 3.105,
    min_performance: 0.16512734325380007,
    real: null,
  },
  "02-2031": {
    max_performance: 5.039009671808046,
    expected_performance: 3.105,
    min_performance: 0.18212349480690015,
    real: null,
  },
  "03-2031": {
    max_performance: 5.008364984186969,
    expected_performance: 3.105,
    min_performance: 0.1990014546332457,
    real: null,
  },
  "04-2031": {
    max_performance: 4.977906661931421,
    expected_performance: 3.105,
    min_performance: 0.21576204464150317,
    real: null,
  },
  "05-2031": {
    max_performance: 4.947633571662271,
    expected_performance: 3.105,
    min_performance: 0.23240608102476346,
    real: null,
  },
  "06-2031": {
    max_performance: 4.917544586893021,
    expected_performance: 3.105,
    min_performance: 0.24893437430028653,
    real: null,
  },
  "07-2031": {
    max_performance: 4.887638587987886,
    expected_performance: 3.105,
    min_performance: 0.2653477293489743,
    real: null,
  },
  "08-2031": {
    max_performance: 4.8579144621201396,
    expected_performance: 3.105,
    min_performance: 0.28164694545456204,
    real: null,
  },
  "09-2031": {
    max_performance: 4.828371103230699,
    expected_performance: 3.105,
    min_performance: 0.29783281634254166,
    real: null,
  },
  "10-2031": {
    max_performance: 4.7990074119869695,
    expected_performance: 3.105,
    min_performance: 0.3139061302188173,
    real: null,
  },
  "11-2031": {
    max_performance: 4.7698222957419345,
    expected_performance: 3.105,
    min_performance: 0.3298676698080856,
    real: null,
  },
  "12-2031": {
    max_performance: 4.740814668493501,
    expected_performance: 2.4472,
    min_performance: 0.345718212391954,
    real: null,
  },
  "01-2032": {
    max_performance: 4.711983450844086,
    expected_performance: 2.4472,
    min_performance: 0.3614585298467894,
    real: null,
  },
  "02-2032": {
    max_performance: 4.683327569960453,
    expected_performance: 2.4472,
    min_performance: 0.3770893886813105,
    real: null,
  },
  "03-2032": {
    max_performance: 4.654845959533794,
    expected_performance: 2.4472,
    min_performance: 0.39261155007391,
    real: null,
  },
  "04-2032": {
    max_performance: 4.626537559740037,
    expected_performance: 2.4472,
    min_performance: 0.4080257699097274,
    real: null,
  },
  "05-2032": {
    max_performance: 4.59840131720043,
    expected_performance: 2.4472,
    min_performance: 0.423332798817452,
    real: null,
  },
  "06-2032": {
    max_performance: 4.570436184942331,
    expected_performance: 2.4472,
    min_performance: 0.4385333822058808,
    real: null,
  },
  "07-2032": {
    max_performance: 4.542641122360248,
    expected_performance: 2.4472,
    min_performance: 0.4536282603002171,
    real: null,
  },
  "08-2032": {
    max_performance: 4.515015095177125,
    expected_performance: 2.4472,
    min_performance: 0.4686181681781161,
    real: null,
  },
  "09-2032": {
    max_performance: 4.48755707540585,
    expected_performance: 2.4472,
    min_performance: 0.4835038358054824,
    real: null,
  },
  "10-2032": {
    max_performance: 4.460266041311005,
    expected_performance: 2.4472,
    min_performance: 0.4982859880720163,
    real: null,
  },
  "11-2032": {
    max_performance: 4.433140977370846,
    expected_performance: 2.4472,
    min_performance: 0.5129653448265143,
    real: null,
  },
  "12-2032": {
    max_performance: 4.406180874239515,
    expected_performance: 1.8492,
    min_performance: 0.5275426209119232,
    real: null,
  },
  "01-2033": {
    max_performance: 4.379384728709478,
    expected_performance: 1.8492,
    min_performance: 0.5420185262001529,
    real: null,
  },
  "02-2033": {
    max_performance: 4.3527515436741995,
    expected_performance: 1.8492,
    min_performance: 0.5563937656266429,
    real: null,
  },
  "03-2033": {
    max_performance: 4.32628032809104,
    expected_performance: 1.8492,
    min_performance: 0.5706690392246903,
    real: null,
  },
  "04-2033": {
    max_performance: 4.2999700969443735,
    expected_performance: 1.8492,
    min_performance: 0.5848450421595417,
    real: null,
  },
  "05-2033": {
    max_performance: 4.273819871208935,
    expected_performance: 1.8492,
    min_performance: 0.5989224647622442,
    real: null,
  },
  "06-2033": {
    max_performance: 4.247828677813398,
    expected_performance: 1.8492,
    min_performance: 0.6129019925632633,
    real: null,
  },
  "07-2033": {
    max_performance: 4.221995549604152,
    expected_performance: 1.8492,
    min_performance: 0.6267843063258656,
    real: null,
  },
  "08-2033": {
    max_performance: 4.1963195253093275,
    expected_performance: 1.8492,
    min_performance: 0.6405700820792697,
    real: null,
  },
  "09-2033": {
    max_performance: 4.17079964950302,
    expected_performance: 1.8492,
    min_performance: 0.6542599911515683,
    real: null,
  },
  "10-2033": {
    max_performance: 4.145434972569733,
    expected_performance: 1.8492,
    min_performance: 0.66785470020242,
    real: null,
  },
  "11-2033": {
    max_performance: 4.120224550669053,
    expected_performance: 1.8492,
    min_performance: 0.681354871255512,
    real: null,
  },
  "12-2033": {
    max_performance: 4.095167445700521,
    expected_performance: 1.8492,
    min_performance: 0.6947611617307998,
    real: null,
  },
  "01-2034": {
    max_performance: 4.070262725268725,
    expected_performance: 1.8492,
    min_performance: 0.7080742244765212,
    real: null,
  },
  "02-2034": {
    max_performance: 4.0455094626486074,
    expected_performance: 1.8492,
    min_performance: 0.7212947078009897,
    real: null,
  },
  "03-2034": {
    max_performance: 4.020906736750981,
    expected_performance: 1.8492,
    min_performance: 0.7344232555041632,
    real: null,
  },
  "04-2034": {
    max_performance: 3.996453632088254,
    expected_performance: 1.8492,
    min_performance: 0.7474605069089966,
    real: null,
  },
  "05-2034": {
    max_performance: 3.9721492387403616,
    expected_performance: 1.8492,
    min_performance: 0.7604070968925738,
    real: null,
  },
  "06-2034": {
    max_performance: 3.9479926523209072,
    expected_performance: 1.8492,
    min_performance: 0.7732636559170272,
    real: null,
  },
  "07-2034": {
    max_performance: 3.923982973943515,
    expected_performance: 1.8492,
    min_performance: 0.7860308100602356,
    real: null,
  },
  "08-2034": {
    max_performance: 3.9001193101883747,
    expected_performance: 1.8492,
    min_performance: 0.7987091810463143,
    real: null,
  },
  "09-2034": {
    max_performance: 3.876400773069003,
    expected_performance: 1.8492,
    min_performance: 0.8112993862758917,
    real: null,
  },
  "10-2034": {
    max_performance: 3.852826479999194,
    expected_performance: 1.8492,
    min_performance: 0.8238020388561749,
    real: null,
  },
  "11-2034": {
    max_performance: 3.829395553760185,
    expected_performance: 1.8492,
    min_performance: 0.8362177476308048,
    real: null,
  },
  "12-2034": {
    max_performance: 3.8061071224680068,
    expected_performance: 1.8492,
    min_performance: 0.8485471172095069,
    real: null,
  },
  "01-2035": {
    max_performance: 3.782960319541046,
    expected_performance: 1.8492,
    min_performance: 0.8607907479975327,
    real: null,
  },
  "02-2035": {
    max_performance: 3.759954283667798,
    expected_performance: 1.8492,
    min_performance: 0.8729492362248985,
    real: null,
  },
  "03-2035": {
    max_performance: 3.7370881587748115,
    expected_performance: 1.8492,
    min_performance: 0.8850231739754211,
    real: null,
  },
  "04-2035": {
    max_performance: 3.714361093994841,
    expected_performance: 1.8492,
    min_performance: 0.8970131492155475,
    real: null,
  },
  "05-2035": {
    max_performance: 3.6917722436351808,
    expected_performance: 1.8492,
    min_performance: 0.9089197458229905,
    real: null,
  },
  "06-2035": {
    max_performance: 3.669320767146196,
    expected_performance: 1.8492,
    min_performance: 0.9207435436151594,
    real: null,
  },
  "07-2035": {
    max_performance: 3.647005829090048,
    expected_performance: 1.8492,
    min_performance: 0.9324851183773977,
    real: null,
  },
  "08-2035": {
    max_performance: 3.6248265991096043,
    expected_performance: 1.8492,
    min_performance: 0.94414504189102,
    real: null,
  },
  "09-2035": {
    max_performance: 3.60278225189754,
    expected_performance: 1.8492,
    min_performance: 0.955723881961158,
    real: null,
  },
  "10-2035": {
    max_performance: 3.5808719671656304,
    expected_performance: 1.8492,
    min_performance: 0.9672222024444097,
    real: null,
  },
  "11-2035": {
    max_performance: 3.5590949296142256,
    expected_performance: 1.8492,
    min_performance: 0.9786405632762976,
    real: null,
  },
  "12-2035": {
    max_performance: 3.5374503289019086,
    expected_performance: 1.8492,
    min_performance: 0.9899795204985387,
    real: null,
  },
  "01-2036": {
    max_performance: 3.515937359615351,
    expected_performance: 1.8492,
    min_performance: 1.0012396262861172,
    real: null,
  },
  "02-2036": {
    max_performance: 3.4945552212393336,
    expected_performance: 1.8492,
    min_performance: 1.012421428974178,
    real: null,
  },
  "03-2036": {
    max_performance: 3.473303118126966,
    expected_performance: 1.8492,
    min_performance: 1.0235254730847276,
    real: null,
  },
  "04-2036": {
    max_performance: 3.4521802594700737,
    expected_performance: 1.8492,
    min_performance: 1.0345522993531504,
    real: null,
  },
  "05-2036": {
    max_performance: 3.4311858592697755,
    expected_performance: 1.8492,
    min_performance: 1.0455024447545413,
    real: null,
  },
  "06-2036": {
    max_performance: 3.4103191363072356,
    expected_performance: 1.8492,
    min_performance: 1.0563764425298556,
    real: null,
  },
  "07-2036": {
    max_performance: 3.3895793141145916,
    expected_performance: 1.8492,
    min_performance: 1.0671748222118742,
    real: null,
  },
  "08-2036": {
    max_performance: 3.3689656209460614,
    expected_performance: 1.8492,
    min_performance: 1.0778981096509945,
    real: null,
  },
  "09-2036": {
    max_performance: 3.3484772897492303,
    expected_performance: 1.8492,
    min_performance: 1.0885468270408323,
    real: null,
  },
  "10-2036": {
    max_performance: 3.328113558136503,
    expected_performance: 1.8492,
    min_performance: 1.0991214929436557,
    real: null,
  },
  "11-2036": {
    max_performance: 3.307873668356738,
    expected_performance: 1.8492,
    min_performance: 1.1096226223156351,
    real: null,
  },
  "12-2036": {
    max_performance: 3.287756867267049,
    expected_performance: 1.8492,
    min_performance: 1.120050726531921,
    real: null,
  },
  "01-2037": {
    max_performance: 3.2677624063047825,
    expected_performance: 1.8492,
    min_performance: 1.1304063134115467,
    real: null,
  },
  "02-2037": {
    max_performance: 3.247889541459659,
    expected_performance: 1.8492,
    min_performance: 1.140689887242157,
    real: null,
  },
  "03-2037": {
    max_performance: 3.228137533246093,
    expected_performance: 1.8492,
    min_performance: 1.150901948804566,
    real: null,
  },
  "04-2037": {
    max_performance: 3.2085056466756705,
    expected_performance: 1.8492,
    min_performance: 1.161042995397143,
    real: null,
  },
  "05-2037": {
    max_performance: 3.1889931512298024,
    expected_performance: 1.8492,
    min_performance: 1.1711135208600323,
    real: null,
  },
  "06-2037": {
    max_performance: 3.169599320832544,
    expected_performance: 1.8492,
    min_performance: 1.1811140155991973,
    real: null,
  },
  "07-2037": {
    max_performance: 3.1503234338235715,
    expected_performance: 1.8492,
    min_performance: 1.1910449666103051,
    real: null,
  },
  "08-2037": {
    max_performance: 3.1311647729313328,
    expected_performance: 1.8492,
    min_performance: 1.2009068575024409,
    real: null,
  },
  "09-2037": {
    max_performance: 3.1121226252463545,
    expected_performance: 1.8492,
    min_performance: 1.2107001685216583,
    real: null,
  },
  "10-2037": {
    max_performance: 3.0931962821947163,
    expected_performance: 1.8492,
    min_performance: 1.220425376574366,
    real: null,
  },
  "11-2037": {
    max_performance: 3.074385039511683,
    expected_performance: 1.8492,
    min_performance: 1.2300829552505523,
    real: null,
  },
  "12-2037": {
    max_performance: 3.0556881972154977,
    expected_performance: 1.8492,
    min_performance: 1.2396733748468465,
    real: null,
  },
  "01-2038": {
    max_performance: 3.0371050595813367,
    expected_performance: 1.8492,
    min_performance: 1.2491971023894213,
    real: null,
  },
  "02-2038": {
    max_performance: 3.018634935115418,
    expected_performance: 1.8492,
    min_performance: 1.2586546016567375,
    real: null,
  },
  "03-2038": {
    max_performance: 3.000277136529274,
    expected_performance: 1.8492,
    min_performance: 1.268046333202126,
    real: null,
  },
  "04-2038": {
    max_performance: 2.982030980714175,
    expected_performance: 1.8492,
    min_performance: 1.2773727543762163,
    real: null,
  },
  "05-2038": {
    max_performance: 2.9638957887157105,
    expected_performance: 1.8492,
    min_performance: 1.286634319349209,
    real: null,
  },
  "06-2038": {
    max_performance: 2.945870885708523,
    expected_performance: 1.8492,
    min_performance: 1.2958314791329921,
    real: null,
  },
  "07-2038": {
    max_performance: 2.9279556009712002,
    expected_performance: 1.8492,
    min_performance: 1.3049646816031037,
    real: null,
  },
  "08-2038": {
    max_performance: 2.910149267861316,
    expected_performance: 1.8492,
    min_performance: 1.3140343715205431,
    real: null,
  },
  "09-2038": {
    max_performance: 2.892451223790621,
    expected_performance: 1.8492,
    min_performance: 1.3230409905534277,
    real: null,
  },
  "10-2038": {
    max_performance: 2.874860810200393,
    expected_performance: 1.8492,
    min_performance: 1.3319849772985026,
    real: null,
  },
  "11-2038": {
    max_performance: 2.857377372536925,
    expected_performance: 1.8492,
    min_performance: 1.3408667673024999,
    real: null,
  },
  "12-2038": {
    max_performance: 2.8400002602271748,
    expected_performance: 1.8492,
    min_performance: 1.3496867930833452,
    real: null,
  },
  "01-2039": {
    max_performance: 2.8227288266545507,
    expected_performance: 1.8492,
    min_performance: 1.3584454841512228,
    real: null,
  },
  "02-2039": {
    max_performance: 2.8055624291348575,
    expected_performance: 1.8492,
    min_performance: 1.3671432670294907,
    real: null,
  },
  "03-2039": {
    max_performance: 2.7885004288923736,
    expected_performance: 1.8492,
    min_performance: 1.375780565275452,
    real: null,
  },
  "04-2039": {
    max_performance: 2.771542191036087,
    expected_performance: 1.8492,
    min_performance: 1.3843577995009788,
    real: null,
  },
  "05-2039": {
    max_performance: 2.754687084536071,
    expected_performance: 1.8492,
    min_performance: 1.392875387392997,
    real: null,
  },
  "06-2039": {
    max_performance: 2.7379344821999996,
    expected_performance: 1.8492,
    min_performance: 1.401333743733825,
    real: null,
  },
  "07-2039": {
    max_performance: 2.721283760649808,
    expected_performance: 1.8492,
    min_performance: 1.4097332804213742,
    real: null,
  },
  "08-2039": {
    max_performance: 2.7047343002985045,
    expected_performance: 1.8492,
    min_performance: 1.4180744064892052,
    real: null,
  },
  "09-2039": {
    max_performance: 2.688285485327106,
    expected_performance: 1.8492,
    min_performance: 1.4263575281264473,
    real: null,
  },
  "10-2039": {
    max_performance: 2.6719367036617263,
    expected_performance: 1.8492,
    min_performance: 1.434583048697579,
    real: null,
  },
  "11-2039": {
    max_performance: 2.6556873469508027,
    expected_performance: 1.8492,
    min_performance: 1.4427513687620712,
    real: null,
  },
  "12-2039": {
    max_performance: 2.639536810542454,
    expected_performance: 1.8492,
    min_performance: 1.4508628860938921,
    real: null,
  },
  "01-2040": {
    max_performance: 2.6234844934619863,
    expected_performance: 1.8492,
    min_performance: 1.4589179957008784,
    real: null,
  },
  "02-2040": {
    max_performance: 2.6075297983895247,
    expected_performance: 1.8492,
    min_performance: 1.4669170898439714,
    real: null,
  },
  "03-2040": {
    max_performance: 2.5916721316377913,
    expected_performance: 1.8492,
    min_performance: 1.4748605580563174,
    real: null,
  },
  "04-2040": {
    max_performance: 2.575910903130009,
    expected_performance: 1.8492,
    min_performance: 1.4827487871622396,
    real: null,
  },
  "05-2040": {
    max_performance: 2.5602455263779493,
    expected_performance: 1.8492,
    min_performance: 1.4905821612960726,
    real: null,
  },
  "06-2040": {
    max_performance: 2.544675418460105,
    expected_performance: 1.8492,
    min_performance: 1.49836106192087,
    real: null,
  },
  "11-2001": {
    real: 10.210000000000008,
  },
};
