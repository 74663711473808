import React from "react";

interface IWCIcons {
  name: string;
  width?: string;
  height?: string;
  viewBox?: string;
  color?: string;
}

function WCIcons(props: IWCIcons) {
  const { name, width, height, viewBox, color } = props;
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/icon/index.js"), []);
  return (
    <inv-icon
      color={color}
      name={name}
      width={width}
      height={height}
      viewBox={viewBox}
    />
  );
}

WCIcons.defaultProps = {
  width: undefined,
  height: undefined,
  viewBox: undefined,
  color: undefined,
};

export default WCIcons;
