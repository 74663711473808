import { fetchApi } from "../../utils/functions";
import { ComplementSimulationRequiredData, Objective } from "./goal.type";
import { pensionApiResponse } from "./goal.data";

export const retirementSimulation = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  complementSimulationRequiredData: ComplementSimulationRequiredData
) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    return pensionApiResponse.meta.simulation.map((res) => ({
      retirement_age: res.year,
      // @ts-ignore
      retirement_income: Math.round(res.pension),
      retirement_income_inflated: Math.round(res.pension_with_inflation),
    }));
  } catch (e) {
    return [];
  }
};

export const addObjectiveApi = async (objective: Objective) => {
  try {
    const addFirstObjectiveRes = await fetchApi(
      "objectives",
      "post",
      objective
    );
    return addFirstObjectiveRes;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const modifyObjectiveApi = async (objective: Objective, id: number) => {
  try {
    const modifyObjectiveRes = await fetchApi(
      `objectives/${id}`,
      "put",
      objective
    );
    return modifyObjectiveRes;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const deleteObjectiveApi = async (id: number) => {
  try {
    const deleteObjectiveRes = await fetchApi(
      `objectives/${id}`,
      "delete",
      undefined
    );
    return deleteObjectiveRes;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getObjectives = () => {
  const res = fetchApi("objectives", "get", {});
  return res;
};
