import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useMediaQuery } from "@mui/material";
import { color } from "../../styles/color";
import Text from "../../styles/textStyles";
import theme from "../../styles/theme";

interface ISelector {
  icon?: JSX.Element;
  iconColor?: string;
  title: string;
  subtitle?: string | JSX.Element;
  size?: "big" | "medium" | "small";
  selected: boolean;
  onClick?: () => void;
  height?: number;
  width?: number;
  disabled?: boolean;
  smallPadding?: boolean;
  alignTitle?: "center";
  className?: string;
  id?: string;
}

const StyledSelectorContainerGrid = styled((props: any) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Grid container alignItems="center" justifyContent="center" {...props} />
  // eslint-disable-next-line @typescript-eslint/no-shadow
))(({ theme }) => ({
  background: color.greyscale.white[100],
  border: `2px solid ${color.greyscale.black[30]}`,
  borderRadius: 24,
  [theme.breakpoints.up("xs")]: {
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
  },
  [theme.breakpoints.up("xl")]: {
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
  },
  "&:hover": {
    border: `2px solid ${color.blue[100]}`,
  },
  cursor: "pointer",
}));

function Selector(props: ISelector) {
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const downXl = useMediaQuery(theme.breakpoints.down("xl"));
  const {
    id,
    className,
    icon,
    iconColor,
    title,
    subtitle,
    size,
    selected,
    onClick,
    height,
    width,
    disabled,
    smallPadding,
    alignTitle,
  } = props;
  const borderRadius = size === "medium" || size === "small" ? 112 : 24;
  let titleSize;
  if (downXl && size === "small") {
    titleSize = "s";
  } else if (downXl && size === "medium") {
    titleSize = "m";
  } else if (downXl) {
    titleSize = "m";
  } else if (!downXl && size === "small") {
    titleSize = "s";
  } else if (!downXl && size === "medium") {
    titleSize = "m";
  } else {
    titleSize = "l";
  }
  let subtitleSize;
  if (downXl && size === "small") {
    subtitleSize = "xs";
  } else if (downXl) {
    subtitleSize = "xs";
  } else if (size === "small") {
    subtitleSize = "xs";
  } else {
    subtitleSize = "s";
  }
  let spacing;
  if (size === "small") {
    spacing = 2;
  } else if (size === "medium") {
    spacing = 2;
  } else {
    spacing = 2;
  }
  let sx;
  if (selected === true) {
    sx = {
      [theme.breakpoints.up("xs")]: {
        paddingRight:
          smallPadding === true ? theme.spacing(4) : theme.spacing(8),
        paddingLeft:
          smallPadding === true ? theme.spacing(4) : theme.spacing(8),
      },
      [theme.breakpoints.up("xl")]: {
        paddingRight:
          smallPadding === true ? theme.spacing(4) : theme.spacing(8),
        paddingLeft:
          smallPadding === true ? theme.spacing(4) : theme.spacing(8),
      },
      border: `4px solid ${color.blue[100]}`,
      borderRadius: `${borderRadius}px`,
      height,
      width,
    };
  } else if (disabled === true) {
    sx = {
      cursor: "default",
      background: color.greyscale.black[5],
      borderRadius: `${borderRadius}px`,
      height,
      width,
      "&:hover": {
        border: `2px solid ${color.greyscale.black[30]}`,
      },
      [theme.breakpoints.up("xs")]: {
        paddingRight:
          smallPadding === true ? theme.spacing(4) : theme.spacing(8),
        paddingLeft:
          smallPadding === true ? theme.spacing(4) : theme.spacing(8),
      },
      [theme.breakpoints.up("xl")]: {
        paddingRight:
          smallPadding === true ? theme.spacing(4) : theme.spacing(8),
        paddingLeft:
          smallPadding === true ? theme.spacing(4) : theme.spacing(8),
      },
    };
  } else {
    sx = {
      borderRadius: `${borderRadius}px`,
      height,
      width,
      [theme.breakpoints.up("xs")]: {
        paddingRight:
          smallPadding === true ? theme.spacing(4) : theme.spacing(8),
        paddingLeft:
          smallPadding === true ? theme.spacing(4) : theme.spacing(8),
      },
      [theme.breakpoints.up("xl")]: {
        paddingRight:
          smallPadding === true ? theme.spacing(4) : theme.spacing(8),
        paddingLeft:
          smallPadding === true ? theme.spacing(4) : theme.spacing(8),
      },
    };
  }
  return (
    <Box sx={{ width: "100%" }} onClick={onClick} className={className} id={id}>
      <StyledSelectorContainerGrid
        // @ts-ignore
        sx={sx}
        // @ts-ignore
        direction={downMobile && !subtitle ? "row" : "column"}
      >
        {icon && (
          <Grid
            item
            sx={{
              height: downMobile ? 48 : "initial",
              color: iconColor,
              opacity: disabled === true ? 0.4 : 1,
              [theme.breakpoints.up("xs")]: {
                paddingBottom: downMobile
                  ? theme.spacing(0)
                  : theme.spacing(spacing),
              },
              [theme.breakpoints.up("xl")]: {
                paddingBottom: downMobile
                  ? theme.spacing(0)
                  : theme.spacing(spacing * 2),
              },
            }}
          >
            {icon}
          </Grid>
        )}
        <Grid
          item
          sx={{
            opacity: disabled === true ? 0.4 : 1,
            maxWidth: downMobile ? 149 : "inherit",
            paddingLeft:
              icon && downMobile ? theme.spacing(6) : theme.spacing(0),
          }}
        >
          <Text
            size={titleSize}
            weight="semibold"
            align={alignTitle ?? (downMobile ? undefined : "center")}
          >
            {title}
          </Text>
        </Grid>
        {subtitle && (
          <Grid
            item
            sx={{
              [theme.breakpoints.up("xs")]: {
                paddingTop: theme.spacing(spacing),
              },
              [theme.breakpoints.up("xl")]: {
                paddingTop: theme.spacing(spacing * 2),
              },
            }}
          >
            {/* FIGMA COLOR DONT EXISTS IN THE COLORS GIVEN BY DESIGNER SO I GIVE ANOTHER THAT EXISTS */}
            <Text
              size={subtitleSize}
              weight="medium"
              fontColor={color.greyscale.black[50]}
              align="center"
            >
              {subtitle}
            </Text>
          </Grid>
        )}
      </StyledSelectorContainerGrid>
    </Box>
  );
}

Selector.defaultProps = {
  icon: undefined,
  iconColor: undefined,
  subtitle: undefined,
  size: undefined,
  onClick: undefined,
  height: undefined,
  width: undefined,
  disabled: undefined,
  smallPadding: undefined,
  alignTitle: undefined,
  className: undefined,
  id: undefined,
};

export default Selector;
