export type PlanTabName = "plan" | "goals" | "benefits" | "help" | "details";

export interface Plan {
  tabName: PlanTabName;
  apportationDialogOpen: boolean;
  newPlanDialogOpen: boolean;
  resultNewPlanDialogOpen: boolean;
  confirmationNewPlanDialogOpen: boolean;
}

export enum PropuestaStatus {
  COMPLETED = "Completed",
  NOT_INITIATED = "Not initiated",
  IN_PROGRESS = "In progress",
  IN_SIMULATION = "In simulation",
  ACTIVE = "Active",
  CANCELLED = "Cancelled",
}
