import axios from "axios";
import { Objective, Objectives } from "../modules/goal/goal.type";

export function setIsAuthDialogOpenInStorage(
  isAuthDialogOpen: "true" | "false"
) {
  const storage = globalThis?.sessionStorage;
  if (!storage) return;
  storage.setItem("isAuthDialogOpen", isAuthDialogOpen);
}

export function getTokenInStorage() {
  const storage = globalThis?.sessionStorage;
  if (!storage) return;
  // eslint-disable-next-line consistent-return
  return storage.getItem("token");
}

export function setTokenInStorage(token: "string") {
  const storage = globalThis?.sessionStorage;
  if (!storage) return;
  storage.setItem("token", token);
}

function getIsAuthDialogOpenInStorage() {
  const storage = globalThis?.sessionStorage;
  if (!storage) return;
  // eslint-disable-next-line consistent-return
  return storage.getItem("isAuthDialogOpen");
}

export const fetchApi = async (
  url: string,
  method: "post" | "get" | "put" | "delete",
  payload: any | undefined
) => {
  let res;
  try {
    if (method === "post") {
      res = axios.post(url, payload);
    } else if (method === "get") {
      res = axios.get(url);
    } else if (method === "put") {
      res = axios.put(url, payload);
    } else if (method === "delete") {
      res = axios.delete(url);
    }
    return (await res)?.data;
  } catch (e) {
    return url.includes("auth") ? Promise.reject(e) : Promise.reject(e);
  }
};

export const refreshAuthLogic = (failedRequest: any) =>
  fetchApi("auth/login/refresh", "post", {})
    .then((tokenRefreshResponse: any) => {
      const theFailedRequest = failedRequest;
      theFailedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.access_token}`;
      return Promise.resolve();
    })
    .catch(() => {
      if (getIsAuthDialogOpenInStorage() === "false") {
        window.location.reload();
      }
    });

export const refreshAuthLogicOnServer = (failedRequest: any) => {
  const uninterceptedAxiosInstance = axios.create();
  uninterceptedAxiosInstance.defaults.baseURL = `${process.env.REACT_APP_SERVER_BASE_URL}/api/api/`;
  uninterceptedAxiosInstance.defaults.withCredentials = true;
  uninterceptedAxiosInstance.defaults.headers = {
    // @ts-ignore
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  return uninterceptedAxiosInstance
    .post("auth/login/refresh")
    .then((tokenRefreshResponse: any) => {
      const theFailedRequest = failedRequest;
      theFailedRequest.response.config.headersAuthorization = `Bearer ${tokenRefreshResponse.access_token}`;
      return Promise.resolve();
    });
};

export const getMoneyInFutureWithInflation = (
  quantity: number,
  years: number,
  inflation: number
) => {
  const inflationRate = 1 + inflation;
  let inflatedMoney = quantity;
  let i;
  for (i = 0; i < years; i += 1) {
    inflatedMoney *= inflationRate;
  }
  return inflatedMoney;
};

export function getMoneyInPresentWithoutInflation(
  quantity: number,
  yearsFromPresentToFuture: number,
  inflation: number
) {
  const inflationRate = 1 + inflation;
  return inflationRate ** -yearsFromPresentToFuture * quantity;
}

export const Capitalize = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const validateDniNifNie = (value: string | undefined | null) => {
  if (value) {
    const validChars = "TRWAGMYFPDXBNJZSQVHLCKET";
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const str = value.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) return false;

    const nie = str
      .replace(/^[X]/, "0")
      .replace(/^[Y]/, "1")
      .replace(/^[Z]/, "2");

    const letter = str.substr(-1);
    const charIndex = parseInt(nie.substr(0, 8), 10) % 23;

    if (validChars.charAt(charIndex) === letter) return true;

    return false;
  }
  return false;
};

export const parseBarChartDataWhenMobile = (data: Array<any>) => {
  // this is the rate at wich we will be getting the data of the array
  // example if dataRate is 3 we will get 1 item of data and dismiss 3
  const dataRate = Math.trunc((data.length - 2) / 8);
  let parsedData;
  if (data.length > 10) {
    parsedData = data.filter((item, index) => {
      let returnedItem;
      if (index % dataRate === 0) {
        returnedItem = item;
      } else if (index === 0) {
        returnedItem = item;
      } else if (index === data.length - 1) {
        returnedItem = item;
      }
      return returnedItem;
    });
  } else {
    parsedData = data;
  }
  return parsedData;
};

export const checkIsRetirementDisabled = (
  objectives: Objective[],
  objectivesData: {
    lastObjectiveId: number | null;
    lastObjectiveType: string | null;
    objectives: Objectives[];
  },
  isMobile?: boolean
) =>
  objectives.reduce((isDisabled: boolean, objective: Objective) => {
    let thisIsDisabled = isDisabled;
    if (
      objective.type === "RETIREMENT" &&
      (objectivesData.lastObjectiveType !== "RETIREMENT" || isMobile === true)
    ) {
      thisIsDisabled = true;
    }
    return thisIsDisabled;
  }, false);

export const checkIsSafetyNetDisabled = (
  objectives: Objective[],
  objectivesData: {
    lastObjectiveId: number | null;
    lastObjectiveType: string | null;
    objectives: Objectives[];
  },
  isMobile?: boolean
) =>
  objectives.reduce((isDisabled: boolean, objective: Objective) => {
    if (
      objective.type === "SAFETY_NET" &&
      (objectivesData.lastObjectiveType !== "SAFETY_NET" || isMobile === true)
    ) {
      // eslint-disable-next-line no-param-reassign
      isDisabled = true;
    }
    return isDisabled;
  }, false);

function mod97(string: string) {
  let checksum = string.slice(0, 2);
  let fragment;
  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    // @ts-ignore
    checksum = parseInt(fragment, 10) % 97;
  }
  // @ts-ignore
  return checksum === 1;
}

export function ibanValidator(value: string | null | undefined) {
  let result = false;
  if (value) {
    const input = value ? value.replace(" ", "") : "";
    const CODE_LENGTHS = {
      AD: 24,
      AE: 23,
      AT: 20,
      AZ: 28,
      BA: 20,
      BE: 16,
      BG: 22,
      BH: 22,
      BR: 29,
      CH: 21,
      CR: 21,
      CY: 28,
      CZ: 24,
      DE: 22,
      DK: 18,
      DO: 28,
      EE: 20,
      ES: 24,
      FI: 18,
      FO: 18,
      FR: 27,
      GB: 22,
      GI: 23,
      GL: 18,
      GR: 27,
      GT: 28,
      HR: 21,
      HU: 28,
      IE: 22,
      IL: 23,
      IS: 26,
      IT: 27,
      JO: 30,
      KW: 30,
      KZ: 20,
      LB: 28,
      LI: 21,
      LT: 20,
      LU: 20,
      LV: 21,
      MC: 27,
      MD: 24,
      ME: 22,
      MK: 19,
      MR: 27,
      MT: 31,
      MU: 30,
      NL: 18,
      NO: 15,
      PK: 24,
      PL: 28,
      PS: 29,
      PT: 25,
      QA: 29,
      RO: 24,
      RS: 22,
      SA: 24,
      SE: 24,
      SI: 19,
      SK: 24,
      SM: 27,
      TN: 24,
      TR: 26,
      AL: 28,
      BY: 28,
      EG: 29,
      GE: 22,
      IQ: 23,
      LC: 32,
      SC: 31,
      ST: 25,
      SV: 28,
      TL: 23,
      UA: 29,
      VA: 22,
      VG: 24,
      XK: 20,
    };
    const iban = String(input)
      .toUpperCase()
      .replace(/[^A-Z0-9]/g, ""); // keep only alphanumeric characters
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest
    let digits;
    // check syntax and length
    // @ts-ignore
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
      return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    // @ts-ignore
    // eslint-disable-next-line prefer-const
    digits = (code[3] + code[1] + code[2]).replace(
      /[A-Z]/g,
      // @ts-ignore
      (letter) => letter.charCodeAt(0) - 55
    );
    // final check
    result = mod97(digits);
  } else {
    result = false;
  }
  return result;
}
