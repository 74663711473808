import { useMediaQuery, Link } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import * as React from "react";
// @ts-ignore
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import theme from "../../styles/theme";
import Text from "../../styles/textStyles";
import { color } from "../../styles/color";
import Buttons from "../../elements/buttons/Buttons";
import {
  LoginFormik,
  ResendVerificationFormik,
  SendChangePassFormik,
} from "./auth.script";
import PageLoading from "../../elements/loading/PageLoading";
import { AuthLoginFormComponent } from "./auth-login-form.component";
import { addAlert, selectApp } from "../app/app.slice";
import { authResendVerificationUser } from "./auth.service";
import WCIcons from "../../elements/icons/WCIcons";

const AuthResendVerificationFormComponent = React.lazy(
  () => import("./auth-resend-verification-form.component")
);
const AuthSendChangePassFormComponent = React.lazy(
  () => import("./auth-send-change-pass-form.component")
);

interface IAuthDialogComponent {
  activeForm?: "register" | "login" | "verification";
}

export function AuthDialogComponent(props: IAuthDialogComponent) {
  const dispatch = useDispatch();
  const { alert, alertFlag } = useSelector(selectApp);
  const router = useNavigate();
  const { activeForm } = props;
  const downXl = useMediaQuery(theme.breakpoints.down("xl"));
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const [alertObject, setAlertObject] = React.useState(alert);
  const [formName, setFormName] = React.useState<
    "login" | "verification" | "register" | "forgot"
  >("login");
  const formik = LoginFormik({
    dispatch,
  });
  const resendFormik = ResendVerificationFormik();
  const sendEmailChangePassFormik = SendChangePassFormik();
  React.useEffect(() => {
    setFormName(activeForm ?? "login");
  }, [activeForm]);

  React.useEffect(() => {
    const listener = (event: { code: string; preventDefault: () => void }) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        if (formName === "login") {
          formik.handleSubmit();
        } else if (formName === "verification") {
          resendFormik.handleSubmit();
        } else {
          sendEmailChangePassFormik.handleSubmit();
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);
  React.useEffect(() => {
    setAlertObject(alert);
  }, [alertFlag]);
  return (
    <Box
      sx={{
        width: "100%",
        // adding padding is a hotfix because of grid inside dialog mess with vertical position and adds a unwanted scrollbar
        [theme.breakpoints.up("xs")]: {
          padding: theme.spacing(12),
          width: 400,
        },
        [theme.breakpoints.up("xl")]: {
          padding: theme.spacing(14),
        },
        [theme.breakpoints.down("mobile")]: {
          padding: 5,
          width: "100%",
        },
      }}
    >
      <PageLoading
        open={
          formik.isSubmitting ||
          resendFormik.isSubmitting ||
          sendEmailChangePassFormik.isSubmitting
        }
      />
      {/* eslint-disable-next-line no-nested-ternary */}
      {formName === "login" &&
      alertObject?.message ===
        "El correo electrónico introducido no ha sido validado" ? (
        <Grid container direction="column">
          <Grid
            item
            sx={{
              width: "100%",
            }}
          >
            <Grid
              container
              direction="column"
              alignItems="center"
              rowSpacing={4}
            >
              <Grid item>
                <WCIcons
                  name="message-failed"
                  viewBox="0 0 25 24"
                  height="50"
                  width="50"
                />
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  rowSpacing={2}
                >
                  <Grid item>
                    <Text size="xl" weight="semibold" align="center">
                      Verifica tu cuenta
                    </Text>
                  </Grid>
                  <Grid item>
                    <Text
                      size="xs"
                      weight="medium"
                      fontColor={color.greyscale.black[30]}
                      align="center"
                    >
                      Para poder acceder necesitas verificar tu correo, no te
                      olvides de revisar tu bandeja de spam por si el correo de
                      verificación estuviese allí.
                    </Text>
                  </Grid>
                  <Grid item>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                      sx={{ cursor: "pointer", fontSize: 12 }}
                      onClick={async () => {
                        authResendVerificationUser(formik.values.email ?? "")
                          .then(() => {
                            dispatch(
                              addAlert({
                                message: "Verificacion reenviada",
                                isError: false,
                                isOpen: true,
                              })
                            );
                          })
                          .catch((e) => {
                            if (e?.response?.data?.message) {
                              dispatch(
                                addAlert({
                                  message: e.response.data.message,
                                  isError: true,
                                  isOpen: true,
                                })
                              );
                            }
                          });
                      }}
                    >
                      ¿No te ha llegado? Reenvia el correo de verificación
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          rowSpacing={{ xs: theme.spacing(7), xl: theme.spacing(14) }}
        >
          {/* Need to wrap containers on item to apply maxwidth */}
          <Grid
            item
            sx={{
              width: "100%",
            }}
          >
            <Grid
              direction="column"
              alignItems="center"
              container
              justifyContent="center"
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                [theme.breakpoints.up("xs")]: {
                  maxWidth: 490,
                  // Add padding to make dialog bigger
                  paddingLeft: theme.spacing(10),
                  paddingRight: theme.spacing(10),
                },
                [theme.breakpoints.up("xl")]: {
                  maxWidth: 680,
                  // Add padding to make dialog bigger
                  paddingLeft: theme.spacing(20),
                  paddingRight: theme.spacing(20),
                },
                [theme.breakpoints.down("mobile")]: {
                  padding: 0,
                },
              }}
              rowSpacing={{ xs: 2, xl: 4 }}
            >
              <Grid item>
                <Text
                  /* eslint-disable-next-line no-nested-ternary */
                  size={downMobile ? "l" : downXl ? "2xl" : "3xl"}
                  weight="semibold"
                  gradient={color.gradient.blue}
                  align="center"
                >
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {formName === "login"
                    ? "Login"
                    : /* eslint-disable-next-line no-nested-ternary */
                    formName === "register"
                    ? "Registro"
                    : formName === "verification"
                    ? "Verificación"
                    : "Cambiar contraseña"}
                </Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: "100%",
            }}
          >
            <Grid
              container
              item
              direction="column"
              justifyContent="center"
              rowSpacing={{ xs: theme.spacing(4), xl: theme.spacing(4) }}
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                [theme.breakpoints.up("xs")]: {
                  maxWidth: 328,
                },
                [theme.breakpoints.up("xl")]: {
                  maxWidth: 408,
                },
              }}
            >
              {/* eslint-disable-next-line no-nested-ternary */}
              {formName === "login" ? (
                <AuthLoginFormComponent formik={formik} />
              ) : formName === "verification" ? (
                <AuthResendVerificationFormComponent formik={resendFormik} />
              ) : (
                <AuthSendChangePassFormComponent
                  formik={sendEmailChangePassFormik}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              width: "inherit",
              [theme.breakpoints.up("xs")]: {
                paddingBottom: theme.spacing(1.75),
              },
              [theme.breakpoints.up("xl")]: {
                paddingBottom: theme.spacing(3.5),
              },
            }}
          >
            <Grid
              item
              container
              justifyContent="center"
              alignItems="center"
              rowSpacing={2}
            >
              <Grid
                item
                sx={{
                  maxWidth: 328,
                  width: "100%",
                }}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {formName === "login" ? (
                  <Buttons
                    size={downMobile ? "medium" : undefined}
                    variant="contained"
                    onClick={() => formik.handleSubmit()}
                  >
                    Iniciar sesión
                  </Buttons>
                ) : formName === "verification" ? (
                  <Buttons
                    size={downMobile ? "medium" : undefined}
                    variant="contained"
                    onClick={() => resendFormik.handleSubmit()}
                  >
                    Reenviar correo
                  </Buttons>
                ) : (
                  <Buttons
                    size={downMobile ? "medium" : undefined}
                    variant="contained"
                    onClick={() => sendEmailChangePassFormik.handleSubmit()}
                  >
                    Enviar correo
                  </Buttons>
                )}
              </Grid>
              <Grid
                item
                sx={{
                  marginTop: theme.spacing(5),
                }}
              >
                <Text size="xxs" weight="bold">
                  {formName === "register" || formName === "forgot" ? (
                    <Grid container direction="column" alignItems="center">
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <Link
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                          setFormName("login");
                          formik.resetForm();
                          resendFormik.resetForm();
                          sendEmailChangePassFormik.resetForm();
                        }}
                      >
                        ¿Ya estás registrado?
                      </Link>
                    </Grid>
                  ) : (
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        rowSpacing={1}
                      >
                        <Grid item>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <Link
                            sx={{ cursor: "pointer" }}
                            onClick={() => router("/registro")}
                          >
                            ¿Aun no estás registrado?
                          </Link>
                        </Grid>
                        <Grid item>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <Link
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              setFormName("forgot");
                              formik.resetForm();
                              resendFormik.resetForm();
                              sendEmailChangePassFormik.resetForm();
                            }}
                          >
                            ¿Has olvidado la contraseña?
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Text>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
AuthDialogComponent.defaultProps = {
  activeForm: undefined,
};

export default AuthDialogComponent;
