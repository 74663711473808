export const socialSignButtonsData: Array<any> = [
  {
    provider: "google",
    text: "Continúa con Google",
    href: `${process.env.REACT_APP_SERVER_BASE_URL}/api/auth/provider/google/request-token`,
  },
  {
    provider: "twitter",
    text: "Continúa con Twitter",
    href: `${process.env.REACT_APP_SERVER_BASE_URL}/api/auth/provider/twitter/request-token`,
  },
  {
    provider: "facebook",
    text: "Continúa con Facebook",
    href: `${process.env.REACT_APP_SERVER_BASE_URL}/api/auth/provider/facebook/request-token`,
  },
];

export const socialRegisterButtonsData: Array<any> = [
  {
    provider: "google",
    text: "Regístrate con Google",
    href: `${process.env.REACT_APP_SERVER_BASE_URL}/api/auth/provider/google/request-token`,
  },
  {
    provider: "twitter",
    text: "Regístrate con Twitter",
    href: `${process.env.REACT_APP_SERVER_BASE_URL}/api/auth/provider/twitter/request-token`,
  },
  {
    provider: "facebook",
    text: "Regístrate con Facebook",
    href: `${process.env.REACT_APP_SERVER_BASE_URL}/api/auth/provider/facebook/request-token`,
  },
];

export default socialSignButtonsData;
