import { Box, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import NumberFormat from "react-number-format";
import * as React from "react";
import Text from "../../styles/textStyles";
import theme from "../../styles/theme";

interface ITotalsTextStructures {
  items: Array<{
    title?: string;
    quantity?: number | string;
    helper?: string | JSX.Element;
    quantitySuffix?: string;
    quantityPrefix?: string;
    fontColor?: string;
    quantityFontColor?: string;
  }>;
  size?: "big" | "medium" | "small" | "superSmall" | "superBig";
  fontColor?: string;
  decimalScale?: number;
  noWrapTitle?: boolean;
  noWrapTitleWidth?: number;
}

export function TotalsTextStructures(props: ITotalsTextStructures) {
  const downXl = useMediaQuery(theme.breakpoints.down("xl"));
  const { items, size, decimalScale, noWrapTitle, noWrapTitleWidth } = props;
  // eslint-disable-next-line no-nested-ternary
  const titleSize = downXl
    ? // eslint-disable-next-line no-nested-ternary
      size === "medium"
      ? "2xl"
      : // eslint-disable-next-line no-nested-ternary
      size === "small"
      ? "l"
      : // eslint-disable-next-line no-nested-ternary
      size === "superSmall"
      ? "s"
      : size === "superBig"
      ? "4xl"
      : "2xl"
    : // eslint-disable-next-line no-nested-ternary
    size === "medium"
    ? "2xl"
    : // eslint-disable-next-line no-nested-ternary
    size === "small"
    ? "l"
    : // eslint-disable-next-line no-nested-ternary
    size === "superSmall"
    ? "s"
    : size === "superBig"
    ? "5xl"
    : "3xl";
  // eslint-disable-next-line no-nested-ternary
  const labelSize = downXl
    ? // eslint-disable-next-line no-nested-ternary
      size === "medium"
      ? "s"
      : // eslint-disable-next-line no-nested-ternary
      size === "small"
      ? "xxs"
      : size === "superSmall"
      ? "xxs"
      : "s"
    : // eslint-disable-next-line no-nested-ternary
    size === "medium"
    ? "s"
    : // eslint-disable-next-line no-nested-ternary
    size === "small"
    ? "xxs"
    : // eslint-disable-next-line no-nested-ternary
    size === "superSmall"
    ? "xxs"
    : size === "superBig"
    ? "s"
    : "m";
  // eslint-disable-next-line no-nested-ternary
  const helperSize = downXl
    ? // eslint-disable-next-line no-nested-ternary
      size === "medium"
      ? "xs"
      : // eslint-disable-next-line no-nested-ternary
      size === "small"
      ? "xxs"
      : size === "superSmall"
      ? "xxs"
      : "xs"
    : // eslint-disable-next-line no-nested-ternary
    size === "medium"
    ? "xs"
    : // eslint-disable-next-line no-nested-ternary
    size === "small"
    ? "xxs"
    : size === "superSmall"
    ? "xxs"
    : "s";
  // eslint-disable-next-line no-nested-ternary
  const quantitySize = downXl
    ? // eslint-disable-next-line no-nested-ternary
      size === "medium"
      ? "m"
      : // eslint-disable-next-line no-nested-ternary
      size === "small"
      ? "xs"
      : // eslint-disable-next-line no-nested-ternary
      size === "superSmall"
      ? "xs"
      : size === "superBig"
      ? "xl"
      : "m"
    : // eslint-disable-next-line no-nested-ternary
    size === "medium"
    ? "m"
    : // eslint-disable-next-line no-nested-ternary
    size === "small"
    ? "xs"
    : // eslint-disable-next-line no-nested-ternary
    size === "superSmall"
    ? "xs"
    : size === "superBig"
    ? "2xl"
    : "l";
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Grid container direction="column">
        <Grid
          item
          sx={{
            width: noWrapTitleWidth ?? "initial",
          }}
        >
          <Text
            noWrap={noWrapTitle ?? undefined}
            size={labelSize}
            weight="medium"
            fontColor={items[0].fontColor}
          >
            {items[0].title}
          </Text>
        </Grid>
        <Grid item>
          <Grid
            container
            columnSpacing={{ xs: 2, xl: 4 }}
            sx={{
              // width of material spacing causes errors on safari so i set it to auto
              [theme.breakpoints.up("xs")]: {
                width: "auto",
              },
            }}
          >
            {items.map((item) => (
              <Grid key={`${item.title}-${item.quantity}`} item>
                <Text
                  size={titleSize}
                  weight="semibold"
                  display="inline"
                  fontColor={
                    item.quantityFontColor
                      ? item.quantityFontColor
                      : item.fontColor
                  }
                >
                  {`${item?.quantityPrefix ?? ""}`}
                </Text>
                <Text
                  size={titleSize}
                  weight="semibold"
                  display="inline"
                  fontColor={
                    item.quantityFontColor
                      ? item.quantityFontColor
                      : item.fontColor
                  }
                >
                  {typeof item.quantity === "number" ? (
                    <NumberFormat
                      value={item.quantity}
                      thousandSeparator={
                        process.env.REACT_APP_THOUSAND_SEPARATOR
                      }
                      decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                      decimalScale={decimalScale || 0}
                      displayType="text"
                    />
                  ) : (
                    item.quantity
                  )}
                </Text>
                <Text
                  size={quantitySize}
                  weight="bold"
                  display="inline"
                  fontColor={
                    item.quantityFontColor
                      ? item.quantityFontColor
                      : item.fontColor
                  }
                >
                  {`\xa0${item?.quantitySuffix ?? ""}`}
                </Text>
                <Text
                  size={helperSize}
                  weight="medium"
                  fontColor={
                    item.quantityFontColor
                      ? item.quantityFontColor
                      : item.fontColor
                  }
                >
                  {item.helper}
                </Text>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

TotalsTextStructures.defaultProps = {
  size: undefined,
  fontColor: undefined,
  decimalScale: undefined,
  noWrapTitle: undefined,
  noWrapTitleWidth: undefined,
};
export default TotalsTextStructures;
