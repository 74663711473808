import Box from "@mui/material/Box";
import * as React from "react";
import { Grid, Link } from "@mui/material";
import Text from "../../styles/textStyles";
import { color } from "../../styles/color";
import WCIcons from "../icons/WCIcons";

interface ISocialSignButton {
  href?: string;
  text: string;
  provider: "twitter" | "google" | "facebook" | "email";
  onClick?: () => void;
}

const imageSocialProvider = {
  twitter: <WCIcons name="social-twitter-color" height="17" width="17" />,
  google: <WCIcons name="social-google-color" height="17" width="17" />,
  facebook: <WCIcons name="social-facebook-color" height="17" width="17" />,
  email: <WCIcons name="mail" height="17" width="17" />,
};

export function SocialSignButton(props: ISocialSignButton) {
  const { href, text, provider, onClick } = props;
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Link
        onClick={onClick}
        rel="opener"
        underline="none"
        href={href}
        target="_blank"
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{
            width: "auto",
            cursor: "pointer",
            borderRadius: "8px",
            border: `2px solid ${color.greyscale.black[30]}`,
            height: "48px",
            padding: "14px 20px",
            "&:hover": {
              border: `2px solid ${color.blue[100]}`,
            },
          }}
        >
          <Grid
            item
            sx={{
              height: "17px",
              marginRight: "16px",
              position: "relative",
              right: 20,
              bottom: 1,
              marginLeft:
                provider === "facebook" || provider === "email"
                  ? "15px"
                  : undefined,
            }}
          >
            {imageSocialProvider[provider]}
          </Grid>
          <Grid item sx={{ position: "relative", right: 15, bottom: 1 }}>
            <Text weight="medium" size="s">
              {text}
            </Text>
          </Grid>
        </Grid>
      </Link>
    </Box>
  );
}

export default SocialSignButton;

SocialSignButton.defaultProps = {
  onClick: undefined,
  href: undefined,
};
