import Box from "@mui/material/Box";
import * as React from "react";
import {
  Slider,
  SliderMark,
  SliderMarkLabel,
  SliderThumb,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormikErrors } from "formik";
import PropTypes from "prop-types";
import { color } from "../../styles/color";
import theme from "../../styles/theme";

interface NotInteractiveDiscreteSlider {
  value: number;
  min?: number;
  max?: number;
  step?: number;
  marks?: Array<any>;
  name: string;
  profileNumberMax: number;
  handleChange?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void | Promise<void> | Promise<FormikErrors<{ recipient: string }>>;
}

const StyledSlider = styled(Slider)(() => ({
  color: "#3a8589",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    zIndex: 10,
    height: 22,
    width: 22,
    backgroundColor: color.blue[100],
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .inner-bar": {
      height: 10,
      width: 1,
      backgroundColor: color.greyscale.white[100],
      marginLeft: 1,
      marginRight: 1,
      zIndex: 2,
    },
    "& .outside-bar": {
      height: 64,
      width: 2,
      backgroundColor: color.blue[100],
      marginLeft: 1,
      marginRight: 1,
      position: "absolute",
      zIndex: 1,
    },
    "& .top-outside-bar": {
      height: 52,
      width: 2,
      backgroundColor: color.blue[100],
      marginLeft: 1,
      marginRight: 1,
      position: "absolute",
      zIndex: 1,
      bottom: 0,
    },
    "& .border": {
      backgroundColor: color.blue[100],
      borderRadius: "50%",
      width: 23,
      height: 23,
      position: "absolute",
      zIndex: 2,
    },
  },
  "& .MuiSlider-valueLabel": {
    color: color.greyscale.black[30],
    fontSize: 20,
    fontWeight: 600,
    top: -30,
    backgroundColor: color.greyscale.white[100],
    border: `2px solid ${color.greyscale.black[10]}`,
    borderRadius: 100,
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-track": {
    height: 8,
    color: color.turquoise[100],
    borderRadius: 0,
  },
  "& .MuiSlider-rail": {
    color: color.greyscale.black[40],
    opacity: 1,
    height: 1,
  },

  "& .MuiSlider-mark": {
    height: 15,
    width: 15,
    borderRadius: "50%",
    border: `1px solid ${color.greyscale.black[40]}`,
    background: color.greyscale.white[100],
    opacity: 1,
    "&.MuiSlider-markActive": {
      border: `4px solid ${color.blue[100]}`,
    },
  },
}));

function SliderThumbComponent(props: any) {
  const { children, ...other } = props;
  // eslint-disable-next-line react/react-in-jsx-scope,react/jsx-props-no-spreading
  return <SliderThumb {...other}>{children}</SliderThumb>;
}

SliderThumbComponent.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

function SliderMarkLabelComponent(props: any) {
  const downXl = useMediaQuery(theme.breakpoints.down("xl"));
  const { children, ...other } = props;
  const parsedOther = {
    ...other,
    style: { left: other.style.left, top: downXl ? -54 : -64 },
  };
  // eslint-disable-next-line react/react-in-jsx-scope,react/jsx-props-no-spreading
  return <SliderMarkLabel {...parsedOther}>{children}</SliderMarkLabel>;
}

SliderMarkLabelComponent.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

function SliderMarkComponent(props: any) {
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));

  const { children, ...other } = props;
  const profile = other.ownerState.componentsProps.valueLabel.value;
  const isSliderActive =
    other.ownerState.componentsProps.valueLabel.valueLabelDisplay === "on";
  const parsedOther = {
    ...other,
    style: {
      ...other.style,
      left: `calc(${other.style.left} - ${downMobile ? "2.6" : "1.6"}%)`,
      background:
        // eslint-disable-next-line no-nested-ternary
        profile >= other["data-index"] + 1 && isSliderActive === false
          ? color.blue[100]
          : profile < other["data-index"] + 1
          ? color.greyscale.black[10]
          : color.greyscale.white[100],
    },
  };
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <SliderMark {...parsedOther}>{children}</SliderMark>;
}

SliderMarkComponent.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

export function NotInteractiveSlider(props: NotInteractiveDiscreteSlider) {
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const { value, min, max, step, marks, handleChange, name, profileNumberMax } =
    props;
  return (
    <Box
      sx={{
        // eslint-disable-next-line no-nested-ternary
        width: downMobile ? "100%" : marks ? 600 : 350,
      }}
    >
      <StyledSlider
        /*
         * Using component props to pass aditional info to markcomponent
         * In this case value has profile number and valueLabelDisplay is on when slider is active
         */
        componentsProps={{
          valueLabel: {
            value: profileNumberMax,
            valueLabelDisplay: handleChange ? "on" : undefined,
          },
        }}
        valueLabelDisplay="off"
        components={{
          ValueLabel: undefined,
          Thumb: handleChange ? SliderThumb : undefined,
          MarkLabel: SliderMarkLabelComponent,
          Mark: SliderMarkComponent,
        }}
        sx={{
          cursor: handleChange ? "pointer" : "default",
        }}
        step={step}
        marks={marks || true}
        min={min}
        max={max}
        value={value}
        /* eslint-disable-next-line @typescript-eslint/no-shadow */
        onChange={(event, value) => {
          if (value <= profileNumberMax) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            handleChange ? handleChange(name, value) : null;
          }
        }}
      />
    </Box>
  );
}

NotInteractiveSlider.defaultProps = {
  min: undefined,
  max: undefined,
  step: undefined,
  marks: undefined,
  handleChange: undefined,
};

export default NotInteractiveSlider;
