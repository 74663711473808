import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { Grid, StepLabel, useMediaQuery } from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
// @ts-ignore
import { useLocation } from "react-router-dom";
import { parse } from "qs";
import { color } from "../../styles/color";
import Text from "../../styles/textStyles";
import theme from "../../styles/theme";
import WCIcons from "../icons/WCIcons";

interface IStepsFragments {
  steps: Array<string>;
  activeColor: string | undefined;
  inactiveColor: string | undefined;
  activeTextStepIconColor: string | undefined;
}

// @ts-ignore
const QontoStepIconRoot = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

function QontoStepIcon(props: any) {
  const { active, completed, className, icon, sx } = props;
  let iconComponent;
  if (completed) {
    iconComponent = (
      <Grid
        container
        className="QontoStepIcon-filled-circle"
        alignItems="center"
        justifyContent="center"
      >
        <WCIcons name="check" color={color.greyscale.white[100]} />
      </Grid>
    );
  } else if (active) {
    iconComponent = (
      <Grid
        container
        className="QontoStepIcon-filled-circle"
        alignItems="center"
        justifyContent="center"
      >
        <Text size="s" weight="semibold" fontColor="inherit">
          {icon}
        </Text>
      </Grid>
    );
  } else {
    iconComponent = (
      <Grid
        container
        className="QontoStepIcon-circle"
        alignItems="center"
        justifyContent="center"
      >
        <Text fontColor="inherit" weight="semibold" size="s">
          {icon}
        </Text>
      </Grid>
    );
  }
  return (
    // @ts-ignore
    <QontoStepIconRoot sx={sx} ownerState={{ active }} className={className}>
      {iconComponent}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.defaultProps = {
  active: undefined,
  className: undefined,
  completed: undefined,
};

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

function StepsFragments(props: IStepsFragments) {
  const location = useLocation();
  const params = location.search.substring(1);
  const { aportacion } = parse(params);
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  let activeStep: number | undefined;
  if (location.pathname.includes("meta/fin") && aportacion === "true") {
    activeStep = 1;
  } else if (location.pathname.includes("meta")) {
    activeStep = 0;
  } else if (location.pathname.includes("perfil")) {
    activeStep = 2;
  } else if (location.pathname.includes("resultado")) {
    activeStep = 3;
  } else {
    activeStep = 0;
  }
  const { steps, inactiveColor, activeColor, activeTextStepIconColor } = props;
  const finalActiveColor = activeColor || color.blue[100];
  const finalInactiveColor = inactiveColor || color.greyscale.black[30];
  const finalActiveTextStepIconColor =
    activeTextStepIconColor || color.greyscale.white[100];
  /*
    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };
    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step:any) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        // @ts-ignore
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };
    */
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper nonLinear activeStep={activeStep} connector={null}>
        {steps.map((label, index) => {
          let displaySx: string;
          if (index === activeStep) {
            displaySx = "inherit";
          } else if (downLg) {
            displaySx = "none";
          } else {
            displaySx = "inherit";
          }
          // @ts-ignore
          return (
            <Step
              sx={{
                display: displaySx,
              }}
              key={label}
              completed={undefined}
            >
              <StepButton color="inherit">
                <StepLabel
                  StepIconProps={{
                    sx: {
                      "& .QontoStepIcon-filled-circle": {
                        width: 32,
                        height: 32,
                        borderRadius: 67,
                        backgroundColor: finalActiveColor,
                        color: finalActiveTextStepIconColor,
                      },
                      "& .QontoStepIcon-circle": {
                        width: 32,
                        height: 32,
                        borderRadius: 67,
                        border: `2px solid ${finalInactiveColor}`,
                        color: inactiveColor,
                      },
                    },
                  }}
                  StepIconComponent={QontoStepIcon}
                  sx={{
                    "& .MuiStepLabel-label.Mui-active": {
                      color: finalActiveColor,
                    },
                    "& .MuiStepLabel-label.Mui-completed": {
                      color: finalActiveColor,
                    },
                    "& .MuiStepLabel-label": {
                      color: finalInactiveColor,
                    },
                  }}
                >
                  <Text size="xs" weight="semibold" fontColor="inherit">
                    {label}
                  </Text>
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

export default StepsFragments;
