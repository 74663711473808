import Box from "@mui/material/Box";
import {
  Slider,
  SliderMark,
  SliderThumb,
  SliderValueLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormikErrors } from "formik";
import * as React from "react";
import PropTypes from "prop-types";
import { color } from "../../styles/color";

interface IPercentageSlider {
  name: string;
  value: number;
  valueLabelFormat: (value: number) => string | JSX.Element;
  handleChange: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<{ recipient: string }>>;
}

const marks = [
  {
    value: 0,
  },
  {
    value: 5,
  },
  {
    value: 10,
  },
  {
    value: 15,
  },
  {
    value: 20,
  },
  {
    value: 25,
  },
  {
    value: 30,
  },
  {
    value: 35,
  },
  {
    value: 40,
  },
  {
    value: 45,
  },
  {
    value: 50,
  },
  {
    value: 55,
  },
  {
    value: 60,
  },
  {
    value: 65,
  },
  {
    value: 70,
  },
  {
    value: 75,
  },
  {
    value: 80,
  },
  {
    value: 85,
  },
  {
    value: 90,
  },
  {
    value: 95,
  },
  {
    value: 100,
  },
];

const StyledSlider = styled(Slider)(({ theme }) => ({
  color: "#3a8589",
  height: 3,
  padding: "13px 0",
  "& .MuiSlider-thumb": {
    zIndex: 10,
    height: 22,
    width: 22,
    outline: `2px solid ${color.greyscale.white[100]}`,
    backgroundColor: color.blue[100],
    "&:hover": {
      boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)",
    },
    "& .inner-bar": {
      height: 10,
      width: 1,
      backgroundColor: color.greyscale.white[100],
      marginLeft: 1,
      marginRight: 1,
      zIndex: 2,
    },
    "& .outside-bar": {
      height: 102,
      width: 2,
      backgroundColor: color.greyscale.black[10],
      marginLeft: 1,
      marginRight: 1,
      position: "absolute",
      zIndex: 1,
      bottom: -23,
    },
    "& .border": {
      backgroundColor: color.blue[100],
      borderRadius: "50%",
      width: 23,
      height: 23,
      outline: `2px solid ${color.greyscale.white[100]}`,
      position: "absolute",
      zIndex: 2,
    },
  },
  "& .MuiSlider-valueLabel": {
    [theme.breakpoints.up("xs")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "20px",
    },
    color: color.greyscale.black[30],
    fontSize: 20,
    fontWeight: 600,
    top: -55,
    backgroundColor: "transparent",
    borderRadius: 100,
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-track": {
    height: 64,
    color: color.turquoise[100],
    borderRadius: "0px 0px 0px 0px",
  },
  "& .MuiSlider-rail": {
    color: color.greyscale.black[40],
    opacity: 1,
    height: 1,
  },

  "& .MuiSlider-mark": {
    height: 16,
    width: 1,
    background: color.greyscale.black[40],
    opacity: 1,
    "&.MuiSlider-markActive": {
      height: 16,
      width: 1,
      background: color.greyscale.white[100],
    },
  },
}));

function SliderThumbComponent(props: any) {
  const { children, ...other } = props;
  const parsedOther = {
    ...other,
    style: { left: `calc(${other.style.left} + 0.2%)` },
  };

  return (
    // eslint-disable-next-line react/react-in-jsx-scope,react/jsx-props-no-spreading
    <SliderThumb {...parsedOther}>
      {children}
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <span className="border" />
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <span className="inner-bar" />
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <span className="inner-bar" />
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <span className="inner-bar" />
      {/* eslint-disable-next-line react/react-in-jsx-scope */}
      <span className="outside-bar" />
    </SliderThumb>
  );
}

SliderThumbComponent.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

function SliderValueLabelComponent(props: any) {
  const { children, ...other } = props;
  // eslint-disable-next-line react/react-in-jsx-scope,react/jsx-props-no-spreading
  return <SliderValueLabel {...other}>{children}</SliderValueLabel>;
}

SliderValueLabelComponent.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

function MarkComponent(props: any) {
  const { children, ...other } = props;
  const markHeight =
    // eslint-disable-next-line no-nested-ternary
    other["data-index"] === 0 ? 0 : other["data-index"] % 5 === 0 ? 40 : 16;
  const parsedOther = {
    ...other,
    style: { ...other.style, height: `${markHeight}px` },
  };
  // eslint-disable-next-line react/react-in-jsx-scope,react/jsx-props-no-spreading
  return <SliderMark {...parsedOther}>{children}</SliderMark>;
}

MarkComponent.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
};

export function PercentageSlider(props: IPercentageSlider) {
  const { value, handleChange, name, valueLabelFormat } = props;
  return (
    <Box sx={{ width: "100%" }}>
      <StyledSlider
        valueLabelDisplay="on"
        components={{
          Thumb: SliderThumbComponent,
          ValueLabel: SliderValueLabelComponent,
          Mark: MarkComponent,
        }}
        step={1}
        marks={marks}
        value={value}
        /* eslint-disable-next-line @typescript-eslint/no-shadow */
        valueLabelFormat={(value) => valueLabelFormat(value)}
        /* eslint-disable-next-line @typescript-eslint/no-shadow */
        onChange={(event, value) => handleChange(name, value)}
      />
    </Box>
  );
}

export default PercentageSlider;
