import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";
import "./styles/globals.css";
import LayoutComplete from "./styles/layouts/LayoutComplete";
import EmptyLayoutComplete from "./styles/layouts/EmptyLayoutComplete";
import { createPageEvent } from "./modules/gtm/gtm.script";

import PageLoading from "./elements/loading/PageLoading";
import Resultado from "./pages/propuesta/resultado";
import InicioPerfil from "./pages/perfil/inicio";

const Token = React.lazy(() => import("./pages/token"));
const Registro = React.lazy(() => import("./pages/registro"));
const Homepage = React.lazy(() => import("./pages"));
const Meta = React.lazy(() => import("./pages/meta"));
const Perfil = React.lazy(() => import("./pages/perfil"));
const Custom404 = React.lazy(() => import("./pages/404"));
const Jubilacion = React.lazy(() => import("./pages/meta/jubilacion"));
const Educacion = React.lazy(() => import("./pages/meta/educacion"));
const Vivienda = React.lazy(() => import("./pages/meta/vivienda"));
const Emergencia = React.lazy(() => import("./pages/meta/emergencia"));
const Herencia = React.lazy(() => import("./pages/meta/herencia"));
const Personalizada = React.lazy(() => import("./pages/meta/personalizada"));
const Fin = React.lazy(() => import("./pages/meta/fin"));
const Propuesta = React.lazy(() => import("./pages/propuesta"));
const Login = React.lazy(() => import("./pages/login"));
const RestorePassword = React.lazy(() => import("./pages/restore-password"));
const Verificacion = React.lazy(() => import("./pages/verificacion"));
const PlanesAsesoramiento = React.lazy(
  () => import("./pages/planes-asesoramiento")
);

const titles = {
  "/": "Finsei",
  "/meta": "Añade una meta",
  "/meta/educacion": "Meta de educación",
  "/meta/jubilacion": "Meta de jubilacion",
  "/meta/viaje": "Meta de herencia",
  "/meta/vivienda": "Meta de vivienda",
  "/meta/emergencia": "Meta de fondo de emergencia",
  "/meta/personalizada": "Meta personalizada",
  "/meta/fin": "Meta añadida",
  "/propuesta": "Propuesta",
  "/registro": "Regístrate en Finsei",
  "/login": "Login",
  "/restore-password": "Cambiar contraseña",
  "/verificacion": "Verificacion",
  "/planes-asesoramiento": "Planes de asesoramiento",
};

function App() {
  const location = useLocation();
  React.useEffect(
    // @ts-ignore
    // eslint-disable-next-line no-return-assign
    () => {
      // @ts-ignore
      document.title = titles[location.pathname] ?? "Finsei";
      createPageEvent(location.pathname);
    },
    [location]
  );
  return (
    <Routes>
      <Route
        path="*"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Custom404 />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      {process.env.REACT_APP_ENVIRONMENT !== "prod" && (
        <Route
          path="/token"
          element={
            <LayoutComplete>
              <React.Suspense fallback={<PageLoading open />}>
                <Token />
              </React.Suspense>
            </LayoutComplete>
          }
        />
      )}

      <Route
        path="/"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Homepage />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/meta"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Meta />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/meta/jubilacion"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Jubilacion />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/meta/educacion"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Educacion />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/meta/vivienda"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Vivienda />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/meta/emergencia"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Emergencia />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/meta/herencia"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Herencia />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/meta/personalizada"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Personalizada />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/meta/fin"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Fin />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/perfil"
        element={
          <LayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Perfil />
            </React.Suspense>
          </LayoutComplete>
        }
      />
      <Route
        path="/propuesta"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Propuesta />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/planes-asesoramiento"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <PlanesAsesoramiento />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/registro"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Registro />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/login"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Login />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/restore-password"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <RestorePassword />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/verificacion"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Verificacion />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/propuesta/resultado"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <Resultado />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
      <Route
        path="/perfil/inicio"
        element={
          <EmptyLayoutComplete>
            <React.Suspense fallback={<PageLoading open />}>
              <InicioPerfil />
            </React.Suspense>
          </EmptyLayoutComplete>
        }
      />
    </Routes>
  );
}

export default App;
