import { Container, Grid, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { color } from "../color";
import { userMockedData } from "../../modules/user/user.data";
import { selectUser } from "../../modules/user/user.slice";
import { OnboardingHeaderNavigation } from "../../components/navigation/OnboardingHeaderNavigation";
import { FooterNavigation } from "../../components/navigation/FooterNavigation";
import theme from "../theme";
import { MobileHeaderNavigation } from "../../components/navigation/MobileHeaderNavigation";
import { selectAuth } from "../../modules/auth/auth.slice";
import { resetAlert } from "../../modules/app/app.slice";
import { setIsAuthDialogOpenInStorage } from "../../utils/functions";

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledContentContainerBox = styled((props: any) => <Box {...props} />)(
  () => ({
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    background: color.greyscale.white[100],
    borderRadius: 32,
    overflowX: "hidden",
    [theme.breakpoints.down("mobile")]: {
      // 78px are header height plus margin top of this element
      minHeight: "calc(100vh - 78px)",
      width: "100%",
      background: color.greyscale.white[100],
      borderRadius: "24px 24px 0px 0px",
      overflowX: "hidden",
      padding: theme.spacing(8),
      marginTop: theme.spacing(6),
      borderRight: 0,
    },
    [theme.breakpoints.up("mobile")]: {
      marginBottom: theme.spacing(28),
      paddingTop: theme.spacing(6),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      paddingBottom: theme.spacing(3),
      marginTop: theme.spacing(5),
      maxWidth: theme.spacing(227.5),
    },
    [theme.breakpoints.up("xl")]: {
      marginBottom: theme.spacing(56),
      paddingTop: theme.spacing(12),
      paddingLeft: theme.spacing(12),
      paddingRight: theme.spacing(12),
      paddingBottom: theme.spacing(6),
      marginTop: theme.spacing(10),
      maxWidth: theme.spacing(322),
    },
  })
);

function LayoutComplete({ children }: any) {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);
  const { isAuthDialogOpen } = auth;
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const { document, email, user } = children?.props?.data || {
    document: "",
    email: "",
    user: userMockedData,
  };
  const { name } = useSelector(selectUser);
  React.useEffect(() => {
    dispatch(resetAlert());
    if (isAuthDialogOpen === true) {
      setIsAuthDialogOpenInStorage("true");
    } else {
      setIsAuthDialogOpenInStorage("false");
    }
  }, []);
  return (
    <Grid
      id="Decoration"
      sx={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        position: "relative",
        left: "0%",
        right: "0%",
        top: "0%",
        bottom: "0%",
        backgroundImage: "url(/assets/images/backgroundImage.webp)",
        backgroundSize: "cover",
      }}
    >
      <Container
        sx={{
          position: "relative",
          height: "100%",
          overflow: "auto",
          scrollbarWidth: "none",
          "::-webkit-scrollbar": {
            display: "none",
          },
          [theme.breakpoints.down("mobile")]: {
            padding: 0,
          },
        }}
        maxWidth="xl"
      >
        <Grid
          container
          direction="column"
          sx={{
            minHeight: "100%",
            flex: "0 0 auto",
          }}
        >
          {downMobile ? (
            <MobileHeaderNavigation
              isAuthDialogOpen={isAuthDialogOpen}
              name={name}
              document={document}
              email={email}
              user={user}
            />
          ) : (
            <OnboardingHeaderNavigation
              isAuthDialogOpen={isAuthDialogOpen}
              name={name}
              document={document}
              email={email}
              user={user}
            />
          )}
          <StyledContentContainerBox>{children}</StyledContentContainerBox>
          {!downMobile && <FooterNavigation />}
        </Grid>
      </Container>
    </Grid>
  );
}
export default LayoutComplete;
