import { ObjectiveType } from "../goal/goal.type";
import { PropuestaStatus } from "./propuesta.type";
import { propuestaMockedData } from "./propuesta.data";
import { fetchApi } from "../../utils/functions";
import { setIsAuthDialogOpen } from "../auth/auth.slice";
import { setUser } from "../user/user.slice";
import { addAlert } from "../app/app.slice";
import { setPlanDataLayer } from "../gtm/gtm.script";

export const getInflation = (config: Array<any>) =>
  config.filter(
    (item: { name: string }) => item.name === "INFLATION" && item
  )[0].value;

// GET the longest array when the input is the return of charts endpoints input and cumulative
export const getLongestDataArray = (arrayOfObjects: any) =>
  arrayOfObjects.reduce((longestArray: {}, item: { data: {} }) => {
    if (Object.keys(longestArray).length < Object.keys(item.data).length) {
      // eslint-disable-next-line no-param-reassign
      longestArray = item.data;
    }
    return longestArray;
  }, {});

// GET the last value of cumulative chart of given goal
export const getBarLastCumulativeValue = (
  cumulativeChart: any,
  goalNumber: 1 | 2 | 3 | 4
) => {
  const index = goalNumber - 1;
  return goalNumber === 1
    ? {
        input: Object.keys(cumulativeChart[index]?.data)
          .map((key) => cumulativeChart[index]?.data[key].input)
          .reduce((acc, item) => (acc > item ? acc : item), 0),
        performance: Object.keys(cumulativeChart[index]?.data)
          .map((key) => cumulativeChart[index]?.data[key].performance)
          .reduce((acc, item) => (acc > item ? acc : item), 0),
      }
    : {
        input: cumulativeChart[index]?.data
          ? Object.keys(cumulativeChart[index]?.data)
              .map((key) => cumulativeChart[index]?.data[key].input)
              .reduce((acc, item) => (acc > item ? acc : item), 0)
          : 0,
        performance: cumulativeChart[index]?.data
          ? Object.keys(cumulativeChart[index]?.data)
              .map((key) => cumulativeChart[index]?.data[key].performance)
              .reduce((acc, item) => (acc > item ? acc : item), 0)
          : 0,
      };
};

export const getFinalInputChart = (
  inputChartLongestDataArray: any,
  inputChart: any,
  plan: any
) => {
  const goal1PositionOnInputChart = inputChart.reduce(
    (position: any, currentItem: { id: any }, index: any) => {
      if (currentItem.id === plan[0]?.objective.id) {
        return index;
      }
      return position;
    },
    null
  );
  const goal2PositionOnInputChart = inputChart.reduce(
    (position: any, currentItem: { id: any }, index: any) => {
      if (currentItem.id === plan[1]?.objective.id) {
        return index;
      }
      return position;
    },
    null
  );
  const goal3PositionOnInputChart = inputChart.reduce(
    (position: any, currentItem: { id: any }, index: any) => {
      if (currentItem.id === plan[2]?.objective.id) {
        return index;
      }
      return position;
    },
    null
  );
  const goal4PositionOnInputChart = inputChart.reduce(
    (position: any, currentItem: { id: any }, index: any) => {
      if (currentItem.id === plan[3]?.objective.id) {
        return index;
      }
      return position;
    },
    null
  );
  return Object.keys(inputChartLongestDataArray).map((key, index) => ({
    name: index + 1,
    bar1: inputChart[goal1PositionOnInputChart]?.data[key]?.monthly_input,
    bar2: inputChart[goal2PositionOnInputChart]?.data[key]?.monthly_input,
    bar3: inputChart[goal3PositionOnInputChart]?.data[key]?.monthly_input,
    bar4: inputChart[goal4PositionOnInputChart]?.data[key]?.monthly_input,
  }));
};

export const getFinalInputChartWithoutInveert = (
  inputChartLongestDataArray: any,
  withoutInveertChart: any,
  plan: any
) => {
  const goal1PositionOnInputChart = withoutInveertChart.reduce(
    (position: any, currentItem: { id: any }, index: any) => {
      if (currentItem.id === plan[0]?.objective.id) {
        return index;
      }
      return position;
    },
    null
  );
  const goal2PositionOnInputChart = withoutInveertChart.reduce(
    (position: any, currentItem: { id: any }, index: any) => {
      if (currentItem.id === plan[1]?.objective.id) {
        return index;
      }
      return position;
    },
    null
  );
  const goal3PositionOnInputChart = withoutInveertChart.reduce(
    (position: any, currentItem: { id: any }, index: any) => {
      if (currentItem.id === plan[2]?.objective.id) {
        return index;
      }
      return position;
    },
    null
  );
  const goal4PositionOnInputChart = withoutInveertChart.reduce(
    (position: any, currentItem: { id: any }, index: any) => {
      if (currentItem.id === plan[3]?.objective.id) {
        return index;
      }
      return position;
    },
    null
  );
  return Object.keys(inputChartLongestDataArray).map((key, index) => ({
    name: index + 1,
    bar1: withoutInveertChart[goal1PositionOnInputChart]?.data[key]?.input
      ? withoutInveertChart[goal1PositionOnInputChart]?.data[key]?.input
      : undefined,
    bar2: withoutInveertChart[goal2PositionOnInputChart]?.data[key]?.input
      ? withoutInveertChart[goal2PositionOnInputChart]?.data[key]?.input
      : undefined,
    bar3: withoutInveertChart[goal3PositionOnInputChart]?.data[key]?.input
      ? withoutInveertChart[goal3PositionOnInputChart]?.data[key]?.input
      : undefined,
    bar4: withoutInveertChart[goal4PositionOnInputChart]?.data[key]?.input
      ? withoutInveertChart[goal4PositionOnInputChart]?.data[key]?.input
      : undefined,
  }));
};

export const getTotalCumulativeChart = (
  inputChartLongestDataArray: any,
  cumulativeChart: any,
  bar1LastCumulativeValue: any,
  bar2LastCumulativeValue: any,
  bar3LastCumulativeValue: any,
  bar4LastCumulativeValue: any
) =>
  Object.keys(inputChartLongestDataArray).map((key, index) => {
    const goal1Input =
      cumulativeChart[0]?.data[key]?.input ?? bar1LastCumulativeValue?.input;
    const goal2Input =
      cumulativeChart[1]?.data[key]?.input ?? bar2LastCumulativeValue?.input;
    const goal3Input =
      cumulativeChart[2]?.data[key]?.input ?? bar3LastCumulativeValue?.input;
    const goal4Input =
      cumulativeChart[3]?.data[key]?.input ?? bar4LastCumulativeValue?.input;

    const goal1Performance =
      cumulativeChart[0]?.data[key]?.performance ??
      bar1LastCumulativeValue?.performance;
    const goal2Performance =
      cumulativeChart[1]?.data[key]?.performance ??
      bar2LastCumulativeValue?.performance;
    const goal3Performance =
      cumulativeChart[2]?.data[key]?.performance ??
      bar3LastCumulativeValue?.performance;
    const goal4Performance =
      cumulativeChart[3]?.data[key]?.performance ??
      bar4LastCumulativeValue?.performance;

    return {
      name: index + 1,
      bar1: goal1Input + goal2Input + goal3Input + goal4Input,
      bar2:
        goal1Performance +
        goal2Performance +
        goal3Performance +
        goal4Performance,
      bar3: undefined,
      bar4: undefined,
    };
  });

export const getTotalCumulativeChartWithoutInveert = (
  inputChartLongestDataArray: any,
  withoutInveertChart: any
) =>
  Object.keys(inputChartLongestDataArray).map((key, index) => {
    const bar1LastCumulativeValue = {
      input: Object.keys(withoutInveertChart[0]?.data)
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .map((key) => withoutInveertChart[0]?.data[key].cumulative_input)
        .reduce((acc, item) => (acc > item ? acc : item), 0),
    };
    const bar2LastCumulativeValue = {
      input: withoutInveertChart[1]?.data
        ? Object.keys(withoutInveertChart[1]?.data)
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .map((key) => withoutInveertChart[1]?.data[key].cumulative_input)
            .reduce((acc, item) => (acc > item ? acc : item), 0)
        : 0,
    };
    const bar3LastCumulativeValue = {
      input: withoutInveertChart[2]?.data
        ? Object.keys(withoutInveertChart[2]?.data)
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .map((key) => withoutInveertChart[2]?.data[key].cumulative_input)
            .reduce((acc, item) => (acc > item ? acc : item), 0)
        : 0,
    };
    const bar4LastCumulativeValue = {
      input: withoutInveertChart[3]?.data
        ? Object.keys(withoutInveertChart[3]?.data)
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .map((key) => withoutInveertChart[3]?.data[key].cumulative_input)
            .reduce((acc, item) => (acc > item ? acc : item), 0)
        : 0,
    };

    const goal1Input =
      withoutInveertChart[0]?.data[key]?.cumulative_input ??
      bar1LastCumulativeValue.input;
    const goal2Input =
      withoutInveertChart[1]?.data[key]?.cumulative_input ??
      bar2LastCumulativeValue.input;
    const goal3Input =
      withoutInveertChart[2]?.data[key]?.cumulative_input ??
      bar3LastCumulativeValue.input;
    const goal4Input =
      withoutInveertChart[3]?.data[key]?.cumulative_input ??
      bar4LastCumulativeValue.input;

    return {
      name: index + 1,
      bar1: goal1Input + goal2Input + goal3Input + goal4Input,
      bar2: 0,
      bar3: undefined,
      bar4: undefined,
    };
  });

export const checkObjectiveTypeInexistence = (
  plan: any,
  goalType: ObjectiveType
) =>
  plan.reduce((isDisabled: boolean, objectivePlan: any) => {
    if (objectivePlan.objective.type === goalType) {
      // eslint-disable-next-line no-param-reassign
      isDisabled = true;
    }
    return isDisabled;
  }, false);

export const getObjectivesFromPlan = (plan: any) =>
  plan.map((objectivePlan: { objective: any }) => objectivePlan.objective);

export const getPlanWithScenarios = (plan: Array<any>, scenarios: Array<any>) =>
  plan.map((planObjective) => {
    const scenarioObjectiveId = scenarios.reduce((selectedScenario, item) => {
      if (item.objective === planObjective.objective.id) {
        // eslint-disable-next-line no-param-reassign
        selectedScenario = item;
      }
      return selectedScenario;
    }, null);
    return { ...planObjective, ...scenarioObjectiveId?.scenario_data };
  });

export const getFinancialProductsByType = (
  recommendation: any,
  type?: "mutual" | "pension"
) =>
  recommendation.map(
    (objectiveRecommendation: { objective: number; services: any[] }) => ({
      id: objectiveRecommendation.objective,
      products: objectiveRecommendation.services.reduce(
        (
          total: any[],
          service: {
            financial_products: any;
            financial_service: { type: string };
          }
        ) => {
          if (service.financial_service.type === type) {
            // eslint-disable-next-line no-param-reassign
            total = [...total, ...service.financial_products];
          } else if (!type) {
            // eslint-disable-next-line no-param-reassign
            total = [...total, ...service.financial_products];
          }
          return total;
        },
        []
      ),
    })
  );

export const propuestaGetClientSideProps = async (
  setInitialData: (data: any) => void,
  router: any,
  setLoading: (loading: boolean) => void,
  dispatch: any
) => {
  const resPlan = fetchApi("plans?global_info=1", "get", {});
  const resPlanId = fetchApi("user/me/plan", "get", {})
    .then(
      // eslint-disable-next-line consistent-return
      async (response) => {
        if (
          response.status === PropuestaStatus.COMPLETED ||
          process.env.REACT_APP_ENVIRONMENT !== "prod" ||
          response.status === PropuestaStatus.IN_PROGRESS
        ) {
          const chartInputs = fetchApi(
            `plans/${response.id}/graphs/inputs`,
            "get",
            {}
          );
          const chartCumulative = fetchApi(
            `plans/${response.id}/graphs/cumulative-inputs`,
            "get",
            {}
          );
          const chartWithoutInveertAnnual = fetchApi(
            `plans/${response.id}/graphs/without-inveert/annual`,
            "get",
            {}
          );
          const chartWithoutInveertMonthly = fetchApi(
            `plans/${response.id}/graphs/without-inveert/monthly`,
            "get",
            {}
          );
          const chartOutputs = fetchApi(
            `plans/${response.id}/graphs/outputs`,
            "get",
            {}
          );
          const recommendation = fetchApi(
            `plans/${response.id}/recommendation`,
            "get",
            {}
          );
          const chartEquity = fetchApi(
            `plans/${response.id}/graphs/fixed-income-and-equity`,
            "get",
            {}
          );
          let scenarios;
          if (process.env.REACT_APP_ENVIRONMENT === "local") {
            scenarios = fetchApi(
              `plans/${response.id}/graphs/fixed-income-and-equity`,
              "get",
              {}
            );
          } else {
            scenarios = fetchApi(`plans/${response.id}/scenarios`, "get", {});
          }

          return Promise.all([
            chartInputs,
            chartCumulative,
            chartWithoutInveertAnnual,
            chartOutputs,
            recommendation,
            chartEquity,
            scenarios,
            chartWithoutInveertMonthly,
          ])
            .then((resPromises) => ({
              plan: response,
              chartInputs: resPromises[0],
              chartCumulative: resPromises[1],
              chartWithoutInveert: resPromises[2],
              chartOutputs: resPromises[3],
              recommendation: resPromises[4],
              chartEquity: resPromises[5],
              scenarios: resPromises[6],
              chartWithoutInveertMonthly: resPromises[7],
            }))
            .catch((e) => Promise.reject(e));
        }
      }
    )
    .catch((e) => Promise.reject(e));
  const resRisk = fetchApi("risk-profile", "get", {});
  const resIncome = fetchApi("income", "get", {});
  const resFamily = fetchApi("family-situation", "get", {});
  const resFinancial = fetchApi("financial-wealth", "get", {});
  const resConfig = fetchApi("config", "get", {});
  const resExpenses = fetchApi("expenses", "get", {});
  const resDataRes = fetchApi("auth/user", "get", {});
  const resObjectives = fetchApi("objectives", "get", {});
  const resAllRisk = fetchApi("config/risk-profile", "get", {});
  Promise.all([
    resPlan,
    resPlanId,
    resRisk,
    resIncome,
    resFamily,
    resFinancial,
    resAllRisk,
    resConfig,
    resExpenses,
    resDataRes,
    resObjectives,
  ])
    .then((resFinal) => {
      const data = {
        scenarios:
          process.env.REACT_APP_ENVIRONMENT === "local"
            ? propuestaMockedData.scenarios
            : resFinal[1]?.scenarios,
        recommendation: resFinal[1]?.recommendation,
        inputChart: resFinal[1]?.chartInputs,
        outputChart: resFinal[1]?.chartOutputs,
        cumulativeChart: resFinal[1]?.chartCumulative,
        withoutInveertChart: resFinal[1]?.chartWithoutInveert,
        equityChart: resFinal[1]?.chartEquity,
        plan: resFinal[0],
        withoutInveertChartMonthly: resFinal[1]?.chartWithoutInveertMonthly,
        objectives: resFinal[10],
        financialWealth: resFinal[5],
        user: resFinal[4],
        userIncome: resFinal[3],
        name: resFinal[4]?.name ?? "",
        riskProfileMax: resFinal[2].reduce(
          (mainProfile: any, profile: { main: boolean }) => {
            if (profile.main === true) {
              // eslint-disable-next-line no-param-reassign
              mainProfile = profile;
            }
            return mainProfile;
          },
          undefined
        ),
        riskProfile: resFinal[2].reduce(
          (mainProfile: any, profile: { is_active: boolean }) => {
            if (profile.is_active === true) {
              // eslint-disable-next-line no-param-reassign
              mainProfile = profile;
            }
            return mainProfile;
          },
          undefined
        ),
        riskProfiles: resFinal[6],
        config: resFinal[7],
        userExpenses: resFinal[8],
        document: resFinal[9]?.document ? resFinal[9]?.document : "",
        email: resFinal[9]?.email ? resFinal[9]?.email : "",
        phone: resFinal[9]?.phone ? resFinal[9]?.phone : null,
      };
      setPlanDataLayer(data.plan.global_info.monthly_saving);
      // @ts-ignore
      setInitialData(data);
      dispatch(setIsAuthDialogOpen(false));
      dispatch(
        setUser({
          name: data.name,
          birthday: data.user.birthday,
          income: { total: data.userIncome.total },
          financial_wealth: {
            private_pension_plan: data.financialWealth.private_pension_plan,
            initial_investment: data.financialWealth.initial_investment,
          },
          riskProfile: {
            profile_id: parseInt(data.riskProfile.identifier, 10),
            max_profile_id: parseInt(data.riskProfileMax.identifier, 10),
          },
          riskProfileArray: data.riskProfiles,
        })
      );
      setLoading(false);
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        dispatch(
          addAlert({
            message: e.response.data.message,
            isError: true,
            isOpen: true,
          })
        );
      }
      setLoading(false);
    });
};

export const roundAdjustedObjective = (
  performance: number,
  saving: number,
  adjustedObjective: number
) => {
  const decimalPartNum1 = performance - Math.floor(performance);
  const decimalPartNum2 = saving - Math.floor(saving);
  const decimalSum = decimalPartNum1 + decimalPartNum2;
  const decimalPartSum = decimalSum - Math.floor(decimalSum);

  return decimalPartSum >= 0.5 ? adjustedObjective - 1 : adjustedObjective;
};
