import {
  userFinancialWealthMockedData,
  userMockedData,
} from "../user/user.data";

export const propuestaMockedData = {
  financialWealth: userFinancialWealthMockedData,
  user: userMockedData,
  withoutInveertChartMonthly: [
    {
      id: 1,
      data: [
        {
          name: 1,
          input: 142.71300805150943,
          cumulative_input: 1712.556096618113,
          date_init: "2022-05-02",
          date_end: "2023-04-02",
        },
        {
          name: 2,
          input: 142.71300805150943,
          cumulative_input: 3425.112193236226,
          date_init: "2023-05-02",
          date_end: "2024-04-02",
        },
        {
          name: 3,
          input: 142.71300805150943,
          cumulative_input: 5137.668289854342,
          date_init: "2024-05-02",
          date_end: "2025-04-02",
        },
        {
          name: 4,
          input: 142.71300805150943,
          cumulative_input: 6850.2243864724605,
          date_init: "2025-05-02",
          date_end: "2026-04-02",
        },
        {
          name: 5,
          input: 142.71300805150943,
          cumulative_input: 8562.780483090577,
          date_init: "2026-05-02",
          date_end: "2027-04-02",
        },
        {
          name: 6,
          input: 142.71300805150943,
          cumulative_input: 10275.336579708684,
          date_init: "2027-05-02",
          date_end: "2028-04-02",
        },
        {
          name: 7,
          input: 142.71300805150943,
          cumulative_input: 11987.892676326792,
          date_init: "2028-05-02",
          date_end: "2029-04-02",
        },
        {
          name: 8,
          input: 142.71300805150943,
          cumulative_input: 13700.4487729449,
          date_init: "2029-05-02",
          date_end: "2030-04-02",
        },
        {
          name: 9,
          input: 142.71300805150943,
          cumulative_input: 15413.004869563007,
          date_init: "2030-05-02",
          date_end: "2031-04-02",
        },
        {
          name: 10,
          input: 142.71300805150943,
          cumulative_input: 17125.560966181114,
          date_init: "2031-05-02",
          date_end: "2032-04-02",
        },
        {
          name: 11,
          input: 142.71300805150943,
          cumulative_input: 18838.11706279922,
          date_init: "2032-05-02",
          date_end: "2033-04-02",
        },
        {
          name: 12,
          input: 142.71300805150943,
          cumulative_input: 20550.67315941733,
          date_init: "2033-05-02",
          date_end: "2034-04-02",
        },
        {
          name: 13,
          input: 142.71300805150943,
          cumulative_input: 22263.229256035436,
          date_init: "2034-05-02",
          date_end: "2035-04-02",
        },
        {
          name: 14,
          input: 142.71300805150943,
          cumulative_input: 23975.785352653544,
          date_init: "2035-05-02",
          date_end: "2036-04-02",
        },
        {
          name: 15,
          input: 142.71300805150943,
          cumulative_input: 25688.34144927165,
          date_init: "2036-05-02",
          date_end: "2037-04-02",
        },
        {
          name: 16,
          input: 142.71300805150943,
          cumulative_input: 27400.89754588976,
          date_init: "2037-05-02",
          date_end: "2038-04-02",
        },
        {
          name: 17,
          input: 142.71300805150943,
          cumulative_input: 29113.453642507866,
          date_init: "2038-05-02",
          date_end: "2039-04-02",
        },
        {
          name: 18,
          input: 142.71300805150943,
          cumulative_input: 30826.009739125973,
          date_init: "2039-05-02",
          date_end: "2040-04-02",
        },
        {
          name: 19,
          input: 142.71300805150943,
          cumulative_input: 32538.56583574408,
          date_init: "2040-05-02",
          date_end: "2041-04-02",
        },
        {
          name: 20,
          input: 142.71300805150943,
          cumulative_input: 34251.12193236223,
          date_init: "2041-05-02",
          date_end: "2042-04-02",
        },
        {
          name: 21,
          input: 142.71300805150943,
          cumulative_input: 35963.67802898038,
          date_init: "2042-05-02",
          date_end: "2043-04-02",
        },
        {
          name: 22,
          input: 142.71300805150943,
          cumulative_input: 37676.23412559853,
          date_init: "2043-05-02",
          date_end: "2044-04-02",
        },
        {
          name: 23,
          input: 142.71300805150943,
          cumulative_input: 39388.79022221668,
          date_init: "2044-05-02",
          date_end: "2045-04-02",
        },
        {
          name: 24,
          input: 142.71300805150943,
          cumulative_input: 41101.34631883483,
          date_init: "2045-05-02",
          date_end: "2046-04-02",
        },
        {
          name: 25,
          input: 142.71300805150943,
          cumulative_input: 42813.90241545298,
          date_init: "2046-05-02",
          date_end: "2047-04-02",
        },
        {
          name: 26,
          input: 142.71300805150943,
          cumulative_input: 44526.458512071134,
          date_init: "2047-05-02",
          date_end: "2048-04-02",
        },
        {
          name: 27,
          input: 142.71300805150943,
          cumulative_input: 46239.014608689286,
          date_init: "2048-05-02",
          date_end: "2049-04-02",
        },
        {
          name: 28,
          input: 142.71300805150943,
          cumulative_input: 47951.57070530744,
          date_init: "2049-05-02",
          date_end: "2050-04-02",
        },
        {
          name: 29,
          input: 142.71300805150943,
          cumulative_input: 49664.12680192559,
          date_init: "2050-05-02",
          date_end: "2051-04-02",
        },
        {
          name: 30,
          input: 142.71300805150943,
          cumulative_input: 51376.68289854374,
          date_init: "2051-05-02",
          date_end: "2052-04-02",
        },
        {
          name: 31,
          input: 142.71300805150943,
          cumulative_input: 53089.23899516189,
          date_init: "2052-05-02",
          date_end: "2053-04-02",
        },
        {
          name: 32,
          input: 142.71300805150943,
          cumulative_input: 54801.79509178004,
          date_init: "2053-05-02",
          date_end: "2054-04-02",
        },
        {
          name: 33,
          input: 142.71300805150943,
          cumulative_input: 56514.35118839819,
          date_init: "2054-05-02",
          date_end: "2055-04-02",
        },
        {
          name: 34,
          input: 142.71300805150943,
          cumulative_input: 58226.90728501634,
          date_init: "2055-05-02",
          date_end: "2056-04-02",
        },
        {
          name: 35,
          input: 142.71300805150943,
          cumulative_input: 59939.463381634494,
          date_init: "2056-05-02",
          date_end: "2057-04-02",
        },
        {
          name: 36,
          input: 142.71300805150943,
          cumulative_input: 60795.74142994357,
          date_init: "2057-05-02",
          date_end: "2057-10-02",
        },
      ],
    },
    {
      id: 3,
      data: [
        {
          name: 1,
          input: 1514.7335614338374,
          cumulative_input: 18176.80273720605,
          date_init: "2022-05-02",
          date_end: "2023-04-02",
        },
      ],
    },
  ],
  plan: {
    id: "327ec35c-f50e-4f28-8923-1408b761eb23",
    birthday: "1992-10-02T13:39:02.000000Z",
    gender: null,
    initial_investment: 3000,
    private_pension_plan: 5000,
    global_info: {
      initial_investment: 8000,
      saving: 80757.78544267055,
      yearly_saving: 22780.50517109021,
      monthly_saving: 1898.3754309241842,
      performance: 224339.32581053948,
      adjusted_objective: 313097.11125320994,
      remaining_saving: 305097.11125320994,
      equity: 0.557,
      fixed_income: 0.44299999999999995,
    },
    plan: [
      {
        initial_investment: 5300,
        yearly_saving: 1726.370628434155,
        monthly_saving: 143.86421903617958,
        adjusted_objective: 289554.9365457113,
        saving: 59703.65090001449,
        remaining_saving: 284254.9365457113,
        performance: 224551.2856456969,
        equity: 0.8,
        fixed_income: 0.2,
        min_performance: 0,
        max_performance: 0,
        objective: {
          id: 7,
          type: "EDUCATION",
          output_type: "UNIQUE",
          protection_type: "CAPITAL",
          name: "Educación de sdfs",
          amount: 23234,
          amount_type: "UNIQUE",
          term: 0,
          term_type: "YEAR",
          duration: null,
          duration_type: null,
          stops_saving: false,
          receiver: "sdfs",
          recipient: "sdfs",
          date_init: "2023-03-15",
          date_end: "2024-03-15",
          is_active: true,
          extra_info: {
            amount: 23234,
            duration: 1,
            date_init: "2023-03-15",
            recipient: "sdfs",
          },
          probability: null,
          time_horizon: 0,
          percentage_completed: null,
          adjusted_objective: 23582.51,
        },
      },
      {
        initial_investment: 2700,
        yearly_saving: 21054.13454265606,
        monthly_saving: 1754.5112118880047,
        adjusted_objective: 23542.174707498645,
        saving: 21054.13454265606,
        remaining_saving: 20842.174707498645,
        performance: -211.95983515741452,
        equity: 0.08,
        fixed_income: 0.92,
        min_performance: 0,
        max_performance: 0,
        objective: {
          id: 9,
          type: "RETIREMENT",
          output_type: "MONTHLY",
          protection_type: "LONG_TERM",
          name: "Complemento jubilación",
          amount: 368.36,
          amount_type: "UNIQUE",
          term: 64,
          term_type: "AGE",
          duration: 312,
          duration_type: "MONTHLY",
          stops_saving: false,
          receiver: null,
          recipient: null,
          date_init: "2056-10-02",
          date_end: "2082-10-02",
          is_active: true,
          extra_info: {
            net_income: 32423,
            percentage: 44,
            is_freelance: false,
            retirement_age: 64,
            years_contributed: 33,
            simulatedRetirement: [
              {
                retirement_age: 63,
                retirement_income: 2876,
              },
              {
                retirement_age: 64,
                retirement_income: 3140,
              },
              {
                retirement_age: 65,
                retirement_income: 3412,
              },
              {
                retirement_age: 66,
                retirement_income: 3605,
              },
              {
                retirement_age: 67,
                retirement_income: 3804,
              },
            ],
            monthly_self_employed_fee: null,
          },
          probability: null,
          time_horizon: 35,
          percentage_completed: null,
          adjusted_objective: 232834.4853671255,
        },
      },
    ],
    created_at: "2022-03-03T11:44:24.000000Z",
    is_active: false,
    channel: null,
  },
  scenarios: [
    {
      objective: 1,
      scenario_data: {
        optimistic_TIR: 0.32942154793214495,
        optimistic_performance: 18533.316247386116,
        optimistic_amount: 5748.760145404498,
        expected_TIR: 0.08152625805336466,
        expected_performance: 3732.1621363549516,
        expected_amount: 13943.997471054992,
        pessimistic_TIR: 0.07112271996092612,
        pessimistic_performance: 3921.0731615840127,
        pessimistic_amount: 3203.9285033503866,
      },
    },
    {
      objective: 3,
      scenario_data: {
        optimistic_TIR: 0.3702081668183108,
        optimistic_performance: 10632.803727439597,
        optimistic_amount: 15384.281776293388,
        expected_TIR: -0.20286546248960824,
        expected_performance: 122.89896072079134,
        expected_amount: 7874.377009574583,
        pessimistic_TIR: -0.16317413677253512,
        pessimistic_performance: 717.8506785385698,
        pessimistic_amount: 5469.3287273923615,
      },
    },
  ],
  recommendation: [
    {
      objective: 9,
      services: [
        {
          financial_service: {
            type: "mutual",
            annual_percentage_rate: "0.0060",
            liquidity: "En cualquier momento",
            taxation_when_selling: "Base imponible del ahorro IRPF",
            tax_advantage: "Traspaso sin coste fiscal",
          },
          financial_products: [
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU1620157294",
                name: "BNP Paribas Funds Green Bond",
                alias: "BNP Paribas Green Bond",
                description:
                  "El fondo trata de incrementar el valor de sus activos a medio plazo invirtiendo en bonos verdes globales (Europa principalmente) que apoyen proyectos medioambientales, emitidos por organismos soberanos supranacionales y corporativos, entidades locales y/o gobiernos en divisas fuertes.\n\n                Se gestiona de manera activa y, como tal, puede invertir en valores que no estén incluidos en el índice Bloomberg Barclays MSCI Global Green Bond Index. El equipo de inversiones aplica asimismo la política de inversión sostenible de BNP Paribas AM, que tiene en cuenta los criterios ambientales, sociales y de gobernanza (ASG) en las inversiones del fondo.  El proceso de inversión filtra y excluye los bonos verdes con peor puntuación ASG, y procura evitar «green-washing» a través de una definición exigente del bono verde.\n                \n                El fondo obtuvo la acreditación ISR (inversión socialmente responsable) por parte del Ministerio de finanzas francés, y el sello GreenFin por parte del Ministerio francés de la Transición Ecológica y Solidária.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/BNP-Green-Bond-KID_ES_es_LU1620157294_YES_2020-02-19.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU1620157294_YES_2020-12-31.pdf",
                asset_category: "Renta Fija Diversificada",
                asset: "Renta Fija",
                portfolio_weight: "0.09",
              },
              portfolio_weight: 0.02,
              saving: 99.95955882352948,
              input: 99.95955882352948,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "IE00BD0DT792",
                name: "iShares Green Bond Index Fund",
                alias: "iShares Green Bond Index Fund",
                description:
                  "El fondo trata de obtener rentabilidad de su inversión mediante una combinación de revalorización del capital y rendimientos que refleje la rentabilidad del Bloomberg Barclays MSCI Green Bond Index, el índice de referencia del fondo (el Índice). El Fondo invierte principalmente en los valores de renta fija (RF) (como bonos) que componen el Índice, cuyos ingresos se utilizan para financiar proyectos con beneficios medioambientales directos como pueden ser por ejemplo la reducción de emisiones de CO2, el ahorro y tratamiento del agua, la preservación forestal, o la generación de energías renovables.\n\n                Es un fondo indexado (gestión pasiva).",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/iShares-Green-Bonds-KID_ES_es_IE00BD0DT792_YES_2020-02-12.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_IE00BD0DT792_YES_2020-11-30-1.pdf",
                asset_category: "Renta Fija Diversificada",
                asset: "Renta Fija",
                portfolio_weight: "0.05",
              },
              portfolio_weight: 0.01,
              saving: 55.533088235294166,
              input: 55.533088235294166,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU0966596875",
                name: "DPAM Bonds Emerging Markets Sustainable",
                alias: "DPAM Bonds Emerging Markets Sustainable",
                description:
                  "El fondo invierte principalmente en obligaciones y/u otros títulos de deuda denominados en cualquier divisa, emitidos (o garantizados) por países emergentes (lo que incluye sus administraciones públicas territoriales y sus organismos públicos —o asimilados—) o por organismos públicos internacionales (como el Banco Mundial y el Banco Europeo de Reconstrucción y Desarrollo) y seleccionados según criterios vinculados al desarrollo sostenible (como, por ejemplo, la igualdad social, el respeto por el medio ambiente y una gobernanza política y económica equitativa).\n\n                Se trata de un fondo gestionado activamente.\n                \n                El fondo obtuvo el sello de inversión sostenible belga Towards Sustainability y la acreditación LuxFLAG ESG. LuxFLAG es una organización sin ánimo de lucro internacional e independiente cuyo objetivo es promover la inversión sostenible. Reconoce aquellos fondos que incorporan el análisis de criterios y consideraciones medioambientales, sociales y de gobernanzas (ESG) en su proceso de inversión.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/DPAM-RF-EM-KID_ES_en_LU0966596875_YES_2020-02-19.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU0966596875_YES_2020-11-30.pdf",
                asset_category: "Renta Fija Países Emergentes",
                asset: "Renta Fija",
                portfolio_weight: "0.08",
              },
              portfolio_weight: 0.02,
              saving: 88.85294117647065,
              input: 88.85294117647065,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU1644441476",
                name: "Candriam SRI Bond Global High Yield",
                alias: "Candriam SRI Bond Global High Yield",
                description:
                  "Este fondo de renta fija invierte en bonos de alto rendimiento («High Yield») de empresas internacionales. Los valores se seleccionan en función de un proceso de análisis económico-financiero, así como de criterios medioambientales, sociales y de gobierno corporativo (ASG). El análisis ASG combina un enfoque «Best-in-Class» con un proceso de exclusión de los emisores que incumplan con los principios del pacto mundial de las Naciones Unidas en materia de derechos humanos y laborales, medio-ambiente y lucha contra la corrupción y/o que estén expuestos a actividades controvertidas (armamento, carbón térmico, tabaco etc…). El fondo reporta un impacto positivo en los siguientes ámbitos (respecto a su benchmark): reducción de consumo de agua y de emisión de carbono, producción de energías renovables, programas de biodiversidad, y disminución de la generación de residuos.\n\n                El fondo obtuvo el sello ISR (inversión socialmente responsable) por parte del Ministerio de finanzas francés y el sello Towards Sustainability por parte del gobierno belga.\n                \n                Es un fondo de gestión activa.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/Candriam-HY-KID_ES_es_LU1644441476_YES_2020-07-21.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/CandriamSustainableBondGlobalHighYield_Rep_R_R_20201231-1.pdf",
                asset_category: "Renta Fija Alto Rendimiento",
                asset: "Renta Fija",
                portfolio_weight: "0.12",
              },
              portfolio_weight: 0.02,
              saving: 133.27941176470597,
              input: 133.27941176470597,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU1434524259",
                name: "Candriam SRI Equity Emerging Markets",
                alias: "Candriam SRI Equity Emerging Markets",
                description:
                  "El fondo invierte en acciones de empresas de países emergentes que cumplen con los diez principios del pacto mundial de la ONU y que no están involucradas en actividades controvertidas como por ejemplo el comercio de armas. El fondo excluye de sus inversiones las empresas que no cumplan con los mínimos criterios ASG, y enfoca sus inversiones en empresas que están bien posicionadas para afrontar los retos de la sostenibilidad global.\n\n                Los impactos ASG más significativos reportados por el fondo se concentra en los ahorros de emisión de carbono y de consumo de agua.\n                \n                Es un fondo de gestión activa.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/Candriam-RV-EM-KID_ES_es_LU1434524259_YES_2020-07-06.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/CandriamSustainableEquityEmergingMarkets_Rep_R_R_20201231.pdf",
                asset_category: "Renta Variable Países Emergentes",
                asset: "Renta Variable",
                portfolio_weight: "0.20",
              },
              portfolio_weight: 0.09,
              saving: 457.72727272727315,
              input: 457.72727272727315,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU1437020909",
                name: "Amundi Index MSCI Global Climate Change",
                alias: "Amundi Index MSCI Global Climate Change",
                description:
                  "El fondo pretende replicar la rentabilidad del Índice MSCI World Climate Change Index. Este índice está basado en el MSCI World Index («Índice base»), que incluye valores de gran y mediana capitalización de 23 países considerados mercados desarrollados. El objetivo del Índice consiste en representar la rentabilidad de una estrategia en la que se re-ponderan los valores en función de las oportunidades y los riesgos asociados a la transición hacia un modelo económico de menor consumo de carbono, y se procura reducir al mínimo las exclusiones del Índice base.\n\n                Es un fondo indexado (gestión pasiva).",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/Amundi-RV-Climate-Change-MR_ES_es_LU1437020909_RES_2020-07-31.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU1437020909_RES_2020-12-31.pdf",
                asset_category: "Renta Variable Global",
                asset: "Renta Variable",
                portfolio_weight: "0.12",
              },
              portfolio_weight: 0.05,
              saving: 274.63636363636385,
              input: 274.63636363636385,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU0348927095",
                name: "Nordea Global Climate & Environment Fund",
                alias: "Nordea Global Climate & Environment Fund",
                description:
                  "El objetivo del fondo consiste en proporcionar a los accionistas un crecimiento de la inversión a largo plazo. Al gestionar activamente la cartera del fondo, el equipo de gestión se centra en aquellas empresas que desarrollan soluciones respetuosas con el medioambiente y el clima, como las energías renovables y la eficiencia de los recursos, y que parecen ofrecer mejores perspectivas de crecimiento y características de inversión. El fondo invierte principalmente en títulos de renta variable de empresas de todo el mundo. El fondo también sigue una política de exclusión de empresas de algunos sectores controvertidos cómo pueden ser el carbón térmico y metalúrgico, el petróleo y gas convencionales y no convencionales, el armamento militar y nuclear, las armas convencionales y controvertidas, y el tabaco.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/Nordea-KID_ES_es_LU0348927095_YES_2020-06-25.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU0348927095_YES_2020-12-31.pdf",
                asset_category: "Renta Variable Sector Ecológico",
                asset: "Renta Variable",
                portfolio_weight: "0.12",
              },
              portfolio_weight: 0.05,
              saving: 274.63636363636385,
              input: 274.63636363636385,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU0503631631",
                name: "Pictet Global Environmental Opportunities",
                alias: "Pictet Global Environmental Opportunities",
                description:
                  "El fondo invierte a nivel global y principalmente en acciones de empresas de energía limpia y agua, agricultura, silvicultura y otras áreas de la cadena de valor medioambiental. Es un fondo de gestión activa.\n\n                El fondo obtuvo el sello de inversión sostenible belga Towards Sustainability, la acreditación de ASG francesa Label ISR, y el FNG Label. Forum Nachhaltige Geldanlagen (FNG-Siegel), fundada en 2001, es la asociación de la industria que promueve la inversión sostenible en Alemania, Austria y Suiza. El objetivo de FNG es mejorar los estándares de calidad para productos de inversión sostenibles, para asegurar el cumplimiento de las normas mínimas internacionalmente reconocidas.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/Pictet-KID.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU0503631631_YES_2020-12-31.pdf",
                asset_category: "Renta Variable Sector Ecológico",
                asset: "Renta Variable",
                portfolio_weight: "0.08",
              },
              portfolio_weight: 0.03,
              saving: 183.09090909090926,
              input: 183.09090909090926,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU0406803147",
                name: "BNP Paribas Funds Climate Impact",
                alias: "BNP Paribas Funds Climate Impact",
                description:
                  "El fondo trata de incrementar el valor de sus activos a medio plazo invirtiendo en acciones emitidas por empresas que operen centradas en permitir la adaptación al cambio climático o la mitigación de sus efectos (principalmente empresas europeas y estadounidenses de pequeña capitalización). Estas actividades incluyen, entre otras: energías renovables y alternativas, eficiencia energética, infraestructuras y tecnologías hidráulicas, control de la contaminación, tecnologías y tratamiento de residuos, servicios de apoyo medioambiental y alimentación sostenible.\n\n                Se gestiona de manera activa y, como tal, puede invertir en valores que no estén incluidos en el índice MSCI World Small Cap. El equipo de inversiones aplica asimismo la Política de inversión sostenible de BNP Paribas AM, que tiene en cuenta los criterios ambientales, sociales y de gobernanza (ASG) en las inversiones del fondo.\n                \n                El fondo reporta impacto positivo respecto a su benchmark en los ámbitos de emisión de carbono, generación de energías renovables, gestión del agua, y tratamiento de residuos.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/BNP-RV-Impact-KID_ES_es_LU0406803147_YES_2020-06-25.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU0406803147_YES_2020-12-31.pdf",
                asset_category: "Renta Variable Sector Ecológico",
                asset: "Renta Variable",
                portfolio_weight: "0.08",
              },
              portfolio_weight: 0.03,
              saving: 183.09090909090926,
              input: 183.09090909090926,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU1892830081",
                name: "Fidelity Sustainable Water & Waste Fund",
                alias: "Fidelity Sustainable Water & Waste Fund",
                description:
                  "El fondo busca invertir en empresas dedicadas al diseño, fabricación o venta de productos y servicios relacionados con el sector de la gestión de aguas y residuos presentes en todo el mundo. La estrategia cuenta con un entorno propicio gracias a megatendencias globales como el crecimiento demográfico, las restricciones medioambientales, una normativa favorable, las tasas de urbanización y la creación de riqueza mundial. El fondo incluye criterios sociales, medioambientales y de buen gobierno (ASG) en sus procesos de inversión y gestión de riesgos. Es un fondo de gestión activa.\n\n                El fondo obtuvo la acreditación de inversión sostenible begla Towards Sustainability, y el sello LuxFlag ESG. LuxFLAG es una organización sin ánimo de lucro internacional e independiente cuyo objetivo es promover la inversión sostenible. Reconoce aquellos fondos que incorporan el análisis de criterios y consideraciones medioambientales, sociales y de gobernanzas (criterios ESG por sus siglas en inglés – Environmental, Social, and Governance) en su proceso de inversión",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/Franklin-WW-KID_ES_es_LU1892830081_YES_2020-05-22.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_en_LU1892830081_YES_2020-12-31.pdf",
                asset_category: "Renta Variable Sector Água & Residuos",
                asset: "Renta Variable",
                portfolio_weight: "0.06",
              },
              portfolio_weight: 0.03,
              saving: 137.31818181818193,
              input: 137.31818181818193,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "IE00B0V9TC00",
                name: "PIMCO GIS Global High Yield Bond Fund",
                alias: "PIMCO Global High Yield Bond",
                description:
                  "El fondo trata de alcanzar su objetivo adquiriendo exposición a una gama diversificada de valores e instrumentos de renta fija de alta rentabilidad emitidos por empresas de todo el mundo. El fondo invertirá principalmente en valores «sin grado de inversión». Generalmente, se considera que estas inversiones son más arriesgadas que los valores con «grado de inversión», pero pueden generar ingresos más elevados. Es un fondo de gestión activa.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/09/KID_ES_es_IE00B2R34Y72_YES_2020-06-24.pdf",
                monthly_report: null,
                asset_category: "Renta Fija Corporativa Alto Rendimiento H I",
                asset: "Renta Fija",
                portfolio_weight: "0.14",
              },
              portfolio_weight: 0.04,
              saving: 191.0649999999999,
              input: 191.0649999999999,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU0097116601",
                name: "Eurizon Treasury USD",
                alias: "Eurizon Fund - Bond USD Short Term",
                description:
                  "Este fondo invierte en bonos gubernamentales de corto plazo que están denominados en dólares estadounidenses. Es un fondo de gestión activa.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/06/KID_ES_es_LU0335989397_YES_2020-02-18.pdf",
                monthly_report: null,
                asset_category: "Renta Fija USA Corto Plazo",
                asset: "Renta Fija",
                portfolio_weight: "0.05",
              },
              portfolio_weight: 0.01,
              saving: 68.23749999999997,
              input: 68.23749999999997,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "IE00B67T5G21",
                name: "iShares Euro Inv Grd Corp Bd Idx Fd (IE)",
                alias:
                  "iShares Euro Investment Grade Corporate Bond Index Fund (IE) Inst EUR",
                description:
                  "Este fondo invierte principalmente en bonos corporativos en euros con grado de inversión (Investment Grade) y con vencimientos de medio y largo plazo. Es un fondo indexado (gestión pasiva).",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/06/KID_ES_es_IE00B67T5G21_YES_2020-02-12.pdf",
                monthly_report: null,
                asset_category: "Renta Fija Corporativa Grado de Inversión",
                asset: "Renta Fija",
                portfolio_weight: "0.10",
              },
              portfolio_weight: 0.03,
              saving: 136.47499999999994,
              input: 136.47499999999994,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU0935221761",
                name: "Ostrum Euro Short Term Credit",
                alias: "OSTRUM Euro Short Term Credit",
                description:
                  "Este fondo invierte principalmente en bonos corporativos en euros con grado de inversión (Investment Grade) y con vencimientos de entre uno y tres años. Es un fondo de gestión activa.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2019/10/KID_ES_es_LU1118012159_YES_2020-02-19-OSTRUM.pdf",
                monthly_report: null,
                asset_category: "Renta Fija Corp. Euro IG Corto Plazo",
                asset: "Renta Fija",
                portfolio_weight: "0.11",
              },
              portfolio_weight: 0.03,
              saving: 150.12249999999992,
              input: 150.12249999999992,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "IE00B03HD191",
                name: "VANGUARD GLOBAL STOCK INDEX",
                alias: "Vanguard Global Stock Index",
                description:
                  "El objetivo del Fondo es replicar el rendimiento del índice MSCI World Index, para obtener el retorno total de los mercados de renta variable de los mercados desarrollados. El Índice está formado por acciones de medianas y grandes empresas. Es un fondo indexado (gestión pasiva).",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE00B03HD191_YES_2020-02-18-VGD-Global.pdf",
                monthly_report: null,
                asset_category: "Renta Variable mercados desarrollados",
                asset: "Renta Variable",
                portfolio_weight: "0.33",
              },
              portfolio_weight: 0.34,
              saving: 1801.4699999999993,
              input: 1801.4699999999993,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "IE0031786696",
                name: "VANGUARD EM MKTS STK INDX",
                alias: "Vanguard Emerging Markets Stock Index",
                description:
                  "El Fondo pretende replicar la rentabilidad del índice MSCI Emerging Markets Index. El Indice está formado por acciones de empresas de gran y mediana capitalización de mercados emergentes. Es un fondo indexado (gestión pasiva).",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE0031786696_YES_2020-02-18-VGD-EM.pdf",
                monthly_report: null,
                asset_category: "Renta Variable mercados emergentes",
                asset: "Renta Variable",
                portfolio_weight: "0.12",
              },
              portfolio_weight: 0.12,
              saving: 655.0799999999997,
              input: 655.0799999999997,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "IE00B42W4L06",
                name: "VANGUARD GB SM CAP IDX",
                alias: "Vanguard Global Small-Cap Index",
                description:
                  "El Fondo pretende replicar la rentabilidad del índice MSCI World Small Cap. El Indice está formado por acciones de empresas de pequeña y mediana capitalización de los países desarrollados. Es un fondo indexado (gestión pasiva).",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE00B42W4L06_YES_2020-02-18-VGD-SC.pdf",
                monthly_report: null,
                asset_category: "Renta Variable Small Caps",
                asset: "Renta Variable",
                portfolio_weight: "0.05",
              },
              portfolio_weight: 0.05,
              saving: 272.94999999999993,
              input: 272.94999999999993,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU1670632170",
                name: "M&G (Lux) Em Mkts Bd C H EUR Acc",
                alias: "M&G Emerging Markets Bond H",
                description:
                  "El objetivo principal del fondo es obtener ingresos de una cartera de obligaciones  y otros valores de deuda de mercados emergentes de todo el mundo. Es un fondo de gestión activa.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_LU1670632170_YES_2020-02-12-MG.pdf",
                monthly_report: null,
                asset_category: "Renta Fija Mercados Emergentes H C",
                asset: "Renta Fija",
                portfolio_weight: "0.10",
              },
              portfolio_weight: 0.03,
              saving: 136.47499999999994,
              input: 136.47499999999994,
              output: 0,
            },
          ],
        },
      ],
    },
    {
      objective: 7,
      services: [
        {
          financial_service: {
            type: "mutual",
            annual_percentage_rate: "0.0060",
            liquidity: "En cualquier momento",
            taxation_when_selling: "Base imponible del ahorro IRPF",
            tax_advantage: "Traspaso sin coste fiscal",
          },
          financial_products: [
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU1620157294",
                name: "BNP Paribas Funds Green Bond",
                alias: "BNP Paribas Green Bond",
                description:
                  "El fondo trata de incrementar el valor de sus activos a medio plazo invirtiendo en bonos verdes globales (Europa principalmente) que apoyen proyectos medioambientales, emitidos por organismos soberanos supranacionales y corporativos, entidades locales y/o gobiernos en divisas fuertes.\n\n                Se gestiona de manera activa y, como tal, puede invertir en valores que no estén incluidos en el índice Bloomberg Barclays MSCI Global Green Bond Index. El equipo de inversiones aplica asimismo la política de inversión sostenible de BNP Paribas AM, que tiene en cuenta los criterios ambientales, sociales y de gobernanza (ASG) en las inversiones del fondo.  El proceso de inversión filtra y excluye los bonos verdes con peor puntuación ASG, y procura evitar «green-washing» a través de una definición exigente del bono verde.\n                \n                El fondo obtuvo la acreditación ISR (inversión socialmente responsable) por parte del Ministerio de finanzas francés, y el sello GreenFin por parte del Ministerio francés de la Transición Ecológica y Solidária.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/BNP-Green-Bond-KID_ES_es_LU1620157294_YES_2020-02-19.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU1620157294_YES_2020-12-31.pdf",
                asset_category: "Renta Fija Diversificada",
                asset: "Renta Fija",
                portfolio_weight: "0.09",
              },
              portfolio_weight: 0.09,
              saving: 234.24485294117667,
              input: 234.24485294117667,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "IE00BD0DT792",
                name: "iShares Green Bond Index Fund",
                alias: "iShares Green Bond Index Fund",
                description:
                  "El fondo trata de obtener rentabilidad de su inversión mediante una combinación de revalorización del capital y rendimientos que refleje la rentabilidad del Bloomberg Barclays MSCI Green Bond Index, el índice de referencia del fondo (el Índice). El Fondo invierte principalmente en los valores de renta fija (RF) (como bonos) que componen el Índice, cuyos ingresos se utilizan para financiar proyectos con beneficios medioambientales directos como pueden ser por ejemplo la reducción de emisiones de CO2, el ahorro y tratamiento del agua, la preservación forestal, o la generación de energías renovables.\n\n                Es un fondo indexado (gestión pasiva).",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/iShares-Green-Bonds-KID_ES_es_IE00BD0DT792_YES_2020-02-12.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_IE00BD0DT792_YES_2020-11-30-1.pdf",
                asset_category: "Renta Fija Diversificada",
                asset: "Renta Fija",
                portfolio_weight: "0.05",
              },
              portfolio_weight: 0.05,
              saving: 130.13602941176487,
              input: 130.13602941176487,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU0966596875",
                name: "DPAM Bonds Emerging Markets Sustainable",
                alias: "DPAM Bonds Emerging Markets Sustainable",
                description:
                  "El fondo invierte principalmente en obligaciones y/u otros títulos de deuda denominados en cualquier divisa, emitidos (o garantizados) por países emergentes (lo que incluye sus administraciones públicas territoriales y sus organismos públicos —o asimilados—) o por organismos públicos internacionales (como el Banco Mundial y el Banco Europeo de Reconstrucción y Desarrollo) y seleccionados según criterios vinculados al desarrollo sostenible (como, por ejemplo, la igualdad social, el respeto por el medio ambiente y una gobernanza política y económica equitativa).\n\n                Se trata de un fondo gestionado activamente.\n                \n                El fondo obtuvo el sello de inversión sostenible belga Towards Sustainability y la acreditación LuxFLAG ESG. LuxFLAG es una organización sin ánimo de lucro internacional e independiente cuyo objetivo es promover la inversión sostenible. Reconoce aquellos fondos que incorporan el análisis de criterios y consideraciones medioambientales, sociales y de gobernanzas (ESG) en su proceso de inversión.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/DPAM-RF-EM-KID_ES_en_LU0966596875_YES_2020-02-19.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU0966596875_YES_2020-11-30.pdf",
                asset_category: "Renta Fija Países Emergentes",
                asset: "Renta Fija",
                portfolio_weight: "0.08",
              },
              portfolio_weight: 0.08,
              saving: 208.21764705882373,
              input: 208.21764705882373,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU1644441476",
                name: "Candriam SRI Bond Global High Yield",
                alias: "Candriam SRI Bond Global High Yield",
                description:
                  "Este fondo de renta fija invierte en bonos de alto rendimiento («High Yield») de empresas internacionales. Los valores se seleccionan en función de un proceso de análisis económico-financiero, así como de criterios medioambientales, sociales y de gobierno corporativo (ASG). El análisis ASG combina un enfoque «Best-in-Class» con un proceso de exclusión de los emisores que incumplan con los principios del pacto mundial de las Naciones Unidas en materia de derechos humanos y laborales, medio-ambiente y lucha contra la corrupción y/o que estén expuestos a actividades controvertidas (armamento, carbón térmico, tabaco etc…). El fondo reporta un impacto positivo en los siguientes ámbitos (respecto a su benchmark): reducción de consumo de agua y de emisión de carbono, producción de energías renovables, programas de biodiversidad, y disminución de la generación de residuos.\n\n                El fondo obtuvo el sello ISR (inversión socialmente responsable) por parte del Ministerio de finanzas francés y el sello Towards Sustainability por parte del gobierno belga.\n                \n                Es un fondo de gestión activa.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/Candriam-HY-KID_ES_es_LU1644441476_YES_2020-07-21.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/CandriamSustainableBondGlobalHighYield_Rep_R_R_20201231-1.pdf",
                asset_category: "Renta Fija Alto Rendimiento",
                asset: "Renta Fija",
                portfolio_weight: "0.12",
              },
              portfolio_weight: 0.12,
              saving: 312.3264705882356,
              input: 312.3264705882356,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU1434524259",
                name: "Candriam SRI Equity Emerging Markets",
                alias: "Candriam SRI Equity Emerging Markets",
                description:
                  "El fondo invierte en acciones de empresas de países emergentes que cumplen con los diez principios del pacto mundial de la ONU y que no están involucradas en actividades controvertidas como por ejemplo el comercio de armas. El fondo excluye de sus inversiones las empresas que no cumplan con los mínimos criterios ASG, y enfoca sus inversiones en empresas que están bien posicionadas para afrontar los retos de la sostenibilidad global.\n\n                Los impactos ASG más significativos reportados por el fondo se concentra en los ahorros de emisión de carbono y de consumo de agua.\n                \n                Es un fondo de gestión activa.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/Candriam-RV-EM-KID_ES_es_LU1434524259_YES_2020-07-06.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/CandriamSustainableEquityEmergingMarkets_Rep_R_R_20201231.pdf",
                asset_category: "Renta Variable Países Emergentes",
                asset: "Renta Variable",
                portfolio_weight: "0.20",
              },
              portfolio_weight: 0.01,
              saving: 23.318181818181838,
              input: 23.318181818181838,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU1437020909",
                name: "Amundi Index MSCI Global Climate Change",
                alias: "Amundi Index MSCI Global Climate Change",
                description:
                  "El fondo pretende replicar la rentabilidad del Índice MSCI World Climate Change Index. Este índice está basado en el MSCI World Index («Índice base»), que incluye valores de gran y mediana capitalización de 23 países considerados mercados desarrollados. El objetivo del Índice consiste en representar la rentabilidad de una estrategia en la que se re-ponderan los valores en función de las oportunidades y los riesgos asociados a la transición hacia un modelo económico de menor consumo de carbono, y se procura reducir al mínimo las exclusiones del Índice base.\n\n                Es un fondo indexado (gestión pasiva).",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/Amundi-RV-Climate-Change-MR_ES_es_LU1437020909_RES_2020-07-31.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU1437020909_RES_2020-12-31.pdf",
                asset_category: "Renta Variable Global",
                asset: "Renta Variable",
                portfolio_weight: "0.12",
              },
              portfolio_weight: 0.01,
              saving: 13.990909090909101,
              input: 13.990909090909101,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU0348927095",
                name: "Nordea Global Climate & Environment Fund",
                alias: "Nordea Global Climate & Environment Fund",
                description:
                  "El objetivo del fondo consiste en proporcionar a los accionistas un crecimiento de la inversión a largo plazo. Al gestionar activamente la cartera del fondo, el equipo de gestión se centra en aquellas empresas que desarrollan soluciones respetuosas con el medioambiente y el clima, como las energías renovables y la eficiencia de los recursos, y que parecen ofrecer mejores perspectivas de crecimiento y características de inversión. El fondo invierte principalmente en títulos de renta variable de empresas de todo el mundo. El fondo también sigue una política de exclusión de empresas de algunos sectores controvertidos cómo pueden ser el carbón térmico y metalúrgico, el petróleo y gas convencionales y no convencionales, el armamento militar y nuclear, las armas convencionales y controvertidas, y el tabaco.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/Nordea-KID_ES_es_LU0348927095_YES_2020-06-25.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU0348927095_YES_2020-12-31.pdf",
                asset_category: "Renta Variable Sector Ecológico",
                asset: "Renta Variable",
                portfolio_weight: "0.12",
              },
              portfolio_weight: 0.005181818181818185,
              saving: 13.990909090909101,
              input: 13.990909090909101,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU0503631631",
                name: "Pictet Global Environmental Opportunities",
                alias: "Pictet Global Environmental Opportunities",
                description:
                  "El fondo invierte a nivel global y principalmente en acciones de empresas de energía limpia y agua, agricultura, silvicultura y otras áreas de la cadena de valor medioambiental. Es un fondo de gestión activa.\n\n                El fondo obtuvo el sello de inversión sostenible belga Towards Sustainability, la acreditación de ASG francesa Label ISR, y el FNG Label. Forum Nachhaltige Geldanlagen (FNG-Siegel), fundada en 2001, es la asociación de la industria que promueve la inversión sostenible en Alemania, Austria y Suiza. El objetivo de FNG es mejorar los estándares de calidad para productos de inversión sostenibles, para asegurar el cumplimiento de las normas mínimas internacionalmente reconocidas.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/Pictet-KID.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU0503631631_YES_2020-12-31.pdf",
                asset_category: "Renta Variable Sector Ecológico",
                asset: "Renta Variable",
                portfolio_weight: "0.08",
              },
              portfolio_weight: 0.0034545454545454575,
              saving: 9.327272727272735,
              input: 9.327272727272735,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU0406803147",
                name: "BNP Paribas Funds Climate Impact",
                alias: "BNP Paribas Funds Climate Impact",
                description:
                  "El fondo trata de incrementar el valor de sus activos a medio plazo invirtiendo en acciones emitidas por empresas que operen centradas en permitir la adaptación al cambio climático o la mitigación de sus efectos (principalmente empresas europeas y estadounidenses de pequeña capitalización). Estas actividades incluyen, entre otras: energías renovables y alternativas, eficiencia energética, infraestructuras y tecnologías hidráulicas, control de la contaminación, tecnologías y tratamiento de residuos, servicios de apoyo medioambiental y alimentación sostenible.\n\n                Se gestiona de manera activa y, como tal, puede invertir en valores que no estén incluidos en el índice MSCI World Small Cap. El equipo de inversiones aplica asimismo la Política de inversión sostenible de BNP Paribas AM, que tiene en cuenta los criterios ambientales, sociales y de gobernanza (ASG) en las inversiones del fondo.\n                \n                El fondo reporta impacto positivo respecto a su benchmark en los ámbitos de emisión de carbono, generación de energías renovables, gestión del agua, y tratamiento de residuos.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/BNP-RV-Impact-KID_ES_es_LU0406803147_YES_2020-06-25.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_es_LU0406803147_YES_2020-12-31.pdf",
                asset_category: "Renta Variable Sector Ecológico",
                asset: "Renta Variable",
                portfolio_weight: "0.08",
              },
              portfolio_weight: 0.0034545454545454575,
              saving: 9.327272727272735,
              input: 9.327272727272735,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU1892830081",
                name: "Fidelity Sustainable Water & Waste Fund",
                alias: "Fidelity Sustainable Water & Waste Fund",
                description:
                  "El fondo busca invertir en empresas dedicadas al diseño, fabricación o venta de productos y servicios relacionados con el sector de la gestión de aguas y residuos presentes en todo el mundo. La estrategia cuenta con un entorno propicio gracias a megatendencias globales como el crecimiento demográfico, las restricciones medioambientales, una normativa favorable, las tasas de urbanización y la creación de riqueza mundial. El fondo incluye criterios sociales, medioambientales y de buen gobierno (ASG) en sus procesos de inversión y gestión de riesgos. Es un fondo de gestión activa.\n\n                El fondo obtuvo la acreditación de inversión sostenible begla Towards Sustainability, y el sello LuxFlag ESG. LuxFLAG es una organización sin ánimo de lucro internacional e independiente cuyo objetivo es promover la inversión sostenible. Reconoce aquellos fondos que incorporan el análisis de criterios y consideraciones medioambientales, sociales y de gobernanzas (criterios ESG por sus siglas en inglés – Environmental, Social, and Governance) en su proceso de inversión",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/Franklin-WW-KID_ES_es_LU1892830081_YES_2020-05-22.pdf",
                monthly_report:
                  "https://www.finanbest.com/wp-content/uploads/2020/08/MR_ES_en_LU1892830081_YES_2020-12-31.pdf",
                asset_category: "Renta Variable Sector Água & Residuos",
                asset: "Renta Variable",
                portfolio_weight: "0.06",
              },
              portfolio_weight: 0.0025909090909090925,
              saving: 6.9954545454545505,
              input: 6.9954545454545505,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "IE00B0V9TC00",
                name: "PIMCO GIS Global High Yield Bond Fund",
                alias: "PIMCO Global High Yield Bond",
                description:
                  "El fondo trata de alcanzar su objetivo adquiriendo exposición a una gama diversificada de valores e instrumentos de renta fija de alta rentabilidad emitidos por empresas de todo el mundo. El fondo invertirá principalmente en valores «sin grado de inversión». Generalmente, se considera que estas inversiones son más arriesgadas que los valores con «grado de inversión», pero pueden generar ingresos más elevados. Es un fondo de gestión activa.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/09/KID_ES_es_IE00B2R34Y72_YES_2020-06-24.pdf",
                monthly_report: null,
                asset_category: "Renta Fija Corporativa Alto Rendimiento H I",
                asset: "Renta Fija",
                portfolio_weight: "0.14",
              },
              portfolio_weight: 0.17,
              saving: 447.74099999999993,
              input: 447.74099999999993,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU0097116601",
                name: "Eurizon Treasury USD",
                alias: "Eurizon Fund - Bond USD Short Term",
                description:
                  "Este fondo invierte en bonos gubernamentales de corto plazo que están denominados en dólares estadounidenses. Es un fondo de gestión activa.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/06/KID_ES_es_LU0335989397_YES_2020-02-18.pdf",
                monthly_report: null,
                asset_category: "Renta Fija USA Corto Plazo",
                asset: "Renta Fija",
                portfolio_weight: "0.05",
              },
              portfolio_weight: 0.06,
              saving: 159.90749999999997,
              input: 159.90749999999997,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "IE00B67T5G21",
                name: "iShares Euro Inv Grd Corp Bd Idx Fd (IE)",
                alias:
                  "iShares Euro Investment Grade Corporate Bond Index Fund (IE) Inst EUR",
                description:
                  "Este fondo invierte principalmente en bonos corporativos en euros con grado de inversión (Investment Grade) y con vencimientos de medio y largo plazo. Es un fondo indexado (gestión pasiva).",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2020/06/KID_ES_es_IE00B67T5G21_YES_2020-02-12.pdf",
                monthly_report: null,
                asset_category: "Renta Fija Corporativa Grado de Inversión",
                asset: "Renta Fija",
                portfolio_weight: "0.10",
              },
              portfolio_weight: 0.12,
              saving: 319.81499999999994,
              input: 319.81499999999994,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU0935221761",
                name: "Ostrum Euro Short Term Credit",
                alias: "OSTRUM Euro Short Term Credit",
                description:
                  "Este fondo invierte principalmente en bonos corporativos en euros con grado de inversión (Investment Grade) y con vencimientos de entre uno y tres años. Es un fondo de gestión activa.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2019/10/KID_ES_es_LU1118012159_YES_2020-02-19-OSTRUM.pdf",
                monthly_report: null,
                asset_category: "Renta Fija Corp. Euro IG Corto Plazo",
                asset: "Renta Fija",
                portfolio_weight: "0.11",
              },
              portfolio_weight: 0.13,
              saving: 351.79649999999987,
              input: 351.79649999999987,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "IE00B03HD191",
                name: "VANGUARD GLOBAL STOCK INDEX",
                alias: "Vanguard Global Stock Index",
                description:
                  "El objetivo del Fondo es replicar el rendimiento del índice MSCI World Index, para obtener el retorno total de los mercados de renta variable de los mercados desarrollados. El Índice está formado por acciones de medianas y grandes empresas. Es un fondo indexado (gestión pasiva).",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE00B03HD191_YES_2020-02-18-VGD-Global.pdf",
                monthly_report: null,
                asset_category: "Renta Variable mercados desarrollados",
                asset: "Renta Variable",
                portfolio_weight: "0.33",
              },
              portfolio_weight: 0.03,
              saving: 91.77299999999998,
              input: 91.77299999999998,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "IE0031786696",
                name: "VANGUARD EM MKTS STK INDX",
                alias: "Vanguard Emerging Markets Stock Index",
                description:
                  "El Fondo pretende replicar la rentabilidad del índice MSCI Emerging Markets Index. El Indice está formado por acciones de empresas de gran y mediana capitalización de mercados emergentes. Es un fondo indexado (gestión pasiva).",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE0031786696_YES_2020-02-18-VGD-EM.pdf",
                monthly_report: null,
                asset_category: "Renta Variable mercados emergentes",
                asset: "Renta Variable",
                portfolio_weight: "0.12",
              },
              portfolio_weight: 0.01,
              saving: 33.371999999999986,
              input: 33.371999999999986,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "IE00B42W4L06",
                name: "VANGUARD GB SM CAP IDX",
                alias: "Vanguard Global Small-Cap Index",
                description:
                  "El Fondo pretende replicar la rentabilidad del índice MSCI World Small Cap. El Indice está formado por acciones de empresas de pequeña y mediana capitalización de los países desarrollados. Es un fondo indexado (gestión pasiva).",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 1,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_IE00B42W4L06_YES_2020-02-18-VGD-SC.pdf",
                monthly_report: null,
                asset_category: "Renta Variable Small Caps",
                asset: "Renta Variable",
                portfolio_weight: "0.05",
              },
              portfolio_weight: 0.0051499999999999975,
              saving: 13.904999999999994,
              input: 13.904999999999994,
              output: 0,
            },
            {
              financial_product: {
                _type: "InvestmentFund",
                provider: null,
                identifier: "LU1670632170",
                name: "M&G (Lux) Em Mkts Bd C H EUR Acc",
                alias: "M&G Emerging Markets Bond H",
                description:
                  "El objetivo principal del fondo es obtener ingresos de una cartera de obligaciones  y otros valores de deuda de mercados emergentes de todo el mundo. Es un fondo de gestión activa.",
                currency: "EUR",
                annual_percentage_rate: null,
                fees_and_comissions_specification: null,
                interest_rate: null,
                max_equity_exposure: 0,
                brochure:
                  "https://www.finanbest.com/wp-content/uploads/2019/01/KID_ES_es_LU1670632170_YES_2020-02-12-MG.pdf",
                monthly_report: null,
                asset_category: "Renta Fija Mercados Emergentes H C",
                asset: "Renta Fija",
                portfolio_weight: "0.10",
              },
              portfolio_weight: 0.12,
              saving: 319.81499999999994,
              input: 319.81499999999994,
              output: 0,
            },
          ],
        },
      ],
    },
  ],
  inputChart: [
    {
      id: 1,
      data: [
        {
          name: 1,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2022-05-02",
          date_end: "2023-04-02",
        },
        {
          name: 2,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2023-05-02",
          date_end: "2024-04-02",
        },
        {
          name: 3,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2024-05-02",
          date_end: "2025-04-02",
        },
        {
          name: 4,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2025-05-02",
          date_end: "2026-04-02",
        },
        {
          name: 5,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2026-05-02",
          date_end: "2027-04-02",
        },
        {
          name: 6,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2027-05-02",
          date_end: "2028-04-02",
        },
        {
          name: 7,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2028-05-02",
          date_end: "2029-04-02",
        },
        {
          name: 8,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2029-05-02",
          date_end: "2030-04-02",
        },
        {
          name: 9,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2030-05-02",
          date_end: "2031-04-02",
        },
        {
          name: 10,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2031-05-02",
          date_end: "2032-04-02",
        },
        {
          name: 11,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2032-05-02",
          date_end: "2033-04-02",
        },
        {
          name: 12,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2033-05-02",
          date_end: "2034-04-02",
        },
        {
          name: 13,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2034-05-02",
          date_end: "2035-04-02",
        },
        {
          name: 14,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2035-05-02",
          date_end: "2036-04-02",
        },
        {
          name: 15,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2036-05-02",
          date_end: "2037-04-02",
        },
        {
          name: 16,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2037-05-02",
          date_end: "2038-04-02",
        },
        {
          name: 17,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2038-05-02",
          date_end: "2039-04-02",
        },
        {
          name: 18,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2039-05-02",
          date_end: "2040-04-02",
        },
        {
          name: 19,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2040-05-02",
          date_end: "2041-04-02",
        },
        {
          name: 20,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2041-05-02",
          date_end: "2042-04-02",
        },
        {
          name: 21,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2042-05-02",
          date_end: "2043-04-02",
        },
        {
          name: 22,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2043-05-02",
          date_end: "2044-04-02",
        },
        {
          name: 23,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2044-05-02",
          date_end: "2045-04-02",
        },
        {
          name: 24,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2045-05-02",
          date_end: "2046-04-02",
        },
        {
          name: 25,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2046-05-02",
          date_end: "2047-04-02",
        },
        {
          name: 26,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2047-05-02",
          date_end: "2048-04-02",
        },
        {
          name: 27,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2048-05-02",
          date_end: "2049-04-02",
        },
        {
          name: 28,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2049-05-02",
          date_end: "2050-04-02",
        },
        {
          name: 29,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2050-05-02",
          date_end: "2051-04-02",
        },
        {
          name: 30,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2051-05-02",
          date_end: "2052-04-02",
        },
        {
          name: 31,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2052-05-02",
          date_end: "2053-04-02",
        },
        {
          name: 32,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2053-05-02",
          date_end: "2054-04-02",
        },
        {
          name: 33,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2054-05-02",
          date_end: "2055-04-02",
        },
        {
          name: 34,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2055-05-02",
          date_end: "2056-04-02",
        },
        {
          name: 35,
          input: 325.02366955945894,
          monthly_input: 27.08530579662157,
          date_init: "2056-05-02",
          date_end: "2057-04-02",
        },
        {
          name: 36,
          input: 162.51183477972944,
          monthly_input: 27.08530579662157,
          date_init: "2057-05-02",
          date_end: "2057-10-02",
        },
      ],
    },
    {
      id: 3,
      data: [
        {
          name: 1,
          input: 11509.1636482411,
          monthly_input: 959.0969706867584,
          date_init: "2022-05-02",
          date_end: "2023-04-02",
        },
      ],
    },
  ],
  outputChart: [
    {
      id: 1,
      data: {
        "2022": {
          name: 2022,
          output: 0,
        },
        "2023": {
          name: 2023,
          output: 0,
        },
        "2024": {
          name: 2024,
          output: 0,
        },
        "2025": {
          name: 2025,
          output: 0,
        },
        "2026": {
          name: 2026,
          output: 0,
        },
        "2027": {
          name: 2027,
          output: 0,
        },
        "2028": {
          name: 2028,
          output: 0,
        },
        "2029": {
          name: 2029,
          output: 0,
        },
        "2030": {
          name: 2030,
          output: 0,
        },
        "2031": {
          name: 2031,
          output: 0,
        },
        "2032": {
          name: 2032,
          output: 0,
        },
        "2033": {
          name: 2033,
          output: 0,
        },
        "2034": {
          name: 2034,
          output: 0,
        },
        "2035": {
          name: 2035,
          output: 0,
        },
        "2036": {
          name: 2036,
          output: 0,
        },
        "2037": {
          name: 2037,
          output: 0,
        },
        "2038": {
          name: 2038,
          output: 0,
        },
        "2039": {
          name: 2039,
          output: 0,
        },
        "2040": {
          name: 2040,
          output: 0,
        },
        "2041": {
          name: 2041,
          output: 0,
        },
        "2042": {
          name: 2042,
          output: 0,
        },
        "2043": {
          name: 2043,
          output: 0,
        },
        "2044": {
          name: 2044,
          output: 0,
        },
        "2045": {
          name: 2045,
          output: 0,
        },
        "2046": {
          name: 2046,
          output: 0,
        },
        "2047": {
          name: 2047,
          output: 0,
        },
        "2048": {
          name: 2048,
          output: 0,
        },
        "2049": {
          name: 2049,
          output: 0,
        },
        "2050": {
          name: 2050,
          output: 0,
        },
        "2051": {
          name: 2051,
          output: 0,
        },
        "2052": {
          name: 2052,
          output: 0,
        },
        "2053": {
          name: 2053,
          output: 0,
        },
        "2054": {
          name: 2054,
          output: 0,
        },
        "2055": {
          name: 2055,
          output: 0,
        },
        "2056": {
          name: 2056,
          output: 0,
        },
        "2057": {
          name: 2057,
          output: 280.8714038962523,
        },
        "2058": {
          name: 2058,
          output: 1702.0807076112883,
        },
        "2059": {
          name: 2059,
          output: 1727.6119182254577,
        },
        "2060": {
          name: 2060,
          output: 1753.5260969988394,
        },
        "2061": {
          name: 2061,
          output: 1779.8289884538226,
        },
        "2062": {
          name: 2062,
          output: 1806.526423280629,
        },
        "2063": {
          name: 2063,
          output: 1833.6243196298378,
        },
        "2064": {
          name: 2064,
          output: 1861.1286844242863,
        },
        "2065": {
          name: 2065,
          output: 1889.0456146906504,
        },
        "2066": {
          name: 2066,
          output: 1917.3812989110093,
        },
        "2067": {
          name: 2067,
          output: 1946.1420183946743,
        },
        "2068": {
          name: 2068,
          output: 1975.334148670595,
        },
        "2069": {
          name: 2069,
          output: 2004.9641609006528,
        },
        "2070": {
          name: 2070,
          output: 2035.0386233141628,
        },
        "2071": {
          name: 2071,
          output: 2065.5642026638748,
        },
        "2072": {
          name: 2072,
          output: 2096.547665703833,
        },
        "2073": {
          name: 2073,
          output: 2127.9958806893906,
        },
        "2074": {
          name: 2074,
          output: 2159.915818899731,
        },
        "2075": {
          name: 2075,
          output: 2192.314556183226,
        },
        "2076": {
          name: 2076,
          output: 2225.199274525975,
        },
        "2077": {
          name: 2077,
          output: 2258.577263643864,
        },
        "2078": {
          name: 2078,
          output: 2292.4559225985217,
        },
        "2079": {
          name: 2079,
          output: 2326.8427614374996,
        },
        "2080": {
          name: 2080,
          output: 2361.745402859062,
        },
        "2081": {
          name: 2081,
          output: 2397.171583901948,
        },
        "2082": {
          name: 2082,
          output: 2229.3646283679527,
        },
      },
    },
    {
      id: 3,
      data: {
        "2022": {
          name: 2022,
          output: 0,
        },
        "2023": {
          name: 2023,
          output: 3382.995,
        },
        "2024": {
          name: 2024,
          output: 3433.739924999999,
        },
        "2025": {
          name: 2025,
          output: 3485.2460238749986,
        },
        "2026": {
          name: 2026,
          output: 3537.5247142331236,
        },
        "2027": {
          name: 2027,
          output: 3590.58758494662,
        },
      },
    },
  ],
  cumulativeChart: [
    {
      id: 1,
      data: [
        {
          name: 1,
          input: 4625.0236695594585,
          performance: 158.4192059134976,
          date_init: "2022-05-02",
          date_end: "2023-04-02",
        },
        {
          name: 2,
          input: 4950.047339118917,
          performance: 333.9522896187375,
          date_init: "2023-05-02",
          date_end: "2024-04-02",
        },
        {
          name: 3,
          input: 5275.071008678376,
          performance: 527.2050823895488,
          date_init: "2024-05-02",
          date_end: "2025-04-02",
        },
        {
          name: 4,
          input: 5600.094678237834,
          performance: 738.8048619268504,
          date_init: "2025-05-02",
          date_end: "2026-04-02",
        },
        {
          name: 5,
          input: 5925.118347797293,
          performance: 969.4011115621788,
          date_init: "2026-05-02",
          date_end: "2027-04-02",
        },
        {
          name: 6,
          input: 6250.142017356751,
          performance: 1219.666306337002,
          date_init: "2027-05-02",
          date_end: "2028-04-02",
        },
        {
          name: 7,
          input: 6575.16568691621,
          performance: 1490.2967269092592,
          date_init: "2028-05-02",
          date_end: "2029-04-02",
        },
        {
          name: 8,
          input: 6900.189356475668,
          performance: 1782.0133022721786,
          date_init: "2029-05-02",
          date_end: "2030-04-02",
        },
        {
          name: 9,
          input: 7225.213026035127,
          performance: 2095.5624823053486,
          date_init: "2030-05-02",
          date_end: "2031-04-02",
        },
        {
          name: 10,
          input: 7550.236695594585,
          performance: 2431.7171412140974,
          date_init: "2031-05-02",
          date_end: "2032-04-02",
        },
        {
          name: 11,
          input: 7875.260365154044,
          performance: 2791.2775129506217,
          date_init: "2032-05-02",
          date_end: "2033-04-02",
        },
        {
          name: 12,
          input: 8200.284034713502,
          performance: 3175.072159749026,
          date_init: "2033-05-02",
          date_end: "2034-04-02",
        },
        {
          name: 13,
          input: 8525.307704272962,
          performance: 3583.9589749464967,
          date_init: "2034-05-02",
          date_end: "2035-04-02",
        },
        {
          name: 14,
          input: 8850.331373832421,
          performance: 4018.826221304363,
          date_init: "2035-05-02",
          date_end: "2036-04-02",
        },
        {
          name: 15,
          input: 9175.35504339188,
          performance: 4480.5936060856975,
          date_init: "2036-05-02",
          date_end: "2037-04-02",
        },
        {
          name: 16,
          input: 9500.37871295134,
          performance: 4970.2133941907,
          date_init: "2037-05-02",
          date_end: "2038-04-02",
        },
        {
          name: 17,
          input: 9825.4023825108,
          performance: 5488.671560697025,
          date_init: "2038-05-02",
          date_end: "2039-04-02",
        },
        {
          name: 18,
          input: 10150.42605207026,
          performance: 6036.988984200077,
          date_init: "2039-05-02",
          date_end: "2040-04-02",
        },
        {
          name: 19,
          input: 10475.449721629719,
          performance: 6616.222682397543,
          date_init: "2040-05-02",
          date_end: "2041-04-02",
        },
        {
          name: 20,
          input: 10800.473391189178,
          performance: 7227.467091413611,
          date_init: "2041-05-02",
          date_end: "2042-04-02",
        },
        {
          name: 21,
          input: 11125.497060748638,
          performance: 7871.855390411243,
          date_init: "2042-05-02",
          date_end: "2043-04-02",
        },
        {
          name: 22,
          input: 11450.520730308097,
          performance: 8550.560873095796,
          date_init: "2043-05-02",
          date_end: "2044-04-02",
        },
        {
          name: 23,
          input: 11775.544399867556,
          performance: 9264.79836776979,
          date_init: "2044-05-02",
          date_end: "2045-04-02",
        },
        {
          name: 24,
          input: 12100.568069427016,
          performance: 10015.825707657645,
          date_init: "2045-05-02",
          date_end: "2046-04-02",
        },
        {
          name: 25,
          input: 12425.591738986475,
          performance: 10804.945253279939,
          date_init: "2046-05-02",
          date_end: "2047-04-02",
        },
        {
          name: 26,
          input: 12750.615408545935,
          performance: 11633.505468719664,
          date_init: "2047-05-02",
          date_end: "2048-04-02",
        },
        {
          name: 27,
          input: 13075.639078105394,
          performance: 12502.902553688362,
          date_init: "2048-05-02",
          date_end: "2049-04-02",
        },
        {
          name: 28,
          input: 13400.662747664854,
          performance: 13414.58213336736,
          date_init: "2049-05-02",
          date_end: "2050-04-02",
        },
        {
          name: 29,
          input: 13725.686417224313,
          performance: 14370.041008069391,
          date_init: "2050-05-02",
          date_end: "2051-04-02",
        },
        {
          name: 30,
          input: 14050.710086783773,
          performance: 15370.828964838283,
          date_init: "2051-05-02",
          date_end: "2052-04-02",
        },
        {
          name: 31,
          input: 14375.733756343232,
          performance: 16418.550653179198,
          date_init: "2052-05-02",
          date_end: "2053-04-02",
        },
        {
          name: 32,
          input: 14700.757425902691,
          performance: 17514.867527189785,
          date_init: "2053-05-02",
          date_end: "2054-04-02",
        },
        {
          name: 33,
          input: 15025.781095462151,
          performance: 18661.499856442744,
          date_init: "2054-05-02",
          date_end: "2055-04-02",
        },
        {
          name: 34,
          input: 15350.80476502161,
          performance: 19860.228808053682,
          date_init: "2055-05-02",
          date_end: "2056-04-02",
        },
        {
          name: 35,
          input: 15675.82843458107,
          performance: 21112.898602454043,
          date_init: "2056-05-02",
          date_end: "2057-04-02",
        },
        {
          name: 36,
          input: 15838.340269360799,
          performance: 23337.651109710692,
          date_init: "2057-05-02",
          date_end: "2058-04-02",
        },
        {
          name: 37,
          input: 15838.340269360799,
          performance: 25528.808068724644,
          date_init: "2058-05-02",
          date_end: "2059-04-02",
        },
        {
          name: 38,
          input: 15838.340269360799,
          performance: 27559.056723107366,
          date_init: "2059-05-02",
          date_end: "2060-04-02",
        },
        {
          name: 39,
          input: 15838.340269360799,
          performance: 29434.833405044104,
          date_init: "2060-05-02",
          date_end: "2061-04-02",
        },
        {
          name: 40,
          input: 15838.340269360799,
          performance: 31162.57444672011,
          date_init: "2061-05-02",
          date_end: "2062-04-02",
        },
        {
          name: 41,
          input: 15838.340269360799,
          performance: 32748.71618032063,
          date_init: "2062-05-02",
          date_end: "2063-04-02",
        },
        {
          name: 42,
          input: 15838.340269360799,
          performance: 34199.69493803091,
          date_init: "2063-05-02",
          date_end: "2064-04-02",
        },
        {
          name: 43,
          input: 15838.340269360799,
          performance: 35521.94705203621,
          date_init: "2064-05-02",
          date_end: "2065-04-02",
        },
        {
          name: 44,
          input: 15838.340269360799,
          performance: 36721.908854521775,
          date_init: "2065-05-02",
          date_end: "2066-04-02",
        },
        {
          name: 45,
          input: 15838.340269360799,
          performance: 37806.01667767285,
          date_init: "2066-05-02",
          date_end: "2067-04-02",
        },
        {
          name: 46,
          input: 15838.340269360799,
          performance: 38780.70685367469,
          date_init: "2067-05-02",
          date_end: "2068-04-02",
        },
        {
          name: 47,
          input: 15838.340269360799,
          performance: 39652.415714712544,
          date_init: "2068-05-02",
          date_end: "2069-04-02",
        },
        {
          name: 48,
          input: 15838.340269360799,
          performance: 40427.57959297165,
          date_init: "2069-05-02",
          date_end: "2070-04-02",
        },
        {
          name: 49,
          input: 15838.340269360799,
          performance: 41112.63482063727,
          date_init: "2070-05-02",
          date_end: "2071-04-02",
        },
        {
          name: 50,
          input: 15838.340269360799,
          performance: 41714.01772989465,
          date_init: "2071-05-02",
          date_end: "2072-04-02",
        },
        {
          name: 51,
          input: 15838.340269360799,
          performance: 42238.16465292904,
          date_init: "2072-05-02",
          date_end: "2073-04-02",
        },
        {
          name: 52,
          input: 15838.340269360799,
          performance: 42676.47971657716,
          date_init: "2073-05-02",
          date_end: "2074-04-02",
        },
        {
          name: 53,
          input: 15838.340269360799,
          performance: 43033.688835837,
          date_init: "2074-05-02",
          date_end: "2075-04-02",
        },
        {
          name: 54,
          input: 15838.340269360799,
          performance: 43332.56924394426,
          date_init: "2075-05-02",
          date_end: "2076-04-02",
        },
        {
          name: 55,
          input: 15838.340269360799,
          performance: 43579.95899456952,
          date_init: "2076-05-02",
          date_end: "2077-04-02",
        },
        {
          name: 56,
          input: 15838.340269360799,
          performance: 43782.29441989804,
          date_init: "2077-05-02",
          date_end: "2078-04-02",
        },
        {
          name: 57,
          input: 15838.340269360799,
          performance: 43946.011852115065,
          date_init: "2078-05-02",
          date_end: "2079-04-02",
        },
        {
          name: 58,
          input: 15838.340269360799,
          performance: 44077.547623405844,
          date_init: "2079-05-02",
          date_end: "2080-04-02",
        },
        {
          name: 59,
          input: 15838.340269360799,
          performance: 44183.33806595562,
          date_init: "2080-05-02",
          date_end: "2081-04-02",
        },
        {
          name: 60,
          input: 15838.340269360799,
          performance: 44269.81951194966,
          date_init: "2081-05-02",
          date_end: "2082-04-02",
        },
        {
          name: 61,
          input: 15838.340269360799,
          performance: 44310.24433961562,
          date_init: "2082-05-02",
          date_end: "2083-04-02",
        },
      ],
    },
    {
      id: 3,
      data: [
        {
          name: 1,
          input: 14209.163648241101,
          performance: 107.0732295529906,
          date_init: "2022-05-02",
          date_end: "2023-04-02",
        },
        {
          name: 2,
          input: 14209.163648241101,
          performance: 1565.539092179028,
          date_init: "2023-05-02",
          date_end: "2024-04-02",
        },
        {
          name: 3,
          input: 14209.163648241101,
          performance: 2580.449864464028,
          date_init: "2024-05-02",
          date_end: "2025-04-02",
        },
        {
          name: 4,
          input: 14209.163648241101,
          performance: 3257.561124050481,
          date_init: "2025-05-02",
          date_end: "2026-04-02",
        },
        {
          name: 5,
          input: 14209.163648241101,
          performance: 3684.9857163716893,
          date_init: "2026-05-02",
          date_end: "2027-04-02",
        },
        {
          name: 6,
          input: 14209.163648241101,
          performance: 3967.639088964949,
          date_init: "2027-05-02",
          date_end: "2028-04-02",
        },
      ],
    },
  ],
  withoutInveertChart: [
    {
      id: 1,
      data: [
        {
          name: 1,
          input: 1712.556096618113,
          cumulative_input: 1712.556096618113,
          date_init: "2022-05-02",
          date_end: "2023-04-02",
        },
        {
          name: 2,
          input: 1712.556096618113,
          cumulative_input: 3425.112193236226,
          date_init: "2023-05-02",
          date_end: "2024-04-02",
        },
        {
          name: 3,
          input: 1712.556096618113,
          cumulative_input: 5137.668289854342,
          date_init: "2024-05-02",
          date_end: "2025-04-02",
        },
        {
          name: 4,
          input: 1712.556096618113,
          cumulative_input: 6850.2243864724605,
          date_init: "2025-05-02",
          date_end: "2026-04-02",
        },
        {
          name: 5,
          input: 1712.556096618113,
          cumulative_input: 8562.780483090577,
          date_init: "2026-05-02",
          date_end: "2027-04-02",
        },
        {
          name: 6,
          input: 1712.556096618113,
          cumulative_input: 10275.336579708684,
          date_init: "2027-05-02",
          date_end: "2028-04-02",
        },
        {
          name: 7,
          input: 1712.556096618113,
          cumulative_input: 11987.892676326792,
          date_init: "2028-05-02",
          date_end: "2029-04-02",
        },
        {
          name: 8,
          input: 1712.556096618113,
          cumulative_input: 13700.4487729449,
          date_init: "2029-05-02",
          date_end: "2030-04-02",
        },
        {
          name: 9,
          input: 1712.556096618113,
          cumulative_input: 15413.004869563007,
          date_init: "2030-05-02",
          date_end: "2031-04-02",
        },
        {
          name: 10,
          input: 1712.556096618113,
          cumulative_input: 17125.560966181114,
          date_init: "2031-05-02",
          date_end: "2032-04-02",
        },
        {
          name: 11,
          input: 1712.556096618113,
          cumulative_input: 18838.11706279922,
          date_init: "2032-05-02",
          date_end: "2033-04-02",
        },
        {
          name: 12,
          input: 1712.556096618113,
          cumulative_input: 20550.67315941733,
          date_init: "2033-05-02",
          date_end: "2034-04-02",
        },
        {
          name: 13,
          input: 1712.556096618113,
          cumulative_input: 22263.229256035436,
          date_init: "2034-05-02",
          date_end: "2035-04-02",
        },
        {
          name: 14,
          input: 1712.556096618113,
          cumulative_input: 23975.785352653544,
          date_init: "2035-05-02",
          date_end: "2036-04-02",
        },
        {
          name: 15,
          input: 1712.556096618113,
          cumulative_input: 25688.34144927165,
          date_init: "2036-05-02",
          date_end: "2037-04-02",
        },
        {
          name: 16,
          input: 1712.556096618113,
          cumulative_input: 27400.89754588976,
          date_init: "2037-05-02",
          date_end: "2038-04-02",
        },
        {
          name: 17,
          input: 1712.556096618113,
          cumulative_input: 29113.453642507866,
          date_init: "2038-05-02",
          date_end: "2039-04-02",
        },
        {
          name: 18,
          input: 1712.556096618113,
          cumulative_input: 30826.009739125973,
          date_init: "2039-05-02",
          date_end: "2040-04-02",
        },
        {
          name: 19,
          input: 1712.556096618113,
          cumulative_input: 32538.56583574408,
          date_init: "2040-05-02",
          date_end: "2041-04-02",
        },
        {
          name: 20,
          input: 1712.556096618113,
          cumulative_input: 34251.12193236223,
          date_init: "2041-05-02",
          date_end: "2042-04-02",
        },
        {
          name: 21,
          input: 1712.556096618113,
          cumulative_input: 35963.67802898038,
          date_init: "2042-05-02",
          date_end: "2043-04-02",
        },
        {
          name: 22,
          input: 1712.556096618113,
          cumulative_input: 37676.23412559853,
          date_init: "2043-05-02",
          date_end: "2044-04-02",
        },
        {
          name: 23,
          input: 1712.556096618113,
          cumulative_input: 39388.79022221668,
          date_init: "2044-05-02",
          date_end: "2045-04-02",
        },
        {
          name: 24,
          input: 1712.556096618113,
          cumulative_input: 41101.34631883483,
          date_init: "2045-05-02",
          date_end: "2046-04-02",
        },
        {
          name: 25,
          input: 1712.556096618113,
          cumulative_input: 42813.90241545298,
          date_init: "2046-05-02",
          date_end: "2047-04-02",
        },
        {
          name: 26,
          input: 1712.556096618113,
          cumulative_input: 44526.458512071134,
          date_init: "2047-05-02",
          date_end: "2048-04-02",
        },
        {
          name: 27,
          input: 1712.556096618113,
          cumulative_input: 46239.014608689286,
          date_init: "2048-05-02",
          date_end: "2049-04-02",
        },
        {
          name: 28,
          input: 1712.556096618113,
          cumulative_input: 47951.57070530744,
          date_init: "2049-05-02",
          date_end: "2050-04-02",
        },
        {
          name: 29,
          input: 1712.556096618113,
          cumulative_input: 49664.12680192559,
          date_init: "2050-05-02",
          date_end: "2051-04-02",
        },
        {
          name: 30,
          input: 1712.556096618113,
          cumulative_input: 51376.68289854374,
          date_init: "2051-05-02",
          date_end: "2052-04-02",
        },
        {
          name: 31,
          input: 1712.556096618113,
          cumulative_input: 53089.23899516189,
          date_init: "2052-05-02",
          date_end: "2053-04-02",
        },
        {
          name: 32,
          input: 1712.556096618113,
          cumulative_input: 54801.79509178004,
          date_init: "2053-05-02",
          date_end: "2054-04-02",
        },
        {
          name: 33,
          input: 1712.556096618113,
          cumulative_input: 56514.35118839819,
          date_init: "2054-05-02",
          date_end: "2055-04-02",
        },
        {
          name: 34,
          input: 1712.556096618113,
          cumulative_input: 58226.90728501634,
          date_init: "2055-05-02",
          date_end: "2056-04-02",
        },
        {
          name: 35,
          input: 1712.556096618113,
          cumulative_input: 59939.463381634494,
          date_init: "2056-05-02",
          date_end: "2057-04-02",
        },
        {
          name: 36,
          input: 856.2780483090565,
          cumulative_input: 60795.74142994357,
          date_init: "2057-05-02",
          date_end: "2057-10-02",
        },
      ],
    },
    {
      id: 3,
      data: [
        {
          name: 1,
          input: 18176.80273720605,
          cumulative_input: 18176.80273720605,
          date_init: "2022-05-02",
          date_end: "2023-04-02",
        },
      ],
    },
  ],
  equityChart: [
    {
      id: 9,
      data: {
        "2022": {
          name: 2022,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2023": {
          name: 2023,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2024": {
          name: 2024,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2025": {
          name: 2025,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2026": {
          name: 2026,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2027": {
          name: 2027,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2028": {
          name: 2028,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2029": {
          name: 2029,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2030": {
          name: 2030,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2031": {
          name: 2031,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2032": {
          name: 2032,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2033": {
          name: 2033,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2034": {
          name: 2034,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2035": {
          name: 2035,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2036": {
          name: 2036,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2037": {
          name: 2037,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2038": {
          name: 2038,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2039": {
          name: 2039,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2040": {
          name: 2040,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2041": {
          name: 2041,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2042": {
          name: 2042,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2043": {
          name: 2043,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2044": {
          name: 2044,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2045": {
          name: 2045,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2046": {
          name: 2046,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2047": {
          name: 2047,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2048": {
          name: 2048,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2049": {
          name: 2049,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2050": {
          name: 2050,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2051": {
          name: 2051,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2052": {
          name: 2052,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2053": {
          name: 2053,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2054": {
          name: 2054,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2055": {
          name: 2055,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2056": {
          name: 2056,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2057": {
          name: 2057,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2058": {
          name: 2058,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2059": {
          name: 2059,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2060": {
          name: 2060,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2061": {
          name: 2061,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2062": {
          name: 2062,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2063": {
          name: 2063,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2064": {
          name: 2064,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2065": {
          name: 2065,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2066": {
          name: 2066,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2067": {
          name: 2067,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2068": {
          name: 2068,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2069": {
          name: 2069,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2070": {
          name: 2070,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2071": {
          name: 2071,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2072": {
          name: 2072,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2073": {
          name: 2073,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2074": {
          name: 2074,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2075": {
          name: 2075,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2076": {
          name: 2076,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2077": {
          name: 2077,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2078": {
          name: 2078,
          equity: 0.8,
          fixed_income: 0.2,
        },
        "2079": {
          name: 2079,
          equity: 0.66,
          fixed_income: 0.34,
        },
        "2080": {
          name: 2080,
          equity: 0.5,
          fixed_income: 0.5,
        },
        "2081": {
          name: 2081,
          equity: 0.28,
          fixed_income: 0.72,
        },
        "2082": {
          name: 2082,
          equity: 0.08,
          fixed_income: 0.92,
        },
      },
    },
    {
      id: 7,
      data: {
        "2022": {
          name: 2022,
          equity: 0.08,
          fixed_income: 0.92,
        },
      },
    },
  ],
  userIncome: {
    total: 3000,
    type: null,
    business_income: [],
    pension_type: null,
    pension_income: [],
    partner_type: null,
    partner_pension_type: null,
    has_partner: null,
  },
  name: "Pablo",
  riskProfileMax: {
    identifier: "5",
    name: "atrevido",
    alias: "atrevido",
    max_equity_exposure: 0.8,
    main: true,
    is_active: true,
  },
  riskProfile: {
    identifier: "5",
    name: "atrevido",
    alias: "atrevido",
    max_equity_exposure: 0.8,
    main: true,
    is_active: true,
  },
  riskProfiles: [
    {
      identifier: "1",
      name: "conservador",
      alias: "conservador",
      max_equity_exposure: 0.08,
    },
    {
      identifier: "2",
      name: "tranquilo",
      alias: "tranquilo",
      max_equity_exposure: 0.28,
    },
    {
      identifier: "3",
      name: "equilibrado",
      alias: "equilibrado",
      max_equity_exposure: 0.5,
    },
    {
      identifier: "4",
      name: "decidido",
      alias: "decidido",
      max_equity_exposure: 0.66,
    },
    {
      identifier: "5",
      name: "atrevido",
      alias: "atrevido",
      max_equity_exposure: 0.8,
    },
    {
      identifier: "6",
      name: "super",
      alias: "super",
      max_equity_exposure: 1,
    },
  ],
  config: [
    {
      name: "FIXED_INCOME_PERFORMANCE",
      value: [
        1.31, 1.48, 1.53, 1.55, 1.56, 1.57, 1.58, 1.58, 1.59, 1.59, 1.59, 1.6,
        1.6, 1.6, 1.6, 1.6, 1.6, 1.6, 1.61, 1.61,
      ],
      description: "Rentabilidad que se aplica a la renta fija",
    },
    {
      name: "MONETARY_PERFORMANCE",
      value: 0.23,
      description: "Rentabilidad que se aplica a los activos monetarios",
    },
    {
      name: "DEPOSIT_PERFORMANCE",
      value: 0.3,
      description: "Rentabilidad que se aplica a los depósitos",
    },
    {
      name: "DEATH_AGE_MAN",
      value: 90,
      description: "Edad esperada de defunción para género masculino",
    },
    {
      name: "DEATH_AGE_WOMAN",
      value: 92,
      description: "Edad esperada de defunción para género femenino",
    },
    {
      name: "INCREMENT_OBJECTIVES",
      value: 0.016,
      description: "Tasa de incremento de ahorro de objetivos",
    },
    {
      name: "INFLATION",
      value: 0.015,
      description: "Tasa de inflación anual",
    },
    {
      name: "RECOMMENDATION_TYPE",
      value: "FIXED_SERVICES_PLAYABLE_PRODUCTS",
      description:
        "Tipo de recomendación de servicio que se dará después de la planificación",
    },
    {
      name: "MAX_PENSION_PLAN_CONTRIBUTION",
      value: 2000,
      description:
        "Contribución máxima anual a los servicios de planes de pensiones",
    },
    {
      name: "MIN_INVESTMENT_SERVICES",
      value: {
        mutual: 3000,
        deposit: 5000,
        pension: 30,
      },
      description:
        "Inversión mínima a realizar por cada uno de los servicios disponibles",
    },
    {
      name: "SAVING_FOR_PENSION",
      value: false,
      description:
        "Esta variable indica si se puede aportar a los planes de pensiones o si simplemente se puede realizar un traspaso y rentabilizar.",
    },
    {
      name: "PERCENTILE",
      value: 25,
      description:
        "Esta variable indica el percentil que se está usando para obtener las rentabilidades de la renta variable",
    },
    {
      name: "EQUITY_EXPECTED_PERFORMANCE",
      value: [
        -1.5, 1.1, 1.6, 0.7, 1, 1.8, 2.3, 2.9, 3.7, 3.9, 3.5, 3.9, 4.1, 4, 4.1,
        4.2, 4.3, 4.4, 4.5, 4.6,
      ],
      description: "Rentabilidad esperada de la renta variable",
    },
    {
      name: "EQUITY_MAX_PERFORMANCE",
      value: [
        70.6, 52.9, 40.6, 29.5, 31.6, 25.2, 24.5, 20.4, 17.5, 16.6, 15.7, 15.1,
        15.5, 14.6, 15.1, 15.2, 15, 14.7, 13.1, 13.1,
      ],
      description: "Rentabilidad máxima de la renta variable",
    },
    {
      name: "EQUITY_MIN_PERFORMANCE",
      value: [
        -51, -32, -19.4, -12.6, -8, -2.6, -5.4, -5.4, -7.6, -5.4, -3.9, -1.9,
        -0.8, 0.4, 0.8, 0.4, 1.2, 1.8, 1.3, 1.6,
      ],
      description: "Rentabilidad mínima de la renta variable",
    },
    {
      name: "CURRENCY",
      value: "EUR",
      description: "Moneda a utilizar en la plataforma",
    },
    {
      name: "CURRENCY_SYMBOL",
      value: "€",
      description: "Símbolo de la moneda a utilizar en la plataforma",
    },
    {
      name: "MEAN_PERFORMANCES_VECTOR",
      value: [
        0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016,
        0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016, 0.016,
      ],
      description:
        "Vector de rentabilidades media que nos proporciona la entidad",
    },
  ],
  userExpenses: {
    total: 2000,
    fixed_expenses: null,
    holidays: null,
    children_expenses: [],
  },
  document: "98061552V",
  email: "prueba+prueba@gmail.com",
  phone: 646333333,
};

export default propuestaMockedData;
