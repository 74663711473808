import Box from "@mui/material/Box";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { FormHelperText, InputLabel } from "@mui/material";
import React from "react";
import esLocale from "date-fns/locale/es";
import { color } from "../../styles/color";
import theme from "../../styles/theme";
import Text from "../../styles/textStyles";
import { StyledTextField } from "./NumberInput";
import WCIcons from "../icons/WCIcons";

interface IDateInput {
  value: number | Date | null;
  handleChange: (name: string, value: Date | null, validation: boolean) => void;
  formik: any;
  error?: string;
  name: string;
  label?: string;
  helperLabel?: string;
  focused?: boolean;
  fullDate?: boolean | "year" | "month";
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
}

function OpenPickerIconComponent() {
  const [isHover, setIsHover] = React.useState(false);
  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <WCIcons
        name="calendar"
        color={isHover ? color.blue[100] : color.greyscale.black[30]}
      />
    </div>
  );
}

function DateInput(props: IDateInput) {
  const {
    name,
    value,
    handleChange,
    formik,
    error,
    label,
    helperLabel,
    focused,
    fullDate,
    minDate,
    disabled,
    maxDate,
  } = props;
  let inputFormat;
  let mask;
  let views;
  let placeholderString: string;
  if (fullDate === true) {
    inputFormat = "dd/MM/yyyy";
    mask = "__/__/____";
    views = ["year", "month", "day"];
    placeholderString = "dd/mm/aaaa";
  } else if (fullDate === "year") {
    inputFormat = "yyyy";
    mask = "____";
    views = ["year"];
    placeholderString = "aaaa";
  } else if (fullDate === "month") {
    inputFormat = "dd/MM";
    mask = "__/__";
    views = ["month", "day"];
    placeholderString = "dd/mm";
  } else {
    inputFormat = "MM/yyyy";
    mask = "__/____";
    views = ["year", "month"];
    placeholderString = "mm/aaaa";
  }
  return (
    <Box sx={{ width: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
        <DatePicker
          OpenPickerButtonProps={{
            disableRipple: true,
            sx: {
              "&:hover": {
                background: "transparent",
                "&.MuiIconButton-root": {
                  color: `${color.blue[100]} !important`,
                },
              },
            },
          }}
          components={{
            OpenPickerIcon: OpenPickerIconComponent,
          }}
          className="datePicker"
          PopperProps={{
            placement: "bottom-start",
          }}
          disabled={disabled}
          minDate={minDate}
          maxDate={maxDate}
          inputRef={(input) =>
            focused === true ? input && input.focus() : null
          }
          disableMaskedInput={false}
          inputFormat={inputFormat}
          mask={mask}
          openTo="year"
          // @ts-ignore
          views={views}
          value={value}
          onChange={(newValue) => {
            // @ts-ignore
            handleChange(name, newValue, false).then(() =>
              formik.validateField(name)
            );
          }}
          renderInput={(params) => (
            <>
              <InputLabel
                sx={{
                  [theme.breakpoints.up("xs")]: {
                    marginBottom: theme.spacing(1),
                  },
                  [theme.breakpoints.up("xl")]: {
                    marginBottom: theme.spacing(2),
                  },
                }}
              >
                <Text
                  size="s"
                  weight="medium"
                  fontColor={color.greyscale.black[50]}
                >
                  {label}
                </Text>
              </InputLabel>
              <StyledTextField
                fullWidth
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                {...params}
                inputProps={{
                  ...params.inputProps,
                  placeholder: placeholderString,
                }}
                error={Boolean(formik.errors[name])}
              />
              <FormHelperText
                sx={{
                  [theme.breakpoints.up("xs")]: {
                    marginTop: theme.spacing(1),
                  },
                  [theme.breakpoints.up("xl")]: {
                    marginTop: theme.spacing(2),
                  },
                }}
              >
                <Text
                  size="xxs"
                  weight="regular"
                  fontColor={color.greyscale.black[40]}
                >
                  {helperLabel}
                </Text>
              </FormHelperText>
              {Boolean(formik.errors[name]) && (
                <FormHelperText
                  error
                  sx={{
                    [theme.breakpoints.up("xs")]: {
                      marginTop: theme.spacing(1),
                    },
                    [theme.breakpoints.up("xl")]: {
                      marginTop: theme.spacing(2),
                    },
                  }}
                >
                  <Text size="xs" weight="regular" fontColor="inherit">
                    {error}
                  </Text>
                </FormHelperText>
              )}
            </>
          )}
        />
      </LocalizationProvider>
    </Box>
  );
}

DateInput.defaultProps = {
  error: undefined,
  label: undefined,
  helperLabel: undefined,
  focused: undefined,
  fullDate: undefined,
  minDate: undefined,
  maxDate: undefined,
  disabled: undefined,
};

export default DateInput;
