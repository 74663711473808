const riskProfileDescription = [
  "Buscas la protección en momentos de volatilidad, sin renunciar a una rentabilidad positiva. Para ello vamos a recomendarte invertir hasta un 10% en Renta Variable, asumiendo un riesgo Bajo.",
  "Buscas la protección en momentos de volatilidad, sin renunciar a una rentabilidad positiva. Para ello vamos a recomendarte invertir hasta un 30% en Renta Variable, asumiendo un riesgo Medio-Bajo.",
  "Buscas una rentabilidad atractiva, mientras la volatilidad sea moderada. Para ello vamos a recomendarte invertir hasta un 50% en Renta Variable, asumiendo un riesgo Medio.",
  "Buscas una rentabilidad atractiva, mientras la volatilidad sea moderada. Para ello vamos a recomendarte invertir hasta un 70% en Renta Variable, asumiendo un riesgo Medio-Alto.",
  "Buscas obtener la máxima rentabilidad, incluso en momentos de volatilidad elevada. Para ello vamos a recomendarte invertir hasta un 85% en Renta Variable, asumiendo un riesgo Alto.",
  "Buscas obtener la máxima rentabilidad, incluso en momentos de volatilidad elevada. Para ello vamos a recomendarte invertir hasta un 100% en Renta Variable, asumiendo un riesgo Muy Alto.",
];

export default riskProfileDescription;
