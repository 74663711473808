import { Gender } from "../user/user.type";

export type ObjectiveType =
  | "RETIREMENT"
  | "EDUCATION"
  | "REAL_STATE"
  | "HERITAGE"
  | "CUSTOM"
  | "SAFETY_NET";
export type ProtectionType = "SHORT_TERM" | "LONG_TERM" | "CAPITAL";
export type TermType = "YEAR" | "AGE";
export type DurationType = "ANNUAL" | "MONTHLY";
export enum AmountType {
  UNIQUE = "UNIQUE",
  MONTHLY = "MONTHLY",
  ANNUAL = "ANNUAL",
}
export enum OutputType {
  UNIQUE = "UNIQUE",
  MONTHLY = "MONTHLY",
  ANNUAL = "ANNUAL",
}

export interface Objectives extends Array<Objective> {}

export interface Objective {
  type: ObjectiveType | null;
  output_type: OutputType | null;
  protection_type: ProtectionType | null;
  name: string | null;
  amount: number | null;
  amount_type: AmountType | null;
  term: number | null | undefined;
  term_type: TermType | null | undefined;
  duration: number | undefined | null;
  duration_type: DurationType | undefined | null;
  receiver: string | undefined | null;
  recipient: string | undefined | null;
  date_init: string | undefined | null; // yyyy-MM-dd
  date_end: string | undefined | null;
  is_active: boolean | undefined | null;
  extra_info: any | undefined | null;
  percentage_completed: number | undefined | null;
  probability: number | undefined | null;
  time_horizon: number | undefined | null;
  adjusted_objective?: number;
  id?: number;
  stops_saving?: boolean;
}

export interface ComplementSimulationRequiredData {
  age: number | null;
  current_children?: number;
  gender?: Gender;
  is_freelance: boolean | null;
  net_income: number | string | null;
  retirement_age: number;
  years_contributed: number | string | null;
}

export interface SimulatedRetirementIncomes
  extends Array<{
    retirement_age: number;
    retirement_income: number;
  }> {}

export interface SimulatedRetirementIncome {
  retirement_age: number;
  retirement_income: number;
}
