import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./styles/color.css";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import axios from "axios";
import App from "./App";
import { store } from "./store";
import theme from "./styles/theme";
import { setIsAuthDialogOpen } from "./modules/auth/auth.slice";

const persistor = persistStore(store);

// Axios config
axios.defaults.baseURL = `${process.env.REACT_APP_SERVER_BASE_URL}/api/`;
axios.defaults.withCredentials = true;
axios.defaults.headers = {
  // @ts-ignore
  Accept: "application/json",
  "Content-Type": "application/json",
  Language: "es",
};
axios.interceptors.request.use(
  (request) => request,
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(setIsAuthDialogOpen(true));
      return Promise.reject();
    }
    return Promise.reject(error);
  }
);

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "inv-button": any;
      "inv-icon": any;
      "inv-grid-row": any;
      "inv-grid-col": any;
      "inv-text-3xl": any;
      "inv-text-2xl": any;
      "inv-text-xl": any;
      "inv-text-l": any;
      "inv-text-m": any;
      "inv-text-s": any;
    }
  }
}

// @ts-ignore
ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
