import Box from "@mui/material/Box";
import { FormHelperText, InputLabel, Grid } from "@mui/material";
import React from "react";
import { color } from "../../styles/color";
import theme from "../../styles/theme";
import Text from "../../styles/textStyles";
import { StyledTextField } from "./NumberInput";
import WCIcons from "../icons/WCIcons";

interface ITextInput {
  value: string;
  handleChange: (
    name: string,
    value: string | null,
    validation: boolean
  ) => Promise<any>;
  formik: any;
  error?: string;
  name: string;
  label?: string;
  helperLabel?: string;
  placeholder?: string;
  focused?: boolean;
  disabled?: boolean;
  isPassword?: boolean;
}

function TextInput(props: ITextInput) {
  const {
    name,
    value,
    handleChange,
    formik,
    error,
    label,
    helperLabel,
    placeholder,
    focused,
    disabled,
    isPassword,
  } = props;
  const [isPasswordVisible, setIsPasswordVisible] = React.useState(isPassword);
  const [isHover, setIsHover] = React.useState(false);
  const EyeOnComponent = (
    <Grid
      item
      sx={{
        cursor: "pointer",
        height: 24,
        width: 25,
        color: color.greyscale.black[30],
        "&:hover": {
          color: color.greyscale.black[50],
        },
      }}
      onClick={() => setIsPasswordVisible(true)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <WCIcons
        name="eye-on"
        color={isHover ? color.greyscale.black[50] : color.greyscale.black[30]}
      />
    </Grid>
  );
  const EyeOffComponent = (
    <Grid
      item
      sx={{
        cursor: "pointer",
        height: 24,
        width: 25,
        color: color.greyscale.black[30],
        "&:hover": {
          color: color.greyscale.black[50],
        },
      }}
      onClick={() => setIsPasswordVisible(false)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <WCIcons
        name="eye-off"
        color={isHover ? color.greyscale.black[50] : color.greyscale.black[30]}
      />
    </Grid>
  );
  // @ts-ignore
  return (
    <Box sx={{ width: "100%" }}>
      <InputLabel
        sx={{
          [theme.breakpoints.up("xs")]: {
            marginBottom: theme.spacing(1),
          },
          [theme.breakpoints.up("xl")]: {
            marginBottom: theme.spacing(2),
          },
        }}
      >
        <Text size="s" weight="medium" fontColor={color.greyscale.black[50]}>
          {label}
        </Text>
      </InputLabel>
      <StyledTextField
        type={
          (isPassword && isPasswordVisible === true) === true
            ? "password"
            : undefined
        }
        disabled={disabled}
        fullWidth
        inputRef={(input: { focus: () => any }) =>
          focused === true ? input && input.focus() : null
        }
        placeholder={placeholder}
        id={name}
        name={name}
        value={value}
        onChange={(event) => {
          if (isPassword && name === "passwordConfirmation") {
            handleChange(name, event.target.value, false).then(() =>
              formik.validateField(name)
            );
          } else if (formik.touched[name] === true) {
            handleChange(name, event.target.value, false).then(() =>
              formik.validateField(name)
            );
          } else {
            handleChange(name, event.target.value, false).then(() => null);
          }
        }}
        error={Boolean(error)}
        /* eslint-disable-next-line react/jsx-no-duplicate-props */
        InputProps={{
          // eslint-disable-next-line
          endAdornment: isPassword
            ? isPasswordVisible === true
              ? EyeOffComponent
              : EyeOnComponent
            : undefined,
        }}
        onBlur={() =>
          formik
            .setFieldTouched(name, true, false)
            .then(() => formik.validateField(name))
        }
      />
      <FormHelperText
        sx={{
          [theme.breakpoints.up("xs")]: {
            marginTop: theme.spacing(1),
          },
          [theme.breakpoints.up("xl")]: {
            marginTop: theme.spacing(2),
          },
        }}
      >
        <Text size="xxs" weight="regular" fontColor={color.greyscale.black[40]}>
          {helperLabel}
        </Text>
      </FormHelperText>
      {Boolean(formik.errors[name]) && (
        <FormHelperText
          error
          sx={{
            [theme.breakpoints.up("xs")]: {
              marginTop: theme.spacing(1),
            },
            [theme.breakpoints.up("xl")]: {
              marginTop: theme.spacing(2),
            },
          }}
        >
          <Text size="xs" weight="regular" fontColor="inherit">
            {error}
          </Text>
        </FormHelperText>
      )}
    </Box>
  );
}

TextInput.defaultProps = {
  error: undefined,
  label: undefined,
  helperLabel: undefined,
  placeholder: undefined,
  focused: undefined,
  disabled: undefined,
  isPassword: undefined,
};

export default TextInput;
