import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { FamilySituation, Income, InitialInvestment, User } from "./user.type";
import { userService } from "./user.service";

export const initialState: User = {
  name: "",
  birthday: "",
  loading: false,
  expenses: {
    total: 0,
  },
  income: {
    total: 0,
  },
  financial_wealth: {
    initial_investment: 0,
    private_pension_plan: 0,
  },
  riskProfile: {
    profile_id: 1,
    max_profile_id: 1,
    external_profile_id: "",
  },
  riskProfileArray: [],
};

export const addData = createAsyncThunk(
  "user/addData",
  async (userInfo: {
    familySituation: FamilySituation;
    income: Income;
    userData: { email: string; document: string };
  }) =>
    userService(userInfo.familySituation, userInfo.income, userInfo.userData)
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setInitialInvestment(state, action: { payload: InitialInvestment }) {
      return { ...state, financial_wealth: action.payload };
    },
    setRiskProfile(state, action) {
      return { ...state, riskProfile: action.payload };
    },
    setUser(state: any, action) {
      return { ...state, ...action.payload };
    },
    resetUser() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addData.pending, (state) => {
      if (state.loading === false) {
        // eslint-disable-next-line no-param-reassign
        state.loading = true;
      }
    });

    builder.addCase(addData.rejected, (state) => {
      if (state.loading === true) {
        // eslint-disable-next-line no-param-reassign
        state.loading = true;
      }
    });

    // eslint-disable-next-line consistent-return
    builder.addCase(addData.fulfilled, (state, action) => {
      const { familySituation, income } = action.meta.arg;
      if (state.loading === true) {
        // @ts-ignore
        this.context.router("/meta").then();
        return {
          ...state,
          ...familySituation,
          loading: false,
          income: { ...income },
        };
      }
    });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state: any) => state.user;

export const { setInitialInvestment, setRiskProfile, setUser, resetUser } =
  userSlice.actions;

export default userSlice.reducer;
