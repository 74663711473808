import { FamilySituation, Income, InitialInvestment, Login } from "./user.type";
import { fetchApi } from "../../utils/functions";

export const userService = async (
  familySituation: FamilySituation,
  income: Income,
  userData: { email?: string; document?: string; zip_code?: string }
) => {
  const familySituationRes = fetchApi(
    "family-situation",
    "post",
    familySituation
  );
  const incomeRes = fetchApi("income", "post", income);
  const userDataRes = fetchApi("user", "put", userData);
  return Promise.all([familySituationRes, incomeRes, userDataRes]);
};

export const initialInvestmentUserApi = async (
  initialInvestment: InitialInvestment
) => {
  const initialInvestmentRes = fetchApi(
    "financial-wealth",
    "post",
    initialInvestment
  );
  return initialInvestmentRes;
};

export const userApiLogin = async (login: Login) => {
  const loginRes = fetchApi("auth/login", "post", login);
  return loginRes;
};

export const userApiRefreshToken = async () => {
  fetchApi("auth/login/refresh", "post", {});
};

export const setUserData = async (data: any) => {
  const userResponse = fetchApi("user", "put", data);
  return userResponse;
};

export const getPlanStatusOfUser = async () => {
  const userResponse = fetchApi("user/me/plan", "get", {});
  return userResponse;
};

export const getUserData = async () => {
  const familySituationRes = fetchApi("family-situation", "get", {});
  const incomeRes = fetchApi("income", "get", {});
  return Promise.all([familySituationRes, incomeRes]);
};

export const getIncome = async () => {
  const incomeRes = fetchApi("income", "get", {});
  return incomeRes;
};

export const getFamilySituation = async () => {
  const familyRes = fetchApi("family-situation", "get", {});
  return familyRes;
};

export const getInitialInvestment = async () => {
  const initialInvestmentRes = fetchApi("financial-wealth", "get", {});
  return initialInvestmentRes;
};
