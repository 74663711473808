import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { getYear } from "date-fns";
import SolidDividers from "../../elements/dividers/SolidDividers";
import { color } from "../../styles/color";
import Text from "../../styles/textStyles";
import WCIcons from "../../elements/icons/WCIcons";

// eslint-disable-next-line react/jsx-props-no-spreading
const StyledDividerGrid = styled((props: any) => <Grid item {...props} />)(
  ({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.up("xl")]: {
      marginBottom: theme.spacing(6),
    },
    width: "100%",
  })
);

export function FooterNavigation() {
  const location = useLocation();
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      sx={{
        maxWidth: 1439,
        marginTop: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        alignSelf: "flex-end",
        height: 120,
      }}
    >
      {location.pathname !== "/plan" && (
        <StyledDividerGrid>
          <SolidDividers orientation="horizontal" />
        </StyledDividerGrid>
      )}
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid
          item
          order={location.pathname === "/plan" ? 2 : undefined}
          sx={{
            color:
              location.pathname === "/plan"
                ? color.turquoise[100]
                : color.blue[100],
          }}
        >
          <img
            style={{ opacity: 0.8 }}
            alt="logo-grey"
            src="/assets/images/logos/logo-inveert-new-grey.png"
            width={160}
            height={24}
          />
        </Grid>
        <Grid
          item
          order={location.pathname === "/plan" ? 1 : undefined}
          sx={{ height: "fit-content" }}
        >
          <Text size="s" weight="regular" fontColor={color.greyscale.white.T40}>
            {`© Inveert Smart Planning S.L. ${getYear(
              new Date()
            )}. All Rights Reserved`}
          </Text>
        </Grid>
        {location.pathname === "/plan" && (
          <Grid
            item
            order={location.pathname === "/plan" ? 3 : undefined}
            sx={{ width: 366, height: "fit-content" }}
          >
            <Grid
              container
              alignItems="center"
              justifyContent="flex-end"
              columnSpacing={5}
              sx={{
                width: "auto",
              }}
            >
              <Grid item>
                <WCIcons name="social-instagram" height="24" width="24" />
              </Grid>
              <Grid item>
                <WCIcons name="social-facebook" height="24" width="24" />
              </Grid>
              <Grid item>
                <WCIcons name="social-twitter" height="24" width="24" />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default FooterNavigation;
