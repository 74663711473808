import { fetchApi } from "../../utils/functions";

export const authLoginUser = async (loginData: {
  email: string;
  password: string;
}) => {
  const response = fetchApi("auth/login", "post", loginData);
  return response;
};

export const authRegisterUser = async (
  registerData: {
    email: string;
    password: string;
    accept_terms: boolean;
    accept_communication: boolean;
    is_active: boolean;
  },
  referred_by?: string | string[] | null | any
) => {
  const response = fetchApi(
    `auth/signup${referred_by ? `?referred_by=${referred_by}` : ""}`,
    "post",
    registerData
  );
  return response;
};

export const authResendVerificationUser = async (email: string) => {
  const response = fetchApi("auth/email/resend", "post", { email });
  return response;
};

export const authSendChangePassUser = async (resendData: { email: string }) => {
  const response = fetchApi("auth/remember_password", "post", resendData);
  return response;
};

export const authIsValidChangePassUser = async (token: string) => {
  const response = fetchApi(`auth/restore-password/${token}`, "get", {});
  return response;
};

export const authChangePassUser = async (changePassData: {
  nuevaContraseña: string;
  uuid: any;
}) => {
  const response = fetchApi("auth/restore-password", "post", changePassData);
  return response;
};

export const authGetUser = async () => {
  const response = fetchApi("auth/user", "get", {});
  return response;
};
