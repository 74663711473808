import Grid from "@mui/material/Grid";
import * as React from "react";

import { Alert, Snackbar } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import TextInput from "../../elements/input/TextInput";
import Text from "../../styles/textStyles";
import { SocialSignButton } from "../../elements/buttons/SocialSignButton";
import { socialSignButtonsData } from "./auth.data";
import { color } from "../../styles/color";
import PageLoading from "../../elements/loading/PageLoading";
import { setIsAuthDialogOpen } from "./auth.slice";
import { reloadApi } from "../app/app.slice";
import { setUserDataLayer } from "../gtm/gtm.script";

interface IAuthLoginFormComponent {
  formik: any;
}

export function AuthLoginFormComponent(props: IAuthLoginFormComponent) {
  const location = useLocation();
  const dispatch = useDispatch();
  const router = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    window.addEventListener("message", (event) => {
      if (event.data.error?.message) {
        setLoading(false);
        setErrorMessage(event.data.error.message);
        setErrorOpen(true);
      } else if (event.data?.data?.token) {
        setUserDataLayer(
          event.data?.data?.email,
          undefined,
          undefined,
          undefined
        );
        if (location.pathname === "/login") {
          router("/");
        } else {
          dispatch(setIsAuthDialogOpen(false));
          dispatch(reloadApi());
        }
      }
    });
  }, []);
  const { formik } = props;
  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }

    setErrorOpen(false);
  };
  return (
    <>
      <PageLoading open={loading} />
      <Snackbar
        open={errorOpen}
        autoHideDuration={10000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={handleClose}
      >
        {/* @ts-ignore */}
        <Alert severity="error" sx={{ width: "100%" }} onClose={handleClose}>
          {errorMessage}
        </Alert>
      </Snackbar>
      {socialSignButtonsData.map((data) => (
        <Grid
          key={data.provider}
          sx={{
            marginTop: "12px",
          }}
        >
          <SocialSignButton
            href={data.href}
            text={data.text}
            provider={data.provider}
          />
        </Grid>
      ))}
      <Grid item>
        <Grid container alignItems="center">
          <Grid
            item
            xs={5}
            sx={{
              background: color.greyscale.black[30],
              height: "0.3px",
            }}
          />

          <Grid item xs={2}>
            <Text
              align="center"
              weight="semibold"
              size="s"
              fontColor={color.greyscale.black[50]}
            >
              o
            </Text>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              background: color.greyscale.black[30],
              height: "0.3px",
            }}
          />
        </Grid>
      </Grid>
      <Grid item>
        <TextInput
          value={formik.values.email ?? ""}
          handleChange={formik.setFieldValue}
          formik={formik}
          name="email"
          label="Correo electrónico"
          // @ts-ignore
          error={formik.errors.email}
          placeholder="luis@gmail.com"
        />
      </Grid>
      <Grid item>
        <TextInput
          isPassword
          value={formik.values.password ?? ""}
          handleChange={formik.setFieldValue}
          formik={formik}
          name="password"
          label="Contraseña"
          // @ts-ignore
          error={formik.errors.password}
          placeholder="*******"
        />
      </Grid>
    </>
  );
}

// @ts-ignore
export default AuthLoginFormComponent;
