import { configureStore, createAction } from "@reduxjs/toolkit";
// eslint-disable-next-line import/no-extraneous-dependencies
import { AnyAction, CombinedState, combineReducers } from "redux";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createMigrate,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer, {
  initialState as initialStateUser,
} from "./modules/user/user.slice";
import objectiveReducer, {
  initialState as initialStateGoal,
} from "./modules/goal/goal.slice";
import planReducer, {
  initialState as initialStatePlan,
} from "./modules/propuesta/propuesta.slice";
import authReducer, {
  initialState as initialStateAuth,
} from "./modules/auth/auth.slice";
import appReducer, {
  initialState as initialStateApp,
} from "./modules/app/app.slice";
import { Objectives } from "./modules/goal/goal.type";
import { User } from "./modules/user/user.type";
import { Plan } from "./modules/propuesta/propuesta.type";

const migrations = {
  0: (state: any) => ({
    ...state,
    user: initialStateUser,
    objectives: initialStateGoal,
    plan: initialStatePlan,
    auth: initialStateAuth,
    app: initialStateApp,
  }),
};

const persistConfig = {
  key: "root",
  storage,
  version: 0,
  migrate: createMigrate(migrations, { debug: false }),
};

const reducers = combineReducers({
  user: userReducer,
  objectives: objectiveReducer,
  plan: planReducer,
  auth: authReducer,
  app: appReducer,
});

const resetAction = createAction("reset");

const resettableReducer = (
  state:
    | CombinedState<{
        plan: Plan;
        user: User;
        objectives: {
          lastObjectiveId: number | null;
          lastObjectiveType: string | null;
          objectives: Objectives[];
        };
        auth: {
          isAuthDialogOpen: boolean;
        };
        app: {
          alert: { message: string; isError: boolean; isOpen: boolean };
          reloadApiFlag: boolean;
          alertFlag: boolean;
        };
      }>
    | undefined,
  action: AnyAction
) => {
  if (resetAction.match(action)) {
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, resettableReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_ENVIRONMENT !== "prod",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export default store;
