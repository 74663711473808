import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import { color } from "../../styles/color";
import { TotalsTextStructures } from "../../components/textEstructures/TotalsTextStructures";
import {
  getFinalInputChart,
  getLongestDataArray,
} from "../../modules/propuesta/propuesta.script";
import PageLoading from "../../elements/loading/PageLoading";
import { propuestaMockedData } from "../../modules/propuesta/propuesta.data";
import { goalColorByType } from "../../utils/componentData";
import BarsChart from "../../components/chart/BarsChart";
import { selectApp } from "../../modules/app/app.slice";
import resultadoGetClientSideProps from "../../modules/goal/goal.script";
import SolidDividers from "../../elements/dividers/SolidDividers";
import WithAndWithoutInveertCards from "../../elements/cards/WithAndWithoutInveertCards";
import styles from "../../styles/Resultado.module.css";

function Resultado() {
  const { reloadApiFlag } = useSelector(selectApp);
  const router = useNavigate();
  const dispatch = useDispatch();
  const [initialData, setInitialData] = React.useState(propuestaMockedData);
  const [loading, setLoading] = React.useState(true);
  const {
    saving,
    performance,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    adjusted_objective,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    initial_investment,
  } = initialData.plan.global_info;
  const { inputChart } = initialData;
  const { plan } = initialData.plan;
  const inputChartLongestDataArray = getLongestDataArray(inputChart);
  const finalInputChart = getFinalInputChart(
    inputChartLongestDataArray,
    inputChart,
    plan
  );
  // @ts-ignore
  const [secondChartLabelData, setSecondChartLabelData] = React.useState(
    plan.map(
      (objectivePlan: { monthly_saving: any }) => objectivePlan.monthly_saving
    )
  );
  // @ts-ignore
  const [secondChartTotalLabel, setSecondChartTotalLabel] = React.useState(
    finalInputChart[0].name
  );
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/grid/row.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/grid/col.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-3xl.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-2xl.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-xl.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-l.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/icon/index"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/text/text-m.js"), []);
  // @ts-ignore
  // eslint-disable-next-line import/extensions
  React.useEffect(() => import("@inveert/ui-kit/src/button/index"), []);
  React.useEffect(() => {
    setLoading(true);
    resultadoGetClientSideProps(
      setInitialData,
      router,
      setLoading,
      dispatch
    ).then(() => null);
  }, [reloadApiFlag]);
  React.useEffect(() => {
    setSecondChartLabelData(
      initialData.plan.plan.map(
        (objectivePlan: { monthly_saving: any }) => objectivePlan.monthly_saving
      )
    );
    setSecondChartTotalLabel(finalInputChart[0].name);
  }, [initialData]);
  return (
    <>
      <PageLoading open={loading} />
      <div className={styles.container}>
        <inv-grid-row style={{ justifyContent: "center" }}>
          <inv-grid-col class="col-8" style={{ display: "flex" }}>
            <inv-text-3xl
              style={
                {
                  "--inv-text-3xl-gradient": color.gradient.blue,
                  "--inv-text-3xl-font-weight": 600,
                  textAlign: "center",
                } as React.CSSProperties
              }
            >
              Tus metas con menos esfuerzo
            </inv-text-3xl>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ justifyContent: "center", marginTop: "2em" }}>
          <inv-grid-col class="col-8" style={{ display: "flex" }}>
            <inv-text-2xl
              style={
                {
                  textAlign: "center",
                  "--inv-text-2xl-color": color.greyscale.black[50],
                } as React.CSSProperties
              }
            >
              <NumberFormat
                value={initial_investment + saving + performance}
                thousandSeparator={process.env.REACT_APP_THOUSAND_SEPARATOR}
                decimalSeparator={process.env.REACT_APP_DECIMAL_SEPARATOR}
                decimalScale={0}
                allowNegative={false}
                displayType="text"
                suffix=" € que te faltan?"
                prefix="¿Cómo conseguir los "
              />
            </inv-text-2xl>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: "2.8em",
          }}
        >
          <WithAndWithoutInveertCards
            isSelected={false}
            title="Sólo ahorrando"
            inputMoney={adjusted_objective}
            inputPercentage={100}
            performanceMoney={0}
          />
          <inv-grid-col class="col-1">
            <div
              style={{
                display: "flex",
                margin: "0 auto",
                alignItems: "center",
                justifyContent: "center",
                background: "var(--primary-color)",
                borderRadius: "50%",
                width: "74px",
                height: "74px",
              }}
            >
              <inv-text-xl
                style={
                  {
                    "--inv-text-xl-color": color.greyscale.white[100],
                    textAlign: "center",
                  } as React.CSSProperties
                }
              >
                VS
              </inv-text-xl>
            </div>
          </inv-grid-col>
          <WithAndWithoutInveertCards
            isSelected
            title="Invirtiendo tus ahorros"
            inputMoney={saving + initial_investment}
            inputPercentage={
              ((saving + initial_investment) /
                (initial_investment + saving + performance)) *
              100
            }
            performanceMoney={performance}
          />
        </inv-grid-row>
        <inv-grid-row
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: "2.8em",
          }}
        >
          <inv-grid-col class="col-auto">
            <inv-text-l
              style={{
                "--inv-text-l-color": color.blue[105],
                "--inv-text-m-font-weight": 600,
              }}
            >
              Si inviertes tus ahorros
            </inv-text-l>
          </inv-grid-col>
          <inv-grid-col class="col-auto">
            <div
              style={{
                padding: "0.6em 1.6em",
                background: "rgba(0, 133, 255, 0.1)",
                borderRadius: "8px",
              }}
            >
              {/* TODO Plan tir is missing */}
              <inv-text-m
                style={{
                  "--inv-text-m-color": color.blue[100],
                  "--inv-text-m-font-weight": 700,
                }}
              >
                -% rentabilidad
              </inv-text-m>
            </div>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ justifyContent: "center", marginTop: "2em" }}>
          <inv-grid-col class="col-5" style={{ display: "flex" }}>
            <inv-text-2xl
              style={
                {
                  "--inv-text-2xl-font-weight": 700,
                  textAlign: "center",
                } as React.CSSProperties
              }
            >
              Aportarías mensualmente
            </inv-text-2xl>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row
          style={{
            justifyContent: "center",
            marginTop: "3.4em",
            marginBottom: "3.4em",
          }}
        >
          <inv-grid-col class="col-10">
            <inv-grid-row
              style={{ alignItems: "center", marginBottom: "3.4em" }}
            >
              <inv-grid-col class="col-auto">
                <TotalsTextStructures
                  size="medium"
                  items={[
                    {
                      title: "Aportación mensual",
                      quantitySuffix: "€/mes",
                      quantity: secondChartLabelData.reduce(
                        (acc: any, data: any) => data + acc,
                        0
                      ),
                      fontColor: color.greyscale.black[50],
                      quantityFontColor: color.greyscale.black[100],
                      helper: `Año ${secondChartTotalLabel}`,
                    },
                  ]}
                  fontColor={color.greyscale.black[50]}
                />
              </inv-grid-col>
              <inv-grid-col class="col-auto">
                <div style={{ height: "50px" }}>
                  <SolidDividers orientation="vertical" />
                </div>
              </inv-grid-col>
              {plan.map(
                (
                  objectivePlan: {
                    initial_investment: any;
                    objective: { type: string; name: string; id: number };
                  },
                  index: React.Key | null | undefined
                ) => (
                  <inv-grid-col
                    key={objectivePlan.objective.id}
                    class="col-auto"
                  >
                    <TotalsTextStructures
                      key={`text-structure-${objectivePlan.objective.id}`}
                      size="medium"
                      items={[
                        // @ts-ignore
                        {
                          title: objectivePlan.objective.name,
                          // @ts-ignore
                          quantity:
                            // @ts-ignore
                            secondChartLabelData[index] !== 0
                              ? // @ts-ignore
                                secondChartLabelData[index]
                              : "—",
                          // @ts-ignore
                          quantitySuffix:
                            // @ts-ignore
                            secondChartLabelData[index] !== 0 ? "€/mes" : "",
                          fontColor: color.greyscale.black[50],
                          // @ts-ignore
                          quantityFontColor:
                            // @ts-ignore
                            goalColorByType[objectivePlan.objective.type],
                        },
                      ]}
                      fontColor={color.greyscale.black[50]}
                    />
                  </inv-grid-col>
                )
              )}
            </inv-grid-row>
            <BarsChart
              name="monthly"
              // @ts-ignore
              barColors={{
                // @ts-ignore
                bar1: goalColorByType[plan[0].objective.type],
                bar2: plan[1]
                  ? // @ts-ignore
                    goalColorByType[plan[1].objective.type]
                  : undefined,
                bar3: plan[2]
                  ? // @ts-ignore
                    goalColorByType[plan[2].objective.type]
                  : undefined,
                bar4: plan[3]
                  ? // @ts-ignore
                    goalColorByType[plan[3].objective.type]
                  : undefined,
              }}
              data={finalInputChart}
              barsHandler={setSecondChartLabelData}
              hideXAxisTicks
              helperHandler={setSecondChartTotalLabel}
            />
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row>
          <inv-grid-col class="col-12">
            <div style={{ maxWidth: "calc(100% - var(--inv-gutter-x))" }}>
              <SolidDividers orientation="horizontal" />
            </div>
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row
          style={{ justifyContent: "flex-end", marginTop: "1.2em" }}
        >
          <inv-grid-col
            class="col-auto"
            style={{ justifyContent: "space-around", marginRight: "1.6em" }}
          >
            <inv-button onClick={() => router("/propuesta")}>
              Ver mi plan completo
            </inv-button>
          </inv-grid-col>
        </inv-grid-row>
      </div>
    </>
  );
}

Resultado.title = "Resultado de tu plan";
Resultado.layout = "EmptyLayout";
Resultado.mobileLayout = "MobileEmptyLayout";
export default Resultado;
