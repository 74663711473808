import { Grid, Link, useMediaQuery, Alert, Snackbar, Box } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";

import { format } from "date-fns";
import { es } from "date-fns/locale";
import StepsFragments from "../../elements/fragments/StepsFragments";
import { color } from "../../styles/color";
import theme from "../../styles/theme";
import { fetchApi, setIsAuthDialogOpenInStorage } from "../../utils/functions";
import PageLoading from "../../elements/loading/PageLoading";
import { planMenuElements } from "../../utils/componentData";
import Text from "../../styles/textStyles";
import {
  changeTabNamePlan,
  openNewPlanDialog,
  selectPlan,
} from "../../modules/propuesta/propuesta.slice";
import { selectAuth } from "../../modules/auth/auth.slice";
import { selectUser } from "../../modules/user/user.slice";
import { resetAlert, selectApp } from "../../modules/app/app.slice";
import WCIcons from "../../elements/icons/WCIcons";

import OnboardingMenu from "../../elements/menu/OnboardingMenu";

import PlanMenu from "../../elements/menu/PlanMenu";
import { AuthDialogComponent } from "../../modules/auth/auth-dialog.component";
import { RiskProfileDialogContent } from "../../modules/risk/risk-dialog.component";
import ContainerDialog from "../../elements/dialog/ContainerDialog";
import { PersonalDataDialogContent } from "../../modules/user/user-dialog.component";

export function OnboardingHeaderNavigation(props: any) {
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const dispatch = useDispatch();
  const { birthday, riskProfile, riskProfileArray } = useSelector(selectUser);
  const auth = useSelector(selectAuth);
  const { alert, alertFlag } = useSelector(selectApp);
  const { isAuthDialogOpen } = auth;
  const { name, financialWealth, userIncome, objectives } = props;
  const downXl = useMediaQuery(theme.breakpoints.down("xl"));
  const router = useNavigate();
  const location = useLocation();
  const showNavigation = !(
    location.pathname === "/" ||
    location.pathname === "/token" ||
    location.pathname === "/verificacion" ||
    location.pathname === "/restore-password" ||
    location.pathname === "/registro" ||
    location.pathname === "/login"
  );
  // @ts-ignore
  const hideHeader = !(
    // @ts-ignore
    (
      location?.pathname?.includes("meta") ||
      location.pathname?.includes("perfil") ||
      location.pathname?.includes("propuesta") ||
      location.pathname === "/planes-asesoramiento"
    )
  );

  const showTopMenu = !(
    location.pathname === "/" ||
    location.pathname === "/token" ||
    location.pathname === "/plan" ||
    location.pathname === "/verificacion" ||
    location.pathname === "/restore-password" ||
    location.pathname === "/registro" ||
    location.pathname === "/login" ||
    location.pathname === "/propuesta" ||
    location.pathname === "/planificacion-financiera" ||
    // @ts-ignore
    location.pathname.includes("contratacion")
  );
  const showProfilePicture = location.pathname === "/plan";
  const [alertObject, setAlertObject] = React.useState(alert);
  const [isElHover, setIsElHover] = React.useState(false);
  const [isEl1Hover, setIsEl1Hover] = React.useState(false);
  const [isEl2Hover, setIsEl2Hover] = React.useState(false);
  const [isEl3Hover, setIsEl3Hover] = React.useState(false);
  const [isRiskProfileDialogOpen, setIsRiskProfileDialogOpen] =
    React.useState(false);
  const [isPersonalDataDialogOpen, setIsPersonalDataDialogOpen] =
    React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { tabName } = useSelector(selectPlan);
  const changeTabName = (thisTabName: string) =>
    dispatch(changeTabNamePlan(thisTabName));
  const menuItems =
    location.pathname === "/propuesta"
      ? [
          {
            title: "Mis datos",
            startIcon: (
              <WCIcons
                name="profile-single"
                width="32"
                height="32"
                color={
                  isEl1Hover ? color.blue[100] : color.greyscale.white[100]
                }
              />
            ),
            onClick: () => setIsPersonalDataDialogOpen(true),
            setIsHover: setIsEl1Hover,
          },
          {
            title: "Perfil inversor",
            startIcon: (
              <WCIcons
                name="financial-freedom"
                width="32"
                height="32"
                color={
                  isEl2Hover ? color.blue[100] : color.greyscale.white[100]
                }
              />
            ),
            onClick: () => setIsRiskProfileDialogOpen(true),
            setIsHover: setIsEl2Hover,
          },
          {
            title: "Cerrar sesión",
            startIcon: (
              <WCIcons
                name="log-out"
                width="32"
                height="32"
                color={
                  isEl3Hover ? color.blue[100] : color.greyscale.white[100]
                }
              />
            ),
            setIsHover: setIsEl3Hover,
            onClick: () => {
              setLoading(true);
              dispatch({ type: "reset" });
              fetchApi("auth/logout", "get", undefined).finally(() => {
                router("/login");
              });
            },
          },
        ]
      : [
          {
            title: "Mis datos",
            startIcon: (
              <WCIcons
                name="profile-single"
                width="32"
                height="32"
                color={
                  isEl1Hover ? color.blue[100] : color.greyscale.white[100]
                }
              />
            ),
            onClick: () => setIsPersonalDataDialogOpen(true),
            setIsHover: setIsEl1Hover,
          },
          {
            title: "Cerrar sesión",
            startIcon: (
              <WCIcons
                name="log-out"
                width="32"
                height="32"
                color={
                  isEl2Hover ? color.blue[100] : color.greyscale.white[100]
                }
              />
            ),
            setIsHover: setIsEl2Hover,
            onClick: () => {
              setLoading(true);
              dispatch({ type: "reset" });
              fetchApi("auth/logout", "get", undefined).finally(() => {
                router("/login");
              });
            },
          },
        ];

  React.useEffect(() => {
    dispatch(resetAlert());
    setLoading(true);
    if (isAuthDialogOpen === true) {
      setIsAuthDialogOpenInStorage("true");
    } else {
      setIsAuthDialogOpenInStorage("false");
    }
    setLoading(false);
  }, []);
  React.useEffect(() => {
    setAlertObject(alert);
  }, [alertFlag]);
  React.useEffect(() => {
    setLoading(false);
  }, [location.pathname]);
  const handleClose = (event: any, reason: string) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(resetAlert());
  };
  // @ts-ignore
  // @ts-ignore
  return (
    <>
      {alertObject?.isOpen && (
        <Snackbar
          open
          autoHideDuration={
            location.pathname === "/propuesta" && !isAuthDialogOpen
              ? undefined
              : 10000
          }
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={
            location.pathname === "/propuesta" && !isAuthDialogOpen
              ? undefined
              : handleClose
          }
        >
          {/* @ts-ignore */}
          <Alert
            severity={alertObject?.isError ? "error" : "success"}
            sx={{ width: "100%" }}
            // @ts-ignore
            onClose={
              location.pathname === "/propuesta" && !isAuthDialogOpen
                ? undefined
                : handleClose
            }
          >
            {alertObject?.message}
          </Alert>
        </Snackbar>
      )}
      {alertObject?.isOpen &&
        alertObject?.message !== "El complemento no puede ser cero" &&
        location.pathname === "/propuesta" &&
        !isAuthDialogOpen && (
          <ContainerDialog
            open
            onClose={() => null}
            scroll="body"
            maxWidth="xs"
          >
            <Grid item sx={{ width: "100%" }}>
              <Grid container justifyContent="center">
                <Grid
                  item
                  xs={12}
                  sx={{
                    background: color.greyscale.white[100],
                    borderRadius: "32px",
                    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
                    padding: "40px",
                  }}
                >
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    rowSpacing={6}
                  >
                    <Grid
                      item
                      sx={{
                        color: color.validation.error,
                      }}
                    >
                      <WCIcons
                        name="message-failed"
                        viewBox="0 0 25 24"
                        height="40"
                        width="40"
                      />
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        rowSpacing={4}
                      >
                        <Grid item>
                          <Text
                            size={downMobile ? "m" : "xl"}
                            weight="semibold"
                            align="center"
                            fontColor={color.greyscale.black[100]}
                          >
                            Error al cargar
                          </Text>
                        </Grid>
                        <Grid item>
                          <Text
                            size={downMobile ? "xxs" : "xs"}
                            weight="regular"
                            align="center"
                            fontColor={color.greyscale.black[50]}
                          >
                            Se ha producido un problema al cargar la prouesta,
                            por favor, recargue la pagina de nuevo mas tarde
                          </Text>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item>
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                          <Link
                            onClick={() => router(0)}
                            sx={{
                              fontWeight: 700,
                              cursor: "pointer",
                              fontSize: downMobile ? 12 : 10,
                              textAlign: "center",
                            }}
                          >
                            Recargar la página
                          </Link>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ContainerDialog>
        )}
      <PageLoading open={loading} />
      <ContainerDialog
        scroll="body"
        open={isRiskProfileDialogOpen}
        onClose={() => setIsRiskProfileDialogOpen(false)}
        fullWidth
        maxWidth={downXl ? "md" : "lg"}
      >
        <RiskProfileDialogContent
          isSliderActive
          birthday={birthday}
          userIncome={userIncome}
          objectives={
            objectives
              ? objectives.map((objective: any) => objective)
              : undefined
          }
          financialWealth={financialWealth}
          isTestAvailable
          closeDialog={() => setIsRiskProfileDialogOpen(false)}
          profileNumber={riskProfile.profile_id}
          profileNumberMax={riskProfile.max_profile_id}
          riskProfileArray={riskProfileArray}
        />
      </ContainerDialog>
      <ContainerDialog
        scroll="body"
        open={isPersonalDataDialogOpen}
        onClose={() => setIsPersonalDataDialogOpen(false)}
      >
        <PersonalDataDialogContent
          closeDialog={() => setIsPersonalDataDialogOpen(false)}
        />
      </ContainerDialog>
      <ContainerDialog
        scroll="body"
        open={isAuthDialogOpen}
        onClose={() => null}
      >
        <AuthDialogComponent />
      </ContainerDialog>
      {location.pathname !== "/registro" && (
        <Grid
          id="header"
          item
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{
            height: downXl ? 100 : 120,
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5),
            "@media (max-width:950px)": {
              paddingLeft: 0,
              paddingRight: 0,
            },
            [theme.breakpoints.up("xl")]: {
              width: 2000,
              margin: "auto",
            },
          }}
        >
          <Grid
            item
            sx={{
              color:
                location.pathname === "/plan"
                  ? color.turquoise[100]
                  : color.blue[100],
            }}
          >
            <img
              alt="logo"
              src="/assets/images/logos/logo-small.svg"
              width={139}
              height={48}
            />
          </Grid>
          <Grid item>
            {location.pathname === "/plan" && (
              <PlanMenu
                elements={planMenuElements}
                selectedElement={tabName === "details" ? "goals" : tabName}
                onChangeTab={changeTabName}
              />
            )}
            {showTopMenu && hideHeader !== true && (
              <StepsFragments
                steps={[
                  "Mis metas",
                  "Aportación inicial",
                  "Perfil inversor",
                  "Resultado",
                ]}
                activeColor={color.greyscale.white[100]}
                inactiveColor={color.greyscale.white.T40}
                activeTextStepIconColor={color.blue[100]}
              />
            )}
            {location.pathname === "/propuesta" && (
              <Box sx={{ width: "100%" }}>
                <Grid container columnSpacing={3} sx={{ width: "auto" }}>
                  <Grid item>
                    <WCIcons
                      name="goals"
                      width="24"
                      height="24"
                      color={color.greyscale.white[100]}
                    />
                  </Grid>
                  <Grid item>
                    <Text
                      weight="regular"
                      size="l"
                      fontColor={color.greyscale.white[100]}
                    >
                      Planificación financiera
                    </Text>
                  </Grid>
                </Grid>
              </Box>
            )}
            {
              // @ts-ignore
              location.pathname.includes("contratacion") && (
                <StepsFragments
                  steps={[
                    "Información personal",
                    "Cumplimiento normativo",
                    "Contratos",
                  ]}
                  activeColor={color.greyscale.white[100]}
                  inactiveColor={color.greyscale.white.T40}
                  activeTextStepIconColor={color.blue[100]}
                />
              )
            }
          </Grid>
          <Grid
            item
            sx={{
              minWidth: 139,
            }}
          >
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              columnSpacing={3}
              sx={{
                width: "auto",
              }}
            >
              {location.pathname === "/plan" && (
                <Grid item>
                  <OnboardingMenu
                    title="Nuevo plan"
                    borderColor={color.turquoise[100]}
                    titleOnclick={() => dispatch(openNewPlanDialog())}
                  />
                </Grid>
              )}
              <Grid
                item
                onClick={() => {
                  if (
                    location.pathname !== "/propuesta" &&
                    location.pathname !== "/planes-asesoramiento"
                  ) {
                    router("/login");
                  }
                }}
              >
                {showNavigation && hideHeader !== true && (
                  <OnboardingMenu
                    setIsHover={(
                      value: boolean | ((prevState: boolean) => boolean)
                    ) => setIsElHover(value)}
                    icon={
                      location.pathname === "/propuesta" ||
                      location.pathname ===
                        "/planes-asesoramiento" ? undefined : (
                        <WCIcons
                          name="log-in"
                          width="24"
                          height="24"
                          color={
                            isElHover
                              ? color.blue[100]
                              : color.greyscale.white[100]
                          }
                        />
                      )
                    }
                    showProfilePicture={showProfilePicture}
                    title={
                      location.pathname === "/propuesta" ||
                      location.pathname === "/planes-asesoramiento"
                        ? name || ""
                        : "Login"
                    }
                    options={
                      location.pathname === "/propuesta" ||
                      location.pathname === "/planes-asesoramiento"
                        ? menuItems
                        : undefined
                    }
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          {location.pathname === "/plan" && (
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Text
                  size="s"
                  weight="light"
                  fontColor={color.greyscale.white.T40}
                >
                  {/* @ts-ignore */}
                  {`Datos actualizados: ${format(
                    new Date(),
                    /* @ts-ignore */
                    "dd 'de' MMMM 'de' yyyy",
                    /* @ts-ignore */
                    { locale: es }
                  )}`}
                </Text>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

export default OnboardingHeaderNavigation;
