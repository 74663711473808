import { Box, Grid, useMediaQuery } from "@mui/material";
import * as React from "react";
import { color } from "../../styles/color";
import theme from "../../styles/theme";
import Text from "../../styles/textStyles";
import OnboardMenu from "./OnboardingMenu";

interface IPlanMenu {
  elements: Array<{
    icon: any;
    title: string;
    name: "plan" | "goals" | "benefits" | "help" | "details";
  }>;
  selectedElement: "plan" | "goals" | "benefits" | "help" | "details";
  onChangeTab: (tabName: string) => void;
}
/*
  const menuDownMdPlan = [
  {
    startIcon: FinancialPlanIcon,
    title: "Plan financiero",
    name: "plan",
    onClick: null,
  },
  {
    startIcon: GoalIcon,
    title: "Metas",
    name: "goals",
    onClick: null,
  },
  {
    startIcon: BenefitsIcon,
    title: "Beneficios",
    name: "benefits",
    onClick: null,
  },
  {
    startIcon: HelpIcon,
    title: "Ayuda",
    name: "help",
    onClick: null,
  },
];
 */

function PlanMenu(props: IPlanMenu) {
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const { elements, selectedElement, onChangeTab } = props;
  /*
    const planMenuElements = menuDownMdPlan.map((item) => ({
    ...item,
    onClick: () => onChangeTab(item.name),
  }));
   */
  return (
    <Box sx={{ width: "100%" }}>
      <Grid
        container
        columnSpacing={8}
        sx={{
          width: "auto",
        }}
      >
        {!downMd && (
          <>
            {elements.map((element) => (
              <Grid
                key={element.name}
                item
                onClick={() => onChangeTab(element.name)}
              >
                <Grid
                  container
                  sx={{
                    color: color.greyscale.white[100],
                    cursor: "pointer",
                    "&:hover": {
                      color: color.turquoise[100],
                    },
                  }}
                >
                  <Grid
                    item
                    sx={{
                      color: "inherit",
                      marginRight: theme.spacing(2),
                    }}
                  >
                    {element.icon}
                  </Grid>
                  <Grid
                    item
                    sx={{
                      color: "inherit",
                      borderBottom:
                        selectedElement === element.name
                          ? `2px solid ${color.turquoise[100]}`
                          : undefined,
                    }}
                  >
                    <Text size="m" weight="semibold" fontColor="inherit">
                      {element.title}
                    </Text>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </>
        )}
        {downMd && (
          <Grid item>
            <OnboardMenu
              title="Secciones"
              borderColor={color.turquoise[100]}
              // options={planMenuElements}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default PlanMenu;
