import { fetchApi } from "../../utils/functions";
import { PropuestaStatus } from "../propuesta/propuesta.type";
import { setIsAuthDialogOpen } from "../auth/auth.slice";
import { setUser } from "../user/user.slice";
import { addAlert } from "../app/app.slice";

const resultadoGetClientSideProps = async (
  setInitialData: (data: any) => void,
  router: any,
  setLoading: (loading: boolean) => void,
  dispatch: any
) => {
  const resPlan = fetchApi("plans?global_info=1", "get", {});
  const resPlanId = fetchApi("user/me/plan", "get", {})
    .then(
      // eslint-disable-next-line consistent-return
      async (response) => {
        if (
          response.status === PropuestaStatus.COMPLETED ||
          process.env.REACT_APP_ENVIRONMENT !== "prod" ||
          response.status === PropuestaStatus.IN_PROGRESS
        ) {
          const chartInputs = fetchApi(
            `plans/${response.id}/graphs/inputs`,
            "get",
            {}
          );
          return Promise.all([chartInputs])
            .then((resPromises) => ({
              plan: response,
              chartInputs: resPromises[0],
            }))
            .catch((e) => Promise.reject(e));
        }
      }
    )
    .catch((e) => Promise.reject(e));
  const resRisk = fetchApi("risk-profile", "get", {});
  const resIncome = fetchApi("income", "get", {});
  const resFamily = fetchApi("family-situation", "get", {});
  const resFinancial = fetchApi("financial-wealth", "get", {});
  const resConfig = fetchApi("config", "get", {});
  const resExpenses = fetchApi("expenses", "get", {});
  const resDataRes = fetchApi("auth/user", "get", {});
  const resObjectives = fetchApi("objectives", "get", {});
  const resAllRisk = fetchApi("config/risk-profile", "get", {});
  Promise.all([
    resPlan,
    resPlanId,
    resRisk,
    resIncome,
    resFamily,
    resFinancial,
    resAllRisk,
    resConfig,
    resExpenses,
    resDataRes,
    resObjectives,
  ])
    .then((resFinal) => {
      const data = {
        inputChart: resFinal[1]?.chartInputs,
        plan: resFinal[0],
        objectives: resFinal[10],
        financialWealth: resFinal[5],
        user: resFinal[4],
        userIncome: resFinal[3],
        name: resFinal[4]?.name ?? "",
        riskProfileMax: resFinal[2].reduce(
          (mainProfile: any, profile: { main: boolean }) => {
            if (profile.main === true) {
              // eslint-disable-next-line no-param-reassign
              mainProfile = profile;
            }
            return mainProfile;
          },
          undefined
        ),
        riskProfile: resFinal[2].reduce(
          (mainProfile: any, profile: { is_active: boolean }) => {
            if (profile.is_active === true) {
              // eslint-disable-next-line no-param-reassign
              mainProfile = profile;
            }
            return mainProfile;
          },
          undefined
        ),
        riskProfiles: resFinal[6],
        config: resFinal[7],
        userExpenses: resFinal[8],
        document: resFinal[9]?.document ? resFinal[9]?.document : "",
        email: resFinal[9]?.email ? resFinal[9]?.email : "",
        phone: resFinal[9]?.phone ? resFinal[9]?.phone : null,
      };
      // @ts-ignore
      setInitialData(data);
      dispatch(setIsAuthDialogOpen(false));
      dispatch(
        setUser({
          name: data.name,
          birthday: data.user.birthday,
          income: { total: data.userIncome.total },
          financial_wealth: {
            private_pension_plan: data.financialWealth.private_pension_plan,
            initial_investment: data.financialWealth.initial_investment,
          },
          riskProfile: {
            profile_id: parseInt(data.riskProfile.identifier, 10),
            max_profile_id: parseInt(data.riskProfileMax.identifier, 10),
          },
          riskProfileArray: data.riskProfiles,
        })
      );
      setLoading(false);
    })
    .catch((e) => {
      if (e?.response?.data?.message) {
        dispatch(
          addAlert({
            message: e.response.data.message,
            isError: true,
            isOpen: true,
          })
        );
      }
      setLoading(false);
    });
};

export default resultadoGetClientSideProps;
