import * as yup from "yup";
import { format, parse, sub } from "date-fns";
import { useFormik } from "formik";
// @ts-ignore
import { useNavigate } from "react-router-dom";
import { userService } from "./user.service";

interface IuserFormik {
  name: string;
  birthday: string | null;
  monthly_income: number | null;
  zip_code: string;
  document?: string;
  closeDialog?: any;
  finish?: () => void;
}

export const UserFormik = (props: IuserFormik) => {
  const router = useNavigate();
  const {
    finish,
    name,
    birthday,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    monthly_income,
    // eslint-disable-next-line @typescript-eslint/naming-convention
    zip_code,
    closeDialog,
  } = props;
  const validationSchema = yup.object({
    name: yup.string().max(20).required("Introduce tu nombre"),
    birthday: yup
      .date()
      .typeError("La fecha no es valida")
      .nullable()
      .min(sub(Date.now(), { years: 90 }), "Debes tener menos de 90")
      .max(sub(Date.now(), { years: 18 }), "Debes ser mayor de edad")
      .required("Fecha de nacimiento requerida"),
    /* email: yup
      .string()
      .required("Introduce un email")
      .email("No es un email válido"), */
    /*
        document: yup.string()
            .required("Dinos tu DNI/NIE")
            .test('documentValid', 'Número de documento inválido',val=>validateDniNifNie(val)),

         */
    monthly_income: yup.number().required("Ingresos requeridos").nullable(),
    zip_code: yup
      .string()
      .required("Introduce tu código postal")
      .matches(/^(?:0?[1-9]|[1-4]\d|5[0-2])\d{3}$/, "Código postal inválido"),
  });
  return useFormik({
    initialValues: {
      name,
      birthday: parse(birthday as string, "dd/MM/yyyy", new Date()),
      monthly_income,
      zip_code,
      // document:document
    },
    validationSchema,
    onSubmit: closeDialog
      ? () => {
          closeDialog();
        }
      : (values, actions) => {
          // eslint-disable-next-line @typescript-eslint/no-shadow,@typescript-eslint/naming-convention
          const { name, birthday, zip_code } = values;
          const familySituation = {
            name,
            birthday: birthday ? format(birthday, "dd/MM/yyyy") : "",
            zip_code,
          };
          userService(
            familySituation,
            { total: values.monthly_income },
            {
              email: undefined,
              document: undefined,
            }
          )
            .then(() => {
              if (finish) {
                finish();
                actions.setSubmitting(false);
              } else {
                router("/meta");
              }
            })
            .catch(() => actions.setSubmitting(false));
        },
  });
};

export default UserFormik;
