import Grid from "@mui/material/Grid";
import * as React from "react";
import * as yup from "yup";
import { differenceInYears, format, parse, sub } from "date-fns";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
// @ts-ignore
import { useLocation, useNavigate } from "react-router-dom";
import { SwipeableDrawer, useMediaQuery } from "@mui/material";
import { color } from "../../styles/color";
import Text from "../../styles/textStyles";
import theme from "../../styles/theme";
import Buttons from "../../elements/buttons/Buttons";
import { selectUser, setRiskProfile } from "../../modules/user/user.slice";
import SolidDividers from "../../elements/dividers/SolidDividers";
import DotStepsFragment from "../../elements/fragments/DotStepsFragment";
import {
  profileFormQuestionMainTitle,
  profileFormQuestionTitle,
  profileQuestionNameOrder,
} from "../../utils/componentData";
import { RiskProfileForm } from "../../modules/risk/risk-form.component";
import { Objective } from "../../modules/goal/goal.type";
import { finanbestTestRiskProfileApi } from "../../modules/risk/risk.service";
// eslint-disable-next-line import/no-cycle
import { RiskProfileDialogContent } from "../../modules/risk/risk-dialog.component";
import { fetchApi } from "../../utils/functions";
import ContainerDialog from "../../elements/dialog/ContainerDialog";
import PageLoading from "../../elements/loading/PageLoading";
import WCIcons from "../../elements/icons/WCIcons";
import { PropuestaStatus } from "../../modules/propuesta/propuesta.type";
import {
  getFamilySituation,
  getIncome,
  getInitialInvestment,
  getPlanStatusOfUser,
} from "../../modules/user/user.service";
import { getObjectives } from "../../modules/goal/goal.service";
import { setIsAuthDialogOpen } from "../../modules/auth/auth.slice";
import { addAlert } from "../../modules/app/app.slice";
import { setUserDataLayer } from "../../modules/gtm/gtm.script";

function Perfil(props: any) {
  const iOS =
    typeof navigator !== "undefined" &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  const downMobile = useMediaQuery(theme.breakpoints.down("mobile"));
  const downXl = useMediaQuery(theme.breakpoints.down("xl"));
  const { finish, hideShowTest, goBack, closeDialog } = props;
  const [showDrawer, setShowDrawer] = React.useState(false);

  const dispatch = useDispatch();
  const router = useNavigate();
  const location = useLocation();
  const user = useSelector(selectUser);
  const [loading, setLoading] = React.useState(true);
  const [objectives, setObjectives] = React.useState([]);
  const [birthday, setBirthday] = React.useState("02/02/2002");
  const [financialWealth, setFinancialWealth] = React.useState({
    initial_investment: 3000,
    private_pension_plan: 0,
  });
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [monthly_income, setMonthlyIncome] = React.useState(2000);
  const [questionIndex, setQuestionIndex] = React.useState(0);
  const [hideBirthdayQuestion, setHideBirthdayQuestion] = React.useState(true);
  const [riskProfileArray, setRiskProfileArray] = React.useState<Array<any>>(
    []
  );
  const [isOpen, setIsOpen] = React.useState(false);
  const validationSchema = yup.object({
    stability: yup
      .number()
      .oneOf([1, 2, 3])
      .required("Tienes que introducir una opción"),
    expenses: yup.number().required("Necesitamos saber tus gastos"),
    wealth: yup
      .number()
      .nullable()
      .required("Tienes que introducir tu patrimonio financiero")
      .min(0, "La cantidad debe ser positiva")
      .max(9999999999999.99, "La cantidad excede el máximo permitido"),
    // retirement_age is term on RETIREMENT
    studies: yup
      .number()
      .oneOf([1, 2, 3])
      .required("Tienes que introducir una opción"),
    years_investing: yup
      .number()
      .oneOf([1, 2, 3, 4])
      .required("Tienes que introducir una opción"),
    experience: yup
      .number()
      .oneOf([1, 2])
      .required("Tienes que introducir una opción"),
    goal: yup
      .number()
      .oneOf([1, 2, 3])
      .required("Tienes que introducir una opción"),
    risk: yup
      .number()
      .oneOf([1, 2, 3, 4])
      .required("Tienes que introducir una opción"),
    attitude: yup
      .number()
      .oneOf([1, 2, 3, 4])
      .required("Tienes que introducir una opción"),
    name: yup.string().max(20).required("Introduce tu nombre"),
    birthday: yup
      .date()
      .typeError("La fecha no es valida")
      .nullable()
      .min(sub(Date.now(), { years: 90 }), "Debes tener menos de 90")
      .max(sub(Date.now(), { years: 18 }), "Debes ser mayor de edad")
      .required("Fecha de nacimiento requerida"),
    zip_code: yup
      .string()
      .required("Introduce tu código postal")
      .matches(/^(?:0?[1-9]|[1-4]\d|5[0-2])\d{3}$/, "Código postal inválido"),
    monthly_income: yup.number().required("Ingresos requeridos").nullable(),
    rent_income: yup.number().nullable(),
    other_income: yup.number().nullable(),
  });
  const formik = useFormik({
    initialValues: {
      stability: null,
      expenses: 75,
      wealth: null,
      studies: null,
      years_investing: null,
      experience: null,
      goal: null,
      risk: null,
      attitude: null,
      name: "",
      birthday: null,
      zip_code: "",
      monthly_income: null,
      rent_income: null,
      other_income: null,
    },
    validationSchema,
    onSubmit: (values, actions) => {
      const longerTimeHorizon = objectives.reduce(
        // eslint-disable-next-line @typescript-eslint/no-shadow
        (longerTimeHorizon: number, objective: Objective) => {
          if (
            (objective?.time_horizon ? objective.time_horizon : 0) >
            longerTimeHorizon
          ) {
            // eslint-disable-next-line no-param-reassign
            longerTimeHorizon = objective?.time_horizon
              ? objective.time_horizon
              : 0;
          }
          return longerTimeHorizon;
        },
        0
      );
      const annualGrossIncome = monthly_income * 12;
      const birthdayFinal = parse(birthday, "dd/MM/yyyy", new Date());
      const userAge = differenceInYears(new Date(), birthdayFinal);
      const totalInitialInvestment =
        financialWealth.private_pension_plan +
        financialWealth.initial_investment;
      const wealth = values.wealth === null ? 0 : values.wealth;
      const investment =
        (totalInitialInvestment /
          (values.wealth === null ? 0 : values.wealth)) *
        100;
      const answers = {
        stability: values.stability,
        studies: values.studies,
        years_investing: values.years_investing,
        experience: values.experience,
        goal: values.goal,
        risk: values.risk,
        attitude: values.attitude,
        // eslint-disable-next-line no-nested-ternary
        expenses: values.expenses < 25 ? 1 : values.expenses > 75 ? 3 : 2,
        age:
          // eslint-disable-next-line no-nested-ternary
          userAge < 35
            ? 1
            : // eslint-disable-next-line no-nested-ternary
            userAge > 67
            ? 4
            : userAge >= 35 && userAge < 55
            ? 2
            : 3,
        income:
          // eslint-disable-next-line no-nested-ternary
          annualGrossIncome < 50000
            ? 1
            : // eslint-disable-next-line no-nested-ternary
            annualGrossIncome > 600000
            ? 4
            : annualGrossIncome >= 50000 && annualGrossIncome <= 300000
            ? 2
            : 3,
        wealth:
          // eslint-disable-next-line no-nested-ternary
          wealth < 25000
            ? 1
            : // eslint-disable-next-line no-nested-ternary
            wealth > 100000
            ? 4
            : wealth >= 25000 && wealth <= 50000
            ? 2
            : 3,
        investment:
          // eslint-disable-next-line no-nested-ternary
          investment < 25
            ? 1
            : // eslint-disable-next-line no-nested-ternary
            investment > 75
            ? 4
            : investment >= 25 && investment <= 50
            ? 2
            : 3,
        horizon:
          // eslint-disable-next-line no-nested-ternary
          longerTimeHorizon < 3
            ? 1
            : // eslint-disable-next-line no-nested-ternary
            longerTimeHorizon > 10
            ? 4
            : longerTimeHorizon >= 3 && longerTimeHorizon <= 5
            ? 2
            : 3,
      };
      finanbestTestRiskProfileApi(answers)
        .then((response) => {
          setUserDataLayer(
            undefined,
            undefined,
            response.profile_id,
            undefined
          );
          dispatch(setRiskProfile(response));
          if (finish) {
            finish();
            formik.setSubmitting(false);
            hideShowTest();
          } else {
            fetchApi("config/risk-profile", "get", {})
              // eslint-disable-next-line @typescript-eslint/no-shadow
              .then((response) => {
                // @ts-ignore
                setRiskProfileArray(response);
                if (downMobile) {
                  setShowDrawer(true);
                } else {
                  setIsOpen(true);
                }
                actions.setSubmitting(false);
              })
              .catch((e) => {
                if (e?.response?.data?.message) {
                  dispatch(
                    addAlert({
                      message: e.response.data.message,
                      isError: true,
                      isOpen: true,
                    })
                  );
                }
                actions.setSubmitting(false);
              });
          }
        })
        .catch((e) => {
          if (e?.response?.data?.message) {
            dispatch(
              addAlert({
                message: e.response.data.message,
                isError: true,
                isOpen: true,
              })
            );
          }
          actions.setSubmitting(false);
        });
    },
  });
  React.useEffect(() => {
    setLoading(true);
    getPlanStatusOfUser()
      .then((res) => {
        if (
          res.status === PropuestaStatus.COMPLETED &&
          process.env.REACT_APP_ENVIRONMENT === "prod" &&
          location.pathname !== "/propuesta"
        ) {
          router("/propuesta");
        } else {
          const resObjectives = getObjectives();
          const resInitial = getInitialInvestment();
          const resIncome = getIncome();
          const resFamily = getFamilySituation();
          Promise.all([resObjectives, resInitial, resIncome, resFamily])
            .then((resFin) => {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              setObjectives(resFin[0]);
              setFinancialWealth({
                initial_investment: resFin[1].initial_investment,
                private_pension_plan: resFin[1].private_pension_plan,
              });
              setMonthlyIncome(resFin[2].total);
              setBirthday(resFin[3].birthday);
              formik.setFieldValue(
                "birthday",
                parse(resFin[3].birthday, "dd/MM/yyyy", new Date()),
                false
              );
              if (resFin[3].birthday) {
                setHideBirthdayQuestion(true);
              } else {
                setHideBirthdayQuestion(false);
              }
              formik.setFieldValue("zip_code", resFin[3].zip_code, false);
              formik.setFieldValue("name", resFin[3].name, false);
              dispatch(setIsAuthDialogOpen(false));
              setLoading(false);
            })
            .catch((e) => {
              if (e?.response?.data?.message) {
                dispatch(
                  addAlert({
                    message: e.response.data.message,
                    isError: true,
                    isOpen: true,
                  })
                );
              }
              setLoading(false);
            });
        }
      })
      .catch((e) => {
        if (e?.response?.data?.message) {
          dispatch(
            addAlert({
              message: e.response.data.message,
              isError: true,
              isOpen: true,
            })
          );
        }
        setLoading(false);
      });
  }, []);
  return (
    <>
      {closeDialog && !downMobile && (
        <Grid container justifyContent="flex-end">
          <Grid
            item
            sx={{ cursor: "pointer", height: downXl ? "30" : "40" }}
            onClick={closeDialog}
          >
            <WCIcons
              name="close-filled"
              height={downXl ? "30" : "40"}
              width={downXl ? "30" : "40"}
            />
          </Grid>
        </Grid>
      )}
      <PageLoading open={formik.isSubmitting || loading} />
      {downMobile ? (
        <SwipeableDrawer
          sx={{
            ".MuiDrawer-paper": {
              // 31 px are the half of the 62px height of mobile header
              maxHeight: "calc(100vh - 101px)",
              borderRadius: "24px 24px 0px 0px",
              padding: theme.spacing(8),
            },
          }}
          anchor="bottom"
          disableSwipeToOpen
          open={showDrawer}
          disableBackdropTransition={!iOS}
          disableDiscovery={iOS}
          onClose={() => setShowDrawer(false)}
          onOpen={() => setShowDrawer(true)}
        >
          <RiskProfileDialogContent
            profileNumberMax={user?.riskProfile?.profile_id}
            profileNumber={user?.riskProfile?.profile_id}
            riskProfileArray={riskProfileArray}
          />
        </SwipeableDrawer>
      ) : (
        <ContainerDialog
          open={isOpen}
          onClose={() => setIsOpen(false)}
          fullWidth
          maxWidth={downXl ? "sm" : "md"}
          scroll="body"
        >
          <RiskProfileDialogContent
            profileNumberMax={user?.riskProfile?.profile_id}
            profileNumber={user?.riskProfile?.profile_id}
            riskProfileArray={riskProfileArray}
          />
        </ContainerDialog>
      )}
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        rowSpacing={downMobile ? theme.spacing(8) : theme.spacing(15)}
      >
        {/* Need to wrap containers on item to apply maxwidth */}
        <Grid item>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            sx={{
              [theme.breakpoints.up("xs")]: {
                maxWidth: 540,
              },
              [theme.breakpoints.up("xl")]: {
                maxWidth: 690,
              },
            }}
            rowSpacing={{ xs: theme.spacing(4), xl: theme.spacing(4) }}
          >
            <Grid item>
              <Text
                // eslint-disable-next-line no-nested-ternary
                size={downMobile ? "l" : downXl ? "2xl" : "3xl"}
                weight="semibold"
                gradient={color.gradient.blue}
              >
                {profileFormQuestionMainTitle[questionIndex]}
              </Text>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ marginTop: "-32px" }}>
          <Grid
            container
            item
            direction="column"
            rowSpacing={
              // eslint-disable-next-line no-nested-ternary
              downMobile
                ? theme.spacing(8)
                : downXl
                ? theme.spacing(14)
                : theme.spacing(20)
            }
            justifyContent="center"
            sx={{
              [theme.breakpoints.up("xs")]: {
                maxWidth: 800,
              },
              [theme.breakpoints.up("xl")]: {
                maxWidth: 1060,
              },
            }}
          >
            <Grid item>
              {
                profileFormQuestionTitle(
                  (formik.values.monthly_income ?? 0) +
                    (formik.values.rent_income ?? 0) +
                    (formik.values.other_income ?? 0),
                  downMobile
                )[questionIndex]
              }
            </Grid>
            <Grid item>
              <RiskProfileForm
                monthly_income={monthly_income}
                setQuestionIndex={setQuestionIndex}
                formik={formik}
                questionIndex={questionIndex}
                hideBirthdayQuestion={hideBirthdayQuestion}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          sx={{
            width: "inherit",
          }}
        >
          <Grid container direction="column" rowSpacing={theme.spacing(5)}>
            {!downMobile && (
              <Grid
                item
                sx={{
                  [theme.breakpoints.up("xs")]: {
                    width: `calc(100% + ${
                      closeDialog ? theme.spacing(9) : theme.spacing(12)
                    })`,
                    marginLeft: `-${theme.spacing(6)}`,
                  },
                  [theme.breakpoints.up("xl")]: {
                    width: `calc(100% + ${
                      closeDialog ? theme.spacing(18) : theme.spacing(24)
                    })`,
                    marginLeft: `-${theme.spacing(12)}`,
                  },
                }}
              >
                <SolidDividers orientation="horizontal" />
              </Grid>
            )}
            <Grid
              item
              container
              justifyContent="space-between"
              alignItems="center"
              direction={downMobile ? "column" : "row"}
              rowSpacing={downMobile ? 4 : 0}
            >
              <Grid
                item
                sx={{
                  cursor: "pointer",
                  /* Size of continuar button to center steps */
                  // eslint-disable-next-line no-nested-ternary
                  width: downMobile ? "fit-content" : downXl ? 140.66 : 162.38,
                }}
                order={{ xs: downMobile ? 3 : 1 }}
              >
                <Buttons
                  size={downMobile ? "medium" : undefined}
                  onClick={() => {
                    if (questionIndex === 0) {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      goBack ? goBack() : router("/perfil/inicio");
                    } else {
                      setQuestionIndex(questionIndex - 1);
                    }
                  }}
                  hoverBackground={color.greyscale.black[10]}
                  variant="text"
                  fontColor={color.greyscale.black[50]}
                >
                  Atrás
                </Buttons>
              </Grid>
              <Grid
                item
                justifySelf="center"
                order={{ xs: downMobile ? 1 : 2 }}
                sx={{
                  paddingBottom: downMobile ? theme.spacing(4) : 0,
                }}
              >
                <DotStepsFragment steps={11} activeStep={questionIndex} />
              </Grid>
              <Grid
                item
                order={{ xs: downMobile ? 2 : 3 }}
                sx={{
                  width: downMobile ? "100%" : "initial",
                }}
              >
                <Buttons
                  size={downMobile ? "medium" : undefined}
                  variant="contained"
                  onClick={() => {
                    if (questionIndex === 0) {
                      formik.validateForm(formik.values).then((err: any) => {
                        if (
                          err?.birthday === undefined &&
                          err?.name === undefined &&
                          err?.zip_code === undefined
                        ) {
                          formik.setSubmitting(true);
                          // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-shadow
                          const { birthday, name, zip_code } = formik.values;

                          const userAge = differenceInYears(
                            new Date(),
                            birthday ?? 0
                          );
                          setUserDataLayer(
                            undefined,
                            userAge,
                            undefined,
                            undefined
                          );

                          const finalBirthday = birthday
                            ? format(birthday, "dd/MM/yyyy")
                            : "";
                          fetchApi("family-situation", "post", {
                            birthday: finalBirthday,
                            name,
                            zip_code,
                          })
                            .then(() => {
                              setQuestionIndex(questionIndex + 1);
                              formik.setSubmitting(false);
                            })
                            .catch((e) => {
                              if (e?.response?.data?.message) {
                                dispatch(
                                  addAlert({
                                    message: e.response.data.message,
                                    isError: true,
                                    isOpen: true,
                                  })
                                );
                              }
                              formik.setSubmitting(false);
                            });
                        }
                      });
                    } else if (questionIndex === 1) {
                      formik.validateForm(formik.values).then((err: any) => {
                        if (
                          err?.monthly_income === undefined &&
                          err?.rent_income === undefined &&
                          err?.other_income === undefined
                        ) {
                          formik.setSubmitting(true);
                          // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-shadow
                          const { monthly_income, rent_income, other_income } =
                            formik.values;
                          const totalIncome =
                            (monthly_income ?? 0) +
                            (rent_income ?? 0) +
                            (other_income ?? 0);
                          setUserDataLayer(
                            undefined,
                            undefined,
                            undefined,
                            totalIncome
                          );
                          fetchApi("income", "post", {
                            total: totalIncome,
                          })
                            .then(() => {
                              setQuestionIndex(questionIndex + 1);
                              formik.setSubmitting(false);
                            })
                            .catch((e) => {
                              if (e?.response?.data?.message) {
                                dispatch(
                                  addAlert({
                                    message: e.response.data.message,
                                    isError: true,
                                    isOpen: true,
                                  })
                                );
                              }
                              formik.setSubmitting(false);
                            });
                        }
                      });
                    } else {
                      formik
                        .setFieldTouched(
                          profileQuestionNameOrder[questionIndex],
                          true,
                          true
                        )
                        .then((err) => {
                          if (
                            // eslint-disable-next-line no-prototype-builtins
                            !err?.hasOwnProperty(
                              profileQuestionNameOrder[questionIndex]
                            )
                          ) {
                            if (questionIndex === 10) {
                              formik.handleSubmit();
                            } else {
                              setQuestionIndex(questionIndex + 1);
                            }
                          }
                        });
                    }
                  }}
                >
                  Continuar
                </Buttons>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
Perfil.title = "Test de perfil de riesgo";
export default Perfil;
