import { AmountType, Objective, OutputType } from "./goal.type";

export const goalsArrayMockedData: Array<Objective> = [
  {
    id: 7,
    type: "EDUCATION",
    output_type: OutputType.UNIQUE,
    protection_type: "CAPITAL",
    name: "Educación de sdfs",
    amount: 23234,
    amount_type: AmountType.UNIQUE,
    term: 0,
    term_type: "YEAR",
    duration: null,
    duration_type: null,
    stops_saving: false,
    receiver: "sdfs",
    recipient: "sdfs",
    date_init: "2023-03-15",
    date_end: "2024-03-15",
    is_active: true,
    extra_info: {
      amount: 23234,
      duration: 1,
      date_init: "2023-03-15",
      recipient: "sdfs",
    },
    probability: null,
    time_horizon: 0,
    percentage_completed: null,
    adjusted_objective: 23582.51,
  },
  {
    id: 9,
    type: "RETIREMENT",
    output_type: OutputType.MONTHLY,
    protection_type: "LONG_TERM",
    name: "Complemento jubilación",
    amount: 368.36,
    amount_type: AmountType.UNIQUE,
    term: 64,
    term_type: "AGE",
    duration: 312,
    duration_type: "MONTHLY",
    stops_saving: false,
    receiver: null,
    recipient: null,
    date_init: "2056-10-02",
    date_end: "2082-10-02",
    is_active: true,
    extra_info: {
      net_income: 32423,
      percentage: 44,
      is_freelance: false,
      retirement_age: 64,
      years_contributed: 33,
      simulatedRetirement: [
        {
          retirement_age: 63,
          retirement_income: 2876,
        },
        {
          retirement_age: 64,
          retirement_income: 3140,
        },
        {
          retirement_age: 65,
          retirement_income: 3412,
        },
        {
          retirement_age: 66,
          retirement_income: 3605,
        },
        {
          retirement_age: 67,
          retirement_income: 3804,
        },
      ],
      monthly_self_employed_fee: null,
    },
    probability: null,
    time_horizon: 35,
    percentage_completed: null,
    adjusted_objective: 232834.4853671255,
  },
];

export const outputChartRetirementMockedData: any = {
  "2022": {
    name: 2022,
    output: 0,
  },
  "2023": {
    name: 2023,
    output: 0,
  },
  "2024": {
    name: 2024,
    output: 0,
  },
  "2025": {
    name: 2025,
    output: 0,
  },
  "2026": {
    name: 2026,
    output: 0,
  },
  "2027": {
    name: 2027,
    output: 0,
  },
  "2028": {
    name: 2028,
    output: 0,
  },
  "2029": {
    name: 2029,
    output: 0,
  },
  "2030": {
    name: 2030,
    output: 0,
  },
  "2031": {
    name: 2031,
    output: 0,
  },
  "2032": {
    name: 2032,
    output: 0,
  },
  "2033": {
    name: 2033,
    output: 0,
  },
  "2034": {
    name: 2034,
    output: 0,
  },
  "2035": {
    name: 2035,
    output: 0,
  },
  "2036": {
    name: 2036,
    output: 0,
  },
  "2037": {
    name: 2037,
    output: 0,
  },
  "2038": {
    name: 2038,
    output: 0,
  },
  "2039": {
    name: 2039,
    output: 0,
  },
  "2040": {
    name: 2040,
    output: 0,
  },
  "2041": {
    name: 2041,
    output: 0,
  },
  "2042": {
    name: 2042,
    output: 0,
  },
  "2043": {
    name: 2043,
    output: 0,
  },
  "2044": {
    name: 2044,
    output: 0,
  },
  "2045": {
    name: 2045,
    output: 0,
  },
  "2046": {
    name: 2046,
    output: 0,
  },
  "2047": {
    name: 2047,
    output: 0,
  },
  "2048": {
    name: 2048,
    output: 0,
  },
  "2049": {
    name: 2049,
    output: 0,
  },
  "2050": {
    name: 2050,
    output: 0,
  },
  "2051": {
    name: 2051,
    output: 0,
  },
  "2052": {
    name: 2052,
    output: 0,
  },
  "2053": {
    name: 2053,
    output: 0,
  },
  "2054": {
    name: 2054,
    output: 0,
  },
  "2055": {
    name: 2055,
    output: 0,
  },
  "2056": {
    name: 2056,
    output: 0,
  },
  "2057": {
    name: 2057,
    output: 280.8714038962523,
  },
  "2058": {
    name: 2058,
    output: 1702.0807076112883,
  },
  "2059": {
    name: 2059,
    output: 1727.6119182254577,
  },
  "2060": {
    name: 2060,
    output: 1753.5260969988394,
  },
  "2061": {
    name: 2061,
    output: 1779.8289884538226,
  },
  "2062": {
    name: 2062,
    output: 1806.526423280629,
  },
  "2063": {
    name: 2063,
    output: 1833.6243196298378,
  },
  "2064": {
    name: 2064,
    output: 1861.1286844242863,
  },
  "2065": {
    name: 2065,
    output: 1889.0456146906504,
  },
  "2066": {
    name: 2066,
    output: 1917.3812989110093,
  },
  "2067": {
    name: 2067,
    output: 1946.1420183946743,
  },
  "2068": {
    name: 2068,
    output: 1975.334148670595,
  },
  "2069": {
    name: 2069,
    output: 2004.9641609006528,
  },
  "2070": {
    name: 2070,
    output: 2035.0386233141628,
  },
  "2071": {
    name: 2071,
    output: 2065.5642026638748,
  },
  "2072": {
    name: 2072,
    output: 2096.547665703833,
  },
  "2073": {
    name: 2073,
    output: 2127.9958806893906,
  },
  "2074": {
    name: 2074,
    output: 2159.915818899731,
  },
  "2075": {
    name: 2075,
    output: 2192.314556183226,
  },
  "2076": {
    name: 2076,
    output: 2225.199274525975,
  },
  "2077": {
    name: 2077,
    output: 2258.577263643864,
  },
  "2078": {
    name: 2078,
    output: 2292.4559225985217,
  },
  "2079": {
    name: 2079,
    output: 2326.8427614374996,
  },
  "2080": {
    name: 2080,
    output: 2361.745402859062,
  },
  "2081": {
    name: 2081,
    output: 2397.171583901948,
  },
  "2082": {
    name: 2082,
    output: 2229.3646283679527,
  },
};
export const outputChartEducationMockedData: any = {
  "2057": {
    name: 2057,
    output: 280.8714038962523,
  },
};

export const pensionApiResponse = {
  jsonapi: {
    version: "1.0",
  },
  links: {
    self: "/api/v2/simulate-retirement",
  },
  meta: {
    simulation: [
      {
        year: 63,
        pension_with_inflation: 1177.5597876043669,
        pension: 836.1111111111112,
      },
      {
        year: 64,
        pension_with_inflation: 1292.5087924524905,
        pension: 904.1666666666666,
      },
      {
        year: 65,
        pension_with_inflation: 1410.6413164938472,
        pension: 972.2222222222223,
      },
      {
        year: 66,
        pension_with_inflation: 1489.072973690905,
        pension: 1011.1111111111112,
      },
      {
        year: 67,
        pension_with_inflation: 1569.5401863076636,
        pension: 1050.0000000000002,
      },
    ],
  },
};

export default goalsArrayMockedData;
