import * as React from "react";
import { color } from "../../styles/color";
import { TotalsTextStructures } from "../../components/textEstructures/TotalsTextStructures";

interface IWithAndWithoutInveertCards {
  inputPercentage: number;
  inputMoney: number;
  performanceMoney: number;
  col?: number;
  title: string;
  isSelected?: boolean;
}

function WithAndWithoutInveertCards(props: IWithAndWithoutInveertCards) {
  const {
    inputMoney,
    inputPercentage,
    performanceMoney,
    col,
    title,
    isSelected,
  } = props;
  return (
    <inv-grid-col class={`col-${col ?? "4"}`}>
      <div
        style={{
          border: `3px solid ${
            isSelected ? color.blue[100] : color.greyscale.black[10]
          }`,
          borderRadius: "16px",
          background: isSelected
            ? color.turquoise[5]
            : color.greyscale.white[100],
          padding: "1.2em",
        }}
      >
        <inv-grid-row
          style={{
            alignItems: "center",
            "--inv-gutter-x": "8px",
            marginBottom: "1.6em",
          }}
        >
          <inv-grid-col class="col-auto">
            <inv-text-l>{title}</inv-text-l>
          </inv-grid-col>
          <inv-grid-col class="col-auto" style={{ height: "20.4px" }}>
            <inv-icon
              name="info"
              width="20.4"
              height="20.4"
              color={color.greyscale.black[50]}
            />
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row style={{ marginBottom: "1.4em" }}>
          <inv-grid-col>
            <TotalsTextStructures
              size="small"
              items={[
                {
                  title: "Aportando",
                  quantity: inputPercentage,
                  quantitySuffix: "% de lo que falta",
                  fontColor: color.greyscale.black[50],
                  quantityFontColor: color.greyscale.black[100],
                },
              ]}
              fontColor={color.greyscale.black[50]}
            />
          </inv-grid-col>
        </inv-grid-row>
        <inv-grid-row>
          <inv-grid-col class="col-auto">
            <TotalsTextStructures
              size="small"
              items={[
                {
                  title: "Tú debes aportar",
                  quantity: inputMoney,
                  quantitySuffix: "€",
                  fontColor: color.greyscale.black[50],
                  quantityFontColor: color.greyscale.black[30],
                },
              ]}
              fontColor={color.greyscale.black[50]}
            />
          </inv-grid-col>
          <inv-grid-col class="col-auto">
            <TotalsTextStructures
              size="small"
              items={[
                {
                  title: "Rentabilidad esperada",
                  quantity: performanceMoney,
                  quantitySuffix: "€",
                  fontColor: color.greyscale.black[50],
                  quantityFontColor: color.turquoise[100],
                },
              ]}
              fontColor={color.greyscale.black[50]}
            />
          </inv-grid-col>
        </inv-grid-row>
      </div>
    </inv-grid-col>
  );
}

export default WithAndWithoutInveertCards;

WithAndWithoutInveertCards.defaultProps = {
  col: 4,
  isSelected: false,
};
